interface DownloadIconProps {
  className?: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25 24'
    className={className}
  >
    <path
      fillRule='evenodd'
      d='M23.125 14.602c.76 0 1.375.615 1.375 1.376v5.45A2.574 2.574 0 0121.928 24H3.073A2.575 2.575 0 01.5 21.428v-5.45a1.377 1.377 0 012.752 0v5.271h18.497v-5.271c0-.76.615-1.376 1.376-1.376zM12.547 0c.76 0 1.375.616 1.375 1.375v10.541l3.25-3.248a1.374 1.374 0 111.944 1.945l-5.643 5.643a1.374 1.374 0 01-1.945.001l-5.643-5.643a1.375 1.375 0 111.946-1.946l3.34 3.341V1.375c0-.76.617-1.375 1.376-1.375z'
    />
  </svg>
);

export default DownloadIcon;
