import { ApolloClient, ApolloError, DocumentNode, InMemoryCache } from '@apollo/client';

const createApolloClient = () =>
  new ApolloClient({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
    cache: new InMemoryCache(),
  });

async function queryAllItems(query: DocumentNode, variables: any = {}): Promise<any> {
  const apolloClient = createApolloClient();
  let cursor = '';
  let allItems: any[] = [];
  let keepQuerying = true;

  try {
    while (keepQuerying) {
      const response = await apolloClient.query<any>({
        query,
        variables: { ...variables, cursor },
        fetchPolicy: 'network-only',
      });
      const { data } = response;
      if (!data || data.Content.items.length === 0) {
        keepQuerying = false;
        break; // exit loop if no more items
      }
      allItems = allItems.concat(data.Content.items);
      cursor = data.Content.cursor;

      if (allItems.length >= data.Content.total) {
        keepQuerying = false;
        break; // exit loop if all items are loaded
      }
    }
    return allItems;
  } catch (error: unknown) {
    if (error instanceof ApolloError) {
      console.error('Error during GraphQL query:', query, error.message);
    } else {
      console.error('An unexpected error occurred:', error);
    }
    return null;
  }
}

export async function queryGraphQl<T>(
  query: DocumentNode,
  variables: any = {},
  isAllItemsLoading: boolean = false
): Promise<T | null> {
  if (isAllItemsLoading) {
    const allItems = await queryAllItems(query, variables);
    return allItems;
  }
  const apolloClient = createApolloClient();
  try {
    const { data } = await apolloClient.query<T>({ query, variables });
    return data;
  } catch (error: unknown) {
    if (error instanceof ApolloError) {
      console.error('Error during GraphQL query:', query, error.message);
    } else {
      console.error('An unexpected error occurred:', error);
    }
    return null;
  }
}
