import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import ContentPageLayout from './ContentPageLayout';
import ArticlePageLayout from './ArticlePageLayout';
import EventsPageLayout from './EventsPageLayout';
import MediaCenterPageLayout from './MediaCenterPageLayout';
import FormPageLayout from './FormPageLayout';
import CookieBar from '@components/CookieBar/CookieBar';

const Layout = ({ data = {}, isAdmin = false }: { children?: React.ReactNode; data: any; isAdmin: boolean }) => {
  const pageData = data ?? {};
  const selectPageComponent = (pageData: any) => {
    switch (data.__typename) {
      case 'ContentPage':
        return <ContentPageLayout data={pageData}></ContentPageLayout>;
      case 'EventsPage':
        return <EventsPageLayout data={pageData} />;
      case 'ArticlePage':
        return <ArticlePageLayout data={pageData}></ArticlePageLayout>;
      case 'MediaCenterPage':
        return <MediaCenterPageLayout data={pageData}></MediaCenterPageLayout>;
      case 'FormPage':
        return <FormPageLayout data={pageData}></FormPageLayout>;
      default:
        return <ContentPageLayout data={pageData?.ComponentsArea ?? {}}></ContentPageLayout>;
    }
  };

  return (
    <main className={`kafd-main-sec globalPage-${data.Section ?? ''}`}>
      {!isAdmin && <Header headerMode={data.HeaderMode ?? 'dark'} />}
      {selectPageComponent(pageData)}
      {!isAdmin && <Footer />}
      <CookieBar />
      <div id='search' />
    </main>
  );
};

export default Layout;
