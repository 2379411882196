export function highlightMatchString(text: string, query: string, className: any) {
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span
        key={index}
        className={className}
      >
        {part}
      </span>
    ) : (
      part
    )
  );
}
