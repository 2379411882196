import useUUID from '@hooks/useUUID';
import React, { useState } from 'react';
import * as styles from './Header.module.scss';
import Icon from '@components/Icon/Icon';
import DesktopSubSubMenuList from './DesktopSubSubMenuList';
import { normalizePath } from '@helpers/normalizePath';

const DesktopSubMenuList: React.FC<any> = ({ menuItem }) => {
  const subMenuItems = menuItem.children[0].Items;
  const subMenuItemsKeys = useUUID(subMenuItems.length);
  const [innerSubmenuActive, setInnerSubmenuActive] = useState<boolean>(false);

  const toggleInnerSubmenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setInnerSubmenuActive((prev) => (prev === false ? true : false));
  };

  return subMenuItems.map((subMenuItem: any, subMenuIndex: number) => {
    const hasInnerSubmenu = subMenuItem.ContentLink.Expanded.__typename === 'MenuBlock';
    const fancyUnderlineClass = styles[subMenuItem.ContentLink.Expanded.Classname] || '';

    return (
      <li
        key={subMenuItemsKeys[subMenuIndex]}
        className={`${fancyUnderlineClass} ${hasInnerSubmenu ? styles['has-submenu'] : ''}`}
      >
        {hasInnerSubmenu ? (
          <a
            className={`${styles['submenu-link']}`}
            onClick={(e) => {
              toggleInnerSubmenu(e);
            }}
          >
            <div className={`${styles['secondary-info']}`}>
              <h4
                className={`${styles['secondary-title']}`}
                style={{ paddingRight: '0px' }}
              >
                {subMenuItem.ContentLink.Expanded.Title}
                <Icon.ChevronRightIcon className={`${styles['chevron-right']} lozad  replaced-svg`} />
              </h4>
            </div>
          </a>
        ) : (
          <a
            href={normalizePath(subMenuItem.ContentLink?.Expanded?.Url ?? '#')}
            className={`${styles['submenu-link']}`}
          >
            <div className={`${styles['secondary-info']}`}>
              <h4
                className={`${styles['secondary-title']}`}
                style={{ paddingRight: '0px' }}
              >
                {subMenuItem.ContentLink.Expanded.Label}
              </h4>
            </div>
          </a>
        )}

        {hasInnerSubmenu && (
          <div
            className={`${styles['submenu']} ${innerSubmenuActive ? styles['inner-right'] : ''}`}
            onMouseLeave={() => setInnerSubmenuActive(false)}
          >
            <ul className={`${styles['secondary-links']}`}>
              {Array.isArray(subMenuItem.ContentLink.Expanded.Items) && (
                <DesktopSubSubMenuList subMenuItem={subMenuItem} />
              )}
            </ul>
          </div>
        )}
      </li>
    );
  });
};

export default React.memo(DesktopSubMenuList);
