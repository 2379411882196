import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './Heading4.module.scss';

interface IHeading4 {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Heading4 Component
 *
 *A component representing the <h4>{children}</h4>.
 *
 * @component
 * @param {Object} prop - The props for the Heading4 component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <h4></h4>.
 * @returns {JSX.Element} The rendered JSX element representing the <h4>{children}</h4>.
 *
 * @example
 *  <Heading4 className={['test1', 'test2', 'test3']}>TEST</Heading4>
 */

const Heading4 = (prop: IHeading4): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <h4
      className={clsx(styles.heading4, className)}
      style={style}
    >
      {children}
    </h4>
  );
};

export default Heading4;
