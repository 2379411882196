import { useRef } from 'react';
import Heading1 from '@components/common/htmlTags/Heading1/Heading1';
import Heading2 from '@components/common/htmlTags/Heading2/Heading2';
import Heading3 from '@components/common/htmlTags/Heading3/Heading3';
import Heading4 from '@components/common/htmlTags/Heading4/Heading4';
import Heading5 from '@components/common/htmlTags/Heading5/Heading5';
import Heading6 from '@components/common/htmlTags/Heading6/Heading6';
import Paragraph from '@components/common/htmlTags/Paragraph/Paragraph';
import ListItem from '@components/common/htmlTags/ListItem/ListItem';
import UnorderedList from '@components/common/htmlTags/UnorderedList/UnorderedList';
import VideoBlock from '@components/VideoBlock/VideoBlock';
import Superscript from '@components/common/htmlTags/Superscript/Superscript';
import Subscript from '@components/common/htmlTags/Subscript/Subscript';
import Span from '@components/common/htmlTags/Span/Span';
import replaceNonPrintingCharacters from '@helpers/replaceNonPrintingCharacters';
import { determineClasses } from '@helpers/determineClasses.helper';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import Div from '@components/common/htmlTags/Div/Div';
import Link from '@components/common/htmlTags/Link/Link';
import ImageInRichText from '@components/common/htmlTags/Image/Image';
import Section from '@components/common/htmlTags/Section/Section';
import { adminRichTextLinkUpdater } from '@helpers/adminRichTextLinkUpdater';
import { v4 as uuid } from 'uuid';
import OrderedList from '@components/common/htmlTags/OrderedList/OrderedList';

interface INodeProps {
  [key: string]: any;
}

interface IRichTextComponentProps {
  data: string;
  className?: string;
}

interface IRenderNode {
  [key: string]: React.ComponentType<INodeProps>;
}

const options = {
  renderNode: {
    'heading-one': Heading1,
    'heading-two': Heading2,
    'heading-three': Heading3,
    'heading-four': Heading4,
    'heading-five': Heading5,
    'heading-six': Heading6,
    'list-item': ListItem,
    'bulleted-list': UnorderedList,
    'numbered-list': OrderedList,
    sup: Superscript,
    sub: Subscript,
    paragraph: Paragraph,
    link: Link,
    span: Span,
    div: Div,
    br: () => <br />,
    image: ImageInRichText,
    section: Section,
  } as unknown as IRenderNode,
};

const RenderTextWithMarks = (text: string, marks: any[], style = {}) => {
  const content = (
    <span
      key={uuid()}
      style={style}
    >
      {text}
    </span>
  );
  const markTypes = Object.keys(marks);
  if (markTypes.length === 1 && markTypes[0] === 'text') return content;
  return markTypes.reduce((content, mark) => {
    switch (mark) {
      case 'bold':
        return <b>{content}</b>;
      case 'italic':
        return <i>{content}</i>;
      case 'code':
        return <code>{content}</code>;
      case 'strikethrough':
        return <s>{content}</s>;
      default:
        return content;
    }
  }, content);
};

// Utility function to render text or nested components

const RenderChildren = (children: any[]) => {
  if (!Array.isArray(children)) {
    return null;
  }

  return children?.map((child, index: number) => {
    const style = {} as any;
    if (child['padding-left']) {
      style['paddingLeft'] = child['padding-left'];
    }
    if (child['padding-right']) {
      style.paddingRight = child['padding-right'];
    }
    if (child['font-size']) {
      style.fontSize = child['font-size'];
    }
    if (child['font-family']) {
      style.fontFamily = child['font-family'];
    }
    if (child['decoration']) {
      style.textDecoration = child['decoration'];
    }
    if (child['align']) {
      style.textAlign = child['align'];
    }
    if (child['line-height']) {
      style.lineHeight = child['line-height'];
    }
    if (child.contentType && child.contentType.includes('VideoBlock')) {
      let accentColor = '';
      if (children[index + 1] && children[index + 1].children[0].url) {
        switch (true) {
          case children[index + 1].children[0].url.includes('/envision/'):
            accentColor = 'purple';
            break;
          case children[index + 1].children[0].url.includes('/accelerate/'):
            accentColor = 'blue';
            break;
          case children[index + 1].children[0].url.includes('/live/'):
            accentColor = 'red';
            break;
          default:
            break;
        }
      }
      return (
        <VideoBlock
          accentColor={accentColor}
          key={child.contentGuid}
          contentGuid={child.contentGuid}
        />
      );
    } else if (child.type && options.renderNode[child.type]) {
      const Component = options.renderNode[child.type];

      return (
        <Component
          key={uuid()}
          style={style}
          className={child.class}
          {...child}
        >
          {RenderChildren(child.children)}
        </Component>
      );
    } else if (child.text) {
      const text = replaceNonPrintingCharacters(child.text);
      return RenderTextWithMarks(text, child, style);
    }
    return null;
  });
};

// Function to parse and render the rich text document
const renderRichText = (text: string) => {
  const Render = (children: any[]) => RenderChildren(children);
  try {
    JSON.parse(text);
    const parsedText = JSON.parse(text);
    return Render(parsedText?.children);
  } catch (e) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: adminRichTextLinkUpdater(text),
        }}
      ></div>
    );
  }
};

const RichTextComponent: React.FC<IRichTextComponentProps> = ({ data, className }) => {
  const richText = useRef(null);
  const { animateClass } = determineClasses(className);

  useIsScrolledToSection(richText, animateClass);
  if (!data) return null;
  return (
    <div
      ref={richText}
      className={className}
    >
      {renderRichText(data)}
    </div>
  );
};

export default RichTextComponent;
