import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './Heading2.module.scss';

interface IHeading2 {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Heading2 Component
 *
 *A component representing the <h2>{children}</h2>.
 *
 * @component
 * @param {Object} prop - The props for the Heading2 component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <h2></h2>.
 * @returns {JSX.Element} The rendered JSX element representing the <h2>{children}</h2>.
 *
 * @example
 *  <Heading2 className={['test1', 'test2', 'test3']}>TEST</Heading2>
 */

const Heading2 = (prop: IHeading2): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <h2
      className={clsx(styles.heading2, className)}
      style={style}
    >
      {children}
    </h2>
  );
};

export default Heading2;
