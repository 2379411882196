import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

interface IErrorMessage {
  className?: string | string[];
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * ErrorMessage Component
 *
 * A component representing an error message.
 *
 * @component
 * @param {Object} props - The props for the ErrorMessage component.
 * @param {string} props.className - The class name/names.
 * @param {CSSProperties} props.style - The style will be injected.
 * @param {string} props.children - The error message text.
 * @returns {JSX.Element} The rendered JSX element representing the error message.
 *
 * @example
 *  <ErrorMessage className={['test1', 'test2', 'test3']}>Cannot load data</ErrorMessage>
 */

const ErrorMessage = ({ className, style, children }: IErrorMessage): JSX.Element => {
  const router = useRouter();
  const language = router.asPath.split('/')[1] ?? 'en';
  const message = children ?? (language === 'en' ? 'Cannot load data' : 'لا يمكن تحميل البيانات');
  return (
    <div
      className={clsx(className)}
      style={style}
    >
      {message}
    </div>
  );
};

export default ErrorMessage;
