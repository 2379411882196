import React from 'react';
import RichTextComponent from '@components/RichText/RichText';
import Tabs from './Tabs/Tabs';
import { queryGraphQl } from '@lib/apolloClient';
import TabListQuery from '@graphql/queries/TabListQuery.graphql';
import { useState, useEffect } from 'react';
import { renderCarouselByType } from '@helpers/renderContentByType';
import { useData } from '@context/GraphQLDataContext';

export interface ITabsBlockData {
  Tabs: any[];
}

const TabsBlock = ({ id }: { id: string }): any => {
  const [tabsData, setTabsData] = useState<any>([]);
  const { data } = useData();

  useEffect(() => {
    const fetchTabListData = async () => {
      if (data) {
        const result = await queryGraphQl<any>(TabListQuery, { id, locale: data.language });
        setTabsData(result?.TabListBlock.items[0]);
      }
    };
    fetchTabListData().catch(() => setTabsData([]));
  }, [data, id]);

  const transformTabs = (): any[] => {
    const result: any = [];

    Array.isArray(tabsData?.Tabs) &&
      tabsData.Tabs.map((item: any) => {
        result.push({
          title: item.ContentLink.Expanded.Title,
          content: renderRichTextOrCardList(item),
        });
      });

    return result;
  };

  const renderRichTextOrCardList = (item: any) => {
    const node = item.ContentLink?.Expanded?.Content[0]?.ContentLink?.Expanded;
    if (node.__typename === 'CardListBlock') {
      return renderCarouselByType({
        Type: node.Type,
        Items: node.Items,
      });
    } else if (node.__typeName === 'RichTextBlock') {
      return (
        <RichTextComponent
          data={item.Editor?.Structure}
          className={item.ContentLink?.Expanded?.Content[0]?.ContentLink?.Expanded.Classname}
        />
      );
    }

    return null;
  };

  return <Tabs items={transformTabs()}></Tabs>;
};

export default TabsBlock;
