import React, { useEffect, useCallback, useReducer, useRef } from 'react';
import styles from './WhistleBlowerForm.module.scss';
import InputTextCustom from './InputTextCustom/InputTextCustom';
import {
  getTranslation,
  validateAllFormInputs,
  submitWhistleblowerData,
  getRecaptchaToken,
  UploadedAttachment,
} from './utils/helpers';
import { TranslationKeys } from './translations/translationKeys';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom } from './utils/interfaces';
import loaderGif from '@images/loader-black.gif';
import Image from 'next/image';
import { FormState, whistleBlowerStatusFormReducer } from './utils/reducers/WhistleBlowerFormStatus.reducer';
import InputTextCustomReadOnly from './InputTextCustomReadOnly/InputTextCustomReadOnly';
import Attachments from './Attachments/Attachments';
import ReCAPTCHA from 'react-google-recaptcha';

const ENDPOINT_SUBMIT_FORM_DATA = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/WhistleblowingReports/GetReportByNumberAndCode`;
const ENDPOINT_SUBMIT_FEEDBACK = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/WhistleblowingReports/SubmitReporterFeedback`;
const ENDPOINT_UPLOAD_FILE = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/Attachments`;

const FeedbackResponseView: React.FC<{
  isError: boolean;
  message: string;
  onBack: () => void;
}> = ({ isError, message, onBack }) => (
  <form className='EPiServerForms'>
    <div className={`${styles.feedbackMessage} ${isError ? styles.error : styles.success}`}>{message}</div>
    <div className={styles.buttonSubmitWrapper}>
      <button
        type='button'
        onClick={onBack}
        className={`Form__Element FormSubmitButton`}
      >
        {getTranslation(TranslationKeys.BACK)}
      </button>
    </div>
  </form>
);

const WhistleBlowerFormStatus: React.FC = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const initialState: FormState = {
    formData: {},
    formInputsValid: {},
    shouldValidate: false,
    isFormSubmitted: false,
    isLoaderSubmitVisible: false,
    responseWhistleblowerTitle: '',
    responseWhistleblowerStatus: '',
    recaptchaError: null,
    recaptchaToken: null,
    hasFetchError: false,
    submitError: '',
    reportId: '',
    viewOnly: true,
    reporterFeedback: '',
    attachments: [],
    isUploadingFiles: false,
    feedbackSubmitted: false,
    feedbackError: '',
    showFeedbackSuccessState: false,
  };
  const [state, dispatch] = useReducer(whistleBlowerStatusFormReducer, initialState);
  const { formData, formInputsValid, shouldValidate, isFormSubmitted, isLoaderSubmitVisible, viewOnly } = state;

  const areAllInputsValid = validateAllFormInputs(formInputsValid);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    const { name, value, isValid } = e.target;
    dispatch({ type: 'SET_FORM_INPUTS_VALID', payload: { [name]: isValid ?? false } });

    // Update both formData and specific state fields
    dispatch({ type: 'SET_FORM_DATA', payload: { [name]: value } });
    if (name === 'reporterFeedback') {
      dispatch({ type: 'SET_REPORTER_FEEDBACK', payload: value });
    }
  }, []);

  const handleFilesChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (!e.target) return;
      const { value, isValid } = e.target;
      dispatch({ type: 'SET_ATTACHMENTS', payload: value });
      dispatch({ type: 'SET_FORM_INPUTS_VALID', payload: { ...state.formInputsValid, attachments: isValid } });
    },
    [state.formInputsValid]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({
      type: 'SET_IS_LOADER_SUBMIT_VISIBLE',
      payload: true,
    });

    const token = await getRecaptchaToken(recaptchaRef, dispatch);
    formData['recaptchaToken'] = token;

    if (areAllInputsValid) {
      submitWhistleblowerData(ENDPOINT_SUBMIT_FORM_DATA, formData, null, null, 'POST')
        .then((data) => {
          dispatch({ type: 'SET_SUBMIT_ERROR', payload: '' });

          if (data || data === undefined) {
            // Adjust condition to include undefined for 204
            dispatch({ type: 'SET_RESPONSE_TITLE', payload: data?.Title || '' });

            const status =
              data?.KAFDFeedback === null
                ? getTranslation(TranslationKeys.SUBMITTED)
                : (data?.KAFDFeedback as string) || '';
            dispatch({ type: 'SET_RESPONSE_STATUS', payload: status });

            dispatch({ type: 'SET_VIEW_ONLY', payload: data?.ViewOnly ?? true });
            dispatch({ type: 'SET_REPORT_ID', payload: data?.Id || '' });
            dispatch({ type: 'SET_IS_FORM_SUBMITTED', payload: true });
          } else {
            dispatch({ type: 'SET_RESPONSE_TITLE', payload: getTranslation(TranslationKeys.REPORT_NOT_FOUND) });
            dispatch({ type: 'SET_RESPONSE_STATUS', payload: getTranslation(TranslationKeys.REPORT_NOT_FOUND) });
          }
        })
        .catch(() => {
          dispatch({ type: 'SET_RESPONSE_TITLE', payload: getTranslation(TranslationKeys.REPORT_NOT_FOUND) });
          dispatch({ type: 'SET_RESPONSE_STATUS', payload: getTranslation(TranslationKeys.REPORT_NOT_FOUND) });
          dispatch({ type: 'SET_SUBMIT_ERROR', payload: getTranslation(TranslationKeys.SUBMIT_FORM_ERROR_MESSAGE) });
        })
        .finally(() => {
          dispatch({ type: 'SET_IS_LOADER_SUBMIT_VISIBLE', payload: false });
        });
    }
  };

  const uploadAttachments = async (token: string): Promise<UploadedAttachment[]> => {
    const uploadPromises = state.attachments.map(async (attachment) => {
      if (attachment.file) {
        const formData = new FormData();
        formData.append('file', attachment.file);

        const response = await fetch(ENDPOINT_UPLOAD_FILE, {
          method: 'POST',
          body: formData,
          headers: token ? { 'X-Recaptcha-Token': token } : undefined,
        });

        if (!response.ok) {
          throw new Error(`File upload failed for ${attachment.file.name}`);
        }

        const fileData = await response.json();
        if (fileData.Id && fileData.FileName) {
          return {
            id: fileData.Id,
            fileName: fileData.FileName,
          };
        }
      }
      return null;
    });

    const uploadedAttachments = await Promise.all(uploadPromises);
    return uploadedAttachments.filter((attachment): attachment is UploadedAttachment => attachment !== null);
  };

  const handleFeedbackSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: 'SET_IS_LOADER_SUBMIT_VISIBLE', payload: true });

    try {
      const token = await getRecaptchaToken(recaptchaRef, dispatch);
      if (!token) {
        throw new Error('Recaptcha token is null');
      }

      let uploadedAttachments: UploadedAttachment[] = [];
      uploadedAttachments = await uploadAttachments(token);

      const feedbackData = {
        id: state.reportId,
        reporterFeedback: formData.reporterFeedback || state.reporterFeedback || '',
        attachments: uploadedAttachments,
        recaptchaToken: token,
      };

      const feedbackResponse = await submitWhistleblowerData(ENDPOINT_SUBMIT_FEEDBACK, feedbackData, null, null, 'PUT');

      if (feedbackResponse || feedbackResponse === undefined) {
        dispatch({ type: 'SET_SUBMIT_ERROR', payload: '' });
        dispatch({ type: 'SET_SHOW_FEEDBACK_SUCCESS_STATE', payload: true });
        dispatch({ type: 'SET_FEEDBACK_ERROR', payload: null });
      } else {
        throw new Error('Feedback submission failed');
      }
    } catch (error) {
      console.error('Error in feedback submission:', error);
      dispatch({
        type: 'SET_FEEDBACK_ERROR',
        payload: getTranslation(TranslationKeys.FEEDBACK_SUBMIT_ERROR),
      });
      dispatch({ type: 'SET_SHOW_FEEDBACK_SUCCESS_STATE', payload: false });
    } finally {
      dispatch({ type: 'SET_IS_LOADER_SUBMIT_VISIBLE', payload: false });
    }
  };

  const onRecaptchaSuccess = useCallback(
    (tokenForVerification: string | null) => {
      dispatch({ type: 'SET_RECAPTCHA_ERROR', payload: '' });
      dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: tokenForVerification });
    },
    [dispatch]
  );

  const onRecaptchaError = useCallback(() => {
    dispatch({ type: 'SET_RECAPTCHA_ERROR', payload: getTranslation(TranslationKeys.RECAPTCHA_EXECUTION_FAILED) });
    dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: null });
    recaptchaRef.current?.reset();
  }, [dispatch, recaptchaRef]);

  const onRecaptchaExpired = useCallback(() => {
    dispatch({ type: 'SET_RECAPTCHA_ERROR', payload: getTranslation(TranslationKeys.RECAPTCHA_TOKEN_EXPIRED) });
    dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: null });
    recaptchaRef.current?.reset();
  }, [dispatch, recaptchaRef]);

  useEffect(() => {
    if (shouldValidate) {
      dispatch({ type: 'SET_SHOULD_VALIDATE', payload: false });
    }
  }, [shouldValidate, formInputsValid]);

  return state.hasFetchError ? (
    <div>{getTranslation(TranslationKeys.SUBMIT_FORM_ERROR_MESSAGE)}</div>
  ) : (
    <>
      <div>
        {!isFormSubmitted ? (
          <form
            onSubmit={handleSubmit}
            className='EPiServerForms'
          >
            <InputTextCustom
              shouldValidate={shouldValidate}
              name='referenceNumber'
              type='number'
              onChange={handleChange}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_REFERENCE_NUMBER)}
              required={true}
              validationMessage={getTranslation(TranslationKeys.REQUIRED_REFERENCE_NUMBER)}
              additionalValidation={true}
              additionalValidationMessage={getTranslation(TranslationKeys.VALID_REFERENCE_NUMBER)}
              additionalValidationRule={/^\d{6}$/}
            />
            <InputTextCustom
              shouldValidate={shouldValidate}
              name='code'
              onChange={handleChange}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_CODE)}
              required={true}
              validationMessage={getTranslation(TranslationKeys.REQUIRED_CODE)}
              additionalValidation={true}
              additionalValidationMessage={getTranslation(TranslationKeys.VALID_CODE)}
              additionalValidationRule={/^[a-zA-Z0-9]{6}$/}
            />
            {isLoaderSubmitVisible && (
              <Image
                width={100}
                height={100}
                src={loaderGif}
                alt='submit form gif'
              />
            )}
            {viewOnly ? null : (
              <>
                <InputTextCustom
                  shouldValidate={shouldValidate}
                  name='reporterFeedback'
                  onChange={handleChange}
                  labelText={getTranslation(TranslationKeys.PLACEHOLDER_REPORT_FEEDBACK)}
                  required={true}
                  validationMessage={getTranslation(TranslationKeys.REQUIRED_REPORT_FEEDBACK)}
                />
                <Attachments
                  shouldValidate={shouldValidate}
                  name='attachments'
                  onChange={handleFilesChange}
                  required={false}
                />
              </>
            )}
            <div className={styles.buttonSubmitWrapper}>
              <button
                type='submit'
                disabled={!areAllInputsValid || isLoaderSubmitVisible}
                className={`Form__Element FormSubmitButton`}
              >
                {viewOnly
                  ? getTranslation(TranslationKeys.ACCESS_REPORT)
                  : getTranslation(TranslationKeys.SUBMIT_FEEDBACK)}
              </button>
            </div>
          </form>
        ) : state.showFeedbackSuccessState || state.feedbackError ? (
          <FeedbackResponseView
            isError={!!state.feedbackError}
            message={state.feedbackError || getTranslation(TranslationKeys.FEEDBACK_SUBMIT_SUCCESS)}
            onBack={() => dispatch({ type: 'RESET_STATE', payload: initialState })}
          />
        ) : viewOnly ? (
          <form className='EPiServerForms'>
            <InputTextCustomReadOnly
              name='title'
              value={state.responseWhistleblowerTitle}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_ACCESS_REPORT_TITLE)}
            />
            <InputTextCustomReadOnly
              name='status'
              value={state.responseWhistleblowerStatus}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_ACCESS_REPORT_STATUS)}
            />
            <div className={styles.buttonSubmitWrapper}>
              <button
                type='button'
                onClick={() => dispatch({ type: 'RESET_STATE', payload: initialState })}
                className={`Form__Element FormSubmitButton`}
              >
                {getTranslation(TranslationKeys.BACK)}
              </button>
            </div>
          </form>
        ) : (
          <form
            onSubmit={handleFeedbackSubmit}
            className='EPiServerForms'
          >
            <InputTextCustomReadOnly
              name='title'
              value={state.responseWhistleblowerTitle}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_ACCESS_REPORT_TITLE)}
            />
            <InputTextCustomReadOnly
              name='status'
              value={state.responseWhistleblowerStatus}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_ACCESS_REPORT_STATUS)}
            />
            <InputTextCustom
              shouldValidate={shouldValidate}
              name='reporterFeedback'
              onChange={handleChange}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_REPORT_FEEDBACK)}
              required={true}
              validationMessage={getTranslation(TranslationKeys.REQUIRED_REPORT_FEEDBACK)}
            />
            <Attachments
              shouldValidate={shouldValidate}
              name='attachments'
              onChange={handleFilesChange}
              required={false}
            />
            <div className={styles.buttonSubmitWrapper}>
              <button
                type='submit'
                disabled={!areAllInputsValid || isLoaderSubmitVisible}
                className={`Form__Element FormSubmitButton`}
              >
                {getTranslation(TranslationKeys.SUBMIT_FEEDBACK)}
              </button>
            </div>
            {state.feedbackSubmitted && (
              <div className={styles.feedbackStatusMessage}>
                {getTranslation(TranslationKeys.FEEDBACK_SUBMIT_SUCCESS)}
              </div>
            )}
            {state.feedbackError && <div className={styles.feedbackErrorMessage}>{state.feedbackError}</div>}
          </form>
        )}
        <div style={{ position: 'fixed', zIndex: '1000' }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
            size='invisible'
            onChange={onRecaptchaSuccess}
            onError={onRecaptchaError}
            onExpired={onRecaptchaExpired}
          />
        </div>
        {state.recaptchaError && <div className='error'>{state.recaptchaError}</div>}
      </div>
    </>
  );
};

export default WhistleBlowerFormStatus;
