import { FC, useRef, useState, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import * as styles from './CarouselLive.module.scss';
import Icon from '@components/Icon/Icon';
import clsx from 'clsx';
import useWindowSize from '@hooks/useWindowSize';
import useUUID from '@hooks/useUUID';
import CarouselLiveCard from '@components/CarouselLive/CarouselLiveCard/CarouselLiveCard';
import { ICarouselItem } from '@interfaces/IContentPageQuery';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import { DetermineLanguage } from '@helpers/determineLanguage';

type ICarouselLiveProps = {
  items: ICarouselItem[];
};

const Carousel: FC<ICarouselLiveProps> = ({ items }) => {
  const sliderLive = useRef<Slider>(null);
  const sliderWrapper = useRef<HTMLDivElement>(null);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<string | boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<string | boolean>(false);
  const windowSize = useWindowSize();
  const areAllCardsVisible = !!(nextButtonDisabled && prevButtonDisabled);
  const itemsKeys = useUUID(items?.length);
  useIsScrolledToSection(sliderWrapper, 'visible-section', true);

  const isDisabled = () => {
    if (!sliderWrapper.current) return;

    const isPrevDisabled = !!sliderWrapper.current.querySelector('.slick-prev.slick-disabled');
    const isNextDisabled = !!sliderWrapper.current.querySelector('.slick-next.slick-disabled');

    if (isPrevDisabled) {
      setPrevButtonDisabled('slick-disabled');
    } else {
      setPrevButtonDisabled(false);
    }

    if (isNextDisabled) {
      setNextButtonDisabled('slick-disabled');
    } else {
      setNextButtonDisabled(false);
    }
  };

  const handlePrevSlide = () => {
    sliderLive?.current?.slickPrev();
  };

  const handleNextSlide = () => {
    sliderLive?.current?.slickNext();
  };

  useEffect(() => {
    isDisabled();
  }, [windowSize]);

  const language = DetermineLanguage();

  const handleSwipeDirection = (swipeDirection: string) => {
    if (language === 'ar') {
      if (swipeDirection === 'left') handlePrevSlide();
      else if (swipeDirection === 'right') handleNextSlide();
    } else {
      if (swipeDirection === 'left') handleNextSlide();
      else if (swipeDirection === 'right') handlePrevSlide();
    }
  };

  const sliderSettings: Settings = {
    speed: 1000,
    infinite: false,
    slidesToShow: 1,
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: '160px',
    swipeEvent(swipeDirection) {
      handleSwipeDirection(swipeDirection);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          centerPadding: '25px',
        },
      },
    ],
    afterChange: () => isDisabled(),
  };

  if (!Array.isArray(items)) return null;

  return (
    <section
      ref={sliderWrapper}
      className='opacity-0'
    >
      <div className={styles.innerContainer}>
        <div className={styles.carouselSectionlive}>
          <Slider
            ref={sliderLive}
            {...sliderSettings}
          >
            {items.map((item, index) => (
              <CarouselLiveCard
                key={itemsKeys[index]}
                title={item.ContentLink?.Expanded.Title}
                text={item.ContentLink?.Expanded.Text}
                image={
                  item.ContentLink?.Expanded.Image.Expanded.Url?.startsWith('/')
                    ? item.ContentLink?.Expanded.Image.Expanded.Url ?? ''
                    : item.ContentLink?.Expanded.Image.Expanded.Url
                }
                href={item.ContentLink?.Expanded.LinkUrl}
                linkText={item.ContentLink?.Expanded.LinkText}
              />
            ))}
          </Slider>
          <div className={styles.decoration}>
            <Icon.MotifRaindrops />
          </div>
          {!areAllCardsVisible && (
            <div className={styles.buttonsWrapper}>
              <button
                onClick={handlePrevSlide}
                className={clsx(styles.button, prevButtonDisabled)}
                aria-label='Prev'
                disabled={!!prevButtonDisabled}
              >
                <Icon.ChevronRightIcon className={styles.iconTransformToLeft} />
              </button>
              <div className={styles.dotIcon} />
              <button
                onClick={handleNextSlide}
                aria-label='Next'
                className={clsx(styles.button, nextButtonDisabled)}
                disabled={!!nextButtonDisabled}
              >
                <Icon.ChevronRightIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Carousel;
