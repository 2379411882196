import React from 'react';
import { IInputTextCustomReadOnly } from '../utils/interfaces';
import Icon from '@components/Icon/Icon';

const InputTextCustomReadOnly: React.FC<IInputTextCustomReadOnly> = ({ name, type = 'text', labelText, value }) => {
  const validationCssClass = 'ValidationSuccess';
  return (
    <div className={`Form__Element FormTextbox ${validationCssClass}`}>
      <label
        htmlFor={name}
        className={`Form__Element__Caption textInputLabel ${value ? 'filled' : ''}`}
      >
        {labelText}
      </label>
      <input
        readOnly
        type={type}
        id={name}
        name={name}
        value={value}
        className='FormTextbox__Input textInput'
      />
      <Icon.Tick className='TickIcon' />
    </div>
  );
};

export default InputTextCustomReadOnly;
