export enum TranslationKeys {
  ACCESS_REPORT = 'ACCESS_REPORT',
  ATTACHMENTS = 'ATTACHMENTS',
  ADD_ANOTHER_ATTACHMENT = 'ADD_ANOTHER_ATTACHMENT',
  CANCEL = 'CANCEL',
  CONFIRMATION_DELETE_DOCUMENT = 'CONFIRMATION_DELETE_DOCUMENT',
  REVEAL_IDENTITY = 'REVEAL_IDENTITY',
  SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE',
  VERIFY = 'VERIFY',
  VERIFY_CODE = 'VERIFY_CODE',
  SUBMIT = 'SUBMIT',
  SUCCESS = 'SUCCESS',
  YES = 'YES',
  NO = 'NO',
  ERROR = 'ERROR',
  REPORT_NOT_FOUND = 'REPORT_NOT_FOUND',
  SUBMIT_REPORT_MESSAGE_HEADER0 = 'SUBMIT_REPORT_MESSAGE_HEADER0',
  SUBMIT_REPORT_MESSAGE_HEADER1 = 'SUBMIT_REPORT_MESSAGE_HEADER1',
  SUBMIT_REPORT_MESSAGE_HEADER2 = 'SUBMIT_REPORT_MESSAGE_HEADER2',
  SUBMIT_REPORT_MESSAGE_HEADER3 = 'SUBMIT_REPORT_MESSAGE_HEADER3',
  SUBMIT_REPORT_MESSAGE_HEADER4 = 'SUBMIT_REPORT_MESSAGE_HEADER4',
  SUBMIT_FORM_ERROR_MESSAGE = 'SUBMIT_FORM_ERROR_MESSAGE',
  TRACKING_NUMBER = 'TRACKING_NUMBER',
  SECRET_CODE = 'SECRET_CODE',
  REPORT_SUBMITTED_SUCCESSFULLY = 'REPORT_SUBMITTED_SUCCESSFULLY',
  FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED',
  FETCH_FORM_ERROR_MESSAGE = 'FETCH_FORM_ERROR_MESSAGE',
  REQUIRED_FIELD = 'REQUIRED_FIELD',
  INVALID_FIELD = 'INVALID_FIELD',
  REQUIRED_VALUE = 'REQUIRED_VALUE',
  REQUIRED_REFERENCE_NUMBER = 'REQUIRED_REFERENCE_NUMBER',
  REQUIRED_CODE = 'REQUIRED_CODE',
  REQUIRED_FILE = 'REQUIRED_FILE',
  VALID_EMAIL = 'VALID_EMAIL',
  VALID_REFERENCE_NUMBER = 'VALID_REFERENCE_NUMBER',
  VALID_CODE = 'VALID_CODE',
  CHOOSE_CONCERN = 'CHOOSE_CONCERN',
  CHOOSE_REPORT_TYPE = 'CHOOSE_REPORT_TYPE',

  PLACEHOLDER_REFERENCE_NUMBER = 'PLACEHOLDER_REFERENCE_NUMBER',
  PLACEHOLDER_CODE = 'PLACEHOLDER_CODE',
  PLACEHOLDER_TITLE = 'PLACEHOLDER_TITLE',
  PLACEHOLDER_REPORT_TYPES = 'PLACEHOLDER_REPORT_TYPES',
  PLACEHOLDER_CONCERNS = 'PLACEHOLDER_CONCERNS',
  PLACEHOLDER_YOUR_NAME = 'PLACEHOLDER_YOUR_NAME',
  PLACEHOLDER_YOUR_EMAIL = 'PLACEHOLDER_YOUR_EMAIL',
  PLACEHOLDER_VERIFICATION_CODE = 'PLACEHOLDER_VERIFICATION_CODE',

  CODE_VERIFIED = 'CODE_VERIFIED',
  CODE_VERIFICATION_FAILED = 'CODE_VERIFICATION_FAILED',
  CODE_WAS_SENT_TO_EMAIL = 'CODE_WAS_SENT_TO_EMAIL',
  ERROR_SENDING_EMAIL = 'ERROR_SENDING_EMAIL',
  RECAPTCHA_VERIFICATION_FAILED = 'RECAPTCHA_VERIFICATION_FAILED',
  RECAPTCHA_EXECUTION_FAILED = 'RECAPTCHA_EXECUTION_FAILED',
  RECAPTCHA_TOKEN_EXPIRED = 'RECAPTCHA_TOKEN_EXPIRED',
  PLACEHOLDER_ACCESS_REPORT_TITLE = 'PLACEHOLDER_ACCESS_REPORT_TITLE',
  PLACEHOLDER_ACCESS_REPORT_STATUS = 'PLACEHOLDER_ACCESS_REPORT_STATUS',
  PLACEHOLDER_REPORT_FEEDBACK = 'PLACEHOLDER_REPORT_FEEDBACK',
  REQUIRED_REPORT_FEEDBACK = 'REQUIRED_REPORT_FEEDBACK',
  SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK',
  FEEDBACK_SUBMIT_SUCCESS = 'FEEDBACK_SUBMIT_SUCCESS',
  FEEDBACK_SUBMIT_ERROR = 'FEEDBACK_SUBMIT_ERROR',
  BACK = 'BACK',
  SUBMITTED = 'SUBMITTED',
}
