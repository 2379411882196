import { FC, useState, useRef, useEffect } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import clsx from 'clsx';
import * as styles from './Accordion.module.scss';
import Icon from '../../Icon/Icon';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { animateSection, fadeIn } from '@helpers/animations.helper';
import RichTextComponent from '@components/RichText/RichText';
import React from 'react';

interface IAccordionProps {
  type?: 'faq' | 'search';
  title: string;
  content?: string;
  classname?: string | string[];
  handleOpen: (index?: number) => void;
  isOpen: boolean;
  index: number;
  children?: React.ReactNode;
}

const Accordion: FC<IAccordionProps> = ({
  type = 'faq',
  title,
  content,
  classname,
  handleOpen,
  isOpen,
  index,
  children,
}) => {
  const [height, setHeight] = useState<number | string | undefined>();
  const expandedContent = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const itemWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(expandedContent.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen, windowSize]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (type === 'search') return;

    const ctx = gsap.context(() => {
      if (
        ScrollTrigger.isInViewport(itemWrapper.current as HTMLDivElement) &&
        (itemWrapper.current as HTMLDivElement).classList.contains('default-item')
      ) {
        fadeIn(itemWrapper.current).play();
        return;
      }

      ScrollTrigger.create({
        trigger: itemWrapper?.current as HTMLDivElement,
        start: 'top 100%',
        animation: animateSection(itemWrapper.current).play(),
      });
    }, itemWrapper);

    return () => ctx.revert();
  }, [type]);

  return (
    <div
      ref={itemWrapper}
      className={clsx(styles.wrapper, classname)}
    >
      <button
        type='button'
        className={clsx(styles.header, isOpen ? styles.headerActive : '')}
        onClick={() => (isOpen ? handleOpen(undefined) : handleOpen(index))}
      >
        <span className={styles.textWrapper}>{title}</span>
        <span className={styles.svgWrapper}>
          <Icon.ArrowDownIcon />
        </span>
      </button>
      <div
        className={styles.content}
        style={{ height }}
        aria-expanded={isOpen}
      >
        <div
          ref={expandedContent}
          className={isOpen ? styles.contentVisible : styles.contentHidden}
        >
          {content && (
            <RichTextComponent
              className='contenteditor'
              data={content}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Accordion, (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen);
