import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './Link.module.scss';
import GenericButton from '@components/GenericButton/GenericButton';

interface ILinkProps {
  className?: string | string[];
  style?: CSSProperties;
  url: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  children: ReactNode;
  title?: string;
}

/**
 * Link component
 *
 * A component for creating a customizable link (<a> tag).
 *
 * @component
 * @param {Object} props - The props for the Link component.
 * @param {string|string[]} props.className - The class name or names to be applied to the link.
 * @param {CSSProperties} props.style - The inline styles to be injected into the link.
 * @param {string} props.url - The URL that the link points to.
 * @param {string} props.target - Specifies where to open the linked document.
 * @param {ReactNode} props.children - The content inside the <a></a> tag.
 * @returns {JSX.Element} The rendered JSX element representing the link.
 *
 * @example
 * <Link
 *   className={['linkClass1', 'linkClass2']}
 *   href="https://example.com"
 *   target="_blank"
 * >
 *   Click Here
 * </Link>
 */

const Link: React.FC<ILinkProps> = (props): JSX.Element => {
  const { className, style, url, target, title, children } = props;

  if (!title) {
    return (
      <a
        href={url || '#'}
        target={target}
        className={clsx(styles.link, className)}
        style={style}
      >
        {children}
      </a>
    );
  } else return <GenericButton {...props} />;
};

export default Link;
