import React, { useCallback, useEffect, useState } from 'react';
import { DetermineLanguage } from '@helpers/determineLanguage';
import InputTextCustom from '../InputTextCustom/InputTextCustom';
import {
  IQuestionWithValue,
  HTMLInputElementCustom,
  HTMLTextAreaElementCustom,
  IInputProps,
} from '../utils/interfaces';
import { validateAllQuestions, getWhistleblowerData } from '../utils/helpers';

const ENDPOINT_QUESTIONS = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/Questions`;
const Questions: React.FC<IInputProps> = ({
  name,
  onChange,
  validationMessage,
  setHasFetchError,
  counterLoaderHelper,
}) => {
  const [questions, setQuestions] = useState<IQuestionWithValue[]>([]);
  const language = DetermineLanguage();

  const fetchQuestions = useCallback(async () => {
    if (counterLoaderHelper) {
      counterLoaderHelper(1);
    }
    await getWhistleblowerData(
      ENDPOINT_QUESTIONS,
      setQuestions,
      (itemQuestion) => ({ ...itemQuestion, value: '' }),
      setHasFetchError
    )
      .finally(() => {
        if (counterLoaderHelper) counterLoaderHelper(-1);
      })
      .catch(() => {
        if (setHasFetchError) setHasFetchError(true);
      });
  }, [counterLoaderHelper, setHasFetchError]);

  useEffect(() => {
    void fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateQuestions = useCallback(() => {
    return validateAllQuestions(questions);
  }, [questions]);
  const isValid = validateQuestions();

  useEffect(() => {
    const target = { name, value: questions, isValid } as unknown as HTMLInputElementCustom;
    onChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  }, [name, isValid, onChange, questions]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    const { name, value } = e.target;
    setQuestions((prevQuestions: IQuestionWithValue[]) =>
      prevQuestions.map((item) => (item.Id === name ? { ...item, value } : item))
    );
  }, []);

  return (
    <>
      {questions?.map((item) => (
        <React.Fragment key={item.Id}>
          <div className='kafd-form-group _textarea'>
            <InputTextCustom
              name={item.Id}
              type='textarea'
              onChange={handleInputChange}
              required={item.mandatory}
              validationMessage={validationMessage}
              labelText={language === 'ar' ? item.arabicLabel : item.label}
            />
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default Questions;
