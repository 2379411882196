import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './GalleryModal.module.scss';
import Icon from '@components/Icon/Icon';
import { useData } from '@context/GraphQLDataContext';
import { useEffect } from 'react';

interface IGalleryModal {
  href: string;
  onClose: () => void;
}

/**
 * GalleryModal Component
 *
 *A component overlay and modal.
 *
 * @component
 * @param {Object} prop - The props for the GenericButton component.
 * @param {function} prop.onClose - Event handler called when one clicked on the Close button or on the overlay.
 * One of the href or onClick props is required, using both will result in a Link being returned and the onClick function will not work. If there are none of these props, the component will return null.
 * @param {string} prop.href - A href for the image.
 * @returns {JSX.Element} The rendered JSX element representing the modal with card.
 *
 */

const GalleryModal = (prop: IGalleryModal): JSX.Element => {
  const { onClose, href } = prop;
  const { translate } = useData();

  const handleClick = (e: { currentTarget: any; target: any }) => {
    const { className } = e.target;
    const unwantedClasses = ['', styles['download-link'], styles['modal-caption']];

    if (!unwantedClasses.includes(className)) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div
      id='media-modal'
      className={styles.GalleryModal}
      onClick={handleClick}
    >
      <div className={styles['modal-container']}>
        <div className={styles['modal-close']}>
          {translate('close')}
          <Icon.CloseModalIcon className='ml-2' />
        </div>
        <div className={styles['modal-img-block']}>
          <Image
            fill
            sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
            url={href}
            alt=''
          />
        </div>
        <div
          id='modal-caption'
          className={styles['modal-caption']}
        >
          <a
            href={href}
            id='download-link'
            download='file'
            className={styles['download-link']}
          >
            {translate('download-file')}
            <Icon.DownloadIcon className='ml-3 mb-2' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
