import React, { useCallback, useReducer } from 'react';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom, IInputProps } from '../utils/interfaces';
import InputTextCustom from '../InputTextCustom/InputTextCustom';
import { getTranslation, validateAllFormInputs } from '../utils/helpers';
import { TranslationKeys } from '../translations/translationKeys';
import { ICheckIdentityState, checkIdentityReducer } from '../utils/reducers/CheckIdentity.reducer';
import Image from 'next/image';
import loaderGif from '@images/loader-black.gif';

const ENDPOINT_SUBMIT_IDENTITY = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/Notifications/SendEmailCodeVerification`;
const ENDPOINT_VERIFY_CODE = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/EmailCodeVerifications`;

const initialState: ICheckIdentityState = {
  inputValues: { name: '', email: '', code: '' },
  isValid: false,
  sentEmail: false,
  verifySuccess: false,
  showInputs: false,
  verifyMessage: '',
  emailMessage: '',
  checkIdentityInputsValid: {},
  isVerificationCodeValid: false,
};

const CheckIdentity: React.FC<IInputProps> = ({ name, onChange, getRecaptchaToken }) => {
  const [state, dispatch] = useReducer(checkIdentityReducer, initialState);
  const {
    inputValues,
    sentEmail,
    verifySuccess,
    showInputs,
    verifyMessage,
    emailMessage,
    checkIdentityInputsValid,
    isVerificationCodeValid,
  } = state;

  const [isLoading, setIsLoading] = React.useState(false);

  const areAllCheckIdentityInputsValid = validateAllFormInputs(checkIdentityInputsValid);

  React.useEffect(() => {
    // Set initial value for isReveal to false
    onChange({
      target: {
        name,
        value: {
          isReveal: false,
          name: '',
          email: '',
        },
        isValid: true,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [name, onChange]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
      const { name, value, isValid } = e.target;
      dispatch({ type: 'SET_INPUT_VALUES', payload: { name, value } });

      if (name === 'name' || name === 'email') {
        dispatch({
          type: 'SET_CHECK_IDENTITY_INPUTS_VALID',
          payload: { ...checkIdentityInputsValid, [name]: isValid },
        });
      }

      if (name === 'code') {
        dispatch({ type: 'SET_IS_VERIFICATION_CODE_VALID', payload: !!isValid });
      }
    },
    [checkIdentityInputsValid]
  );

  const handleRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const isYes = value === 'yes';
      dispatch({ type: 'SET_SHOW_INPUTS', payload: isYes });

      // Update to always include isReveal in the value object
      onChange({
        target: {
          name,
          value: {
            isReveal: isYes,
            ...(isYes ? {} : { name: '', email: '' }),
          },
          isValid: true,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);

      if (isYes) {
        dispatch({ type: 'SET_IS_VALID', payload: false });
      }
    },
    [onChange, name]
  );

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    let token = null;
    if (getRecaptchaToken) {
      token = await getRecaptchaToken();
    }
    e.preventDefault();
    fetch(ENDPOINT_SUBMIT_IDENTITY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: inputValues.email, recaptchaToken: token }),
    })
      .then((response) => {
        if (response.ok) {
          dispatch({ type: 'SET_SENT_EMAIL', payload: true });
          dispatch({ type: 'SET_EMAIL_MESSAGE', payload: getTranslation(TranslationKeys.CODE_WAS_SENT_TO_EMAIL) });
        } else {
          dispatch({ type: 'SET_SENT_EMAIL', payload: false });
          dispatch({ type: 'SET_EMAIL_MESSAGE', payload: getTranslation(TranslationKeys.ERROR_SENDING_EMAIL) });
          console.error('Error sending mail');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'SET_SENT_EMAIL', payload: false });
        dispatch({ type: 'SET_EMAIL_MESSAGE', payload: getTranslation(TranslationKeys.ERROR) });
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const handleVerifyCode = async () => {
    setIsLoading(true);
    try {
      let token = null;
      if (getRecaptchaToken) {
        token = await getRecaptchaToken();
      }
      const response = await fetch(ENDPOINT_VERIFY_CODE, {
        method: 'POST',
        body: JSON.stringify({ email: inputValues.email, code: inputValues.code }),
        headers: {
          'X-Recaptcha-Token': token || '',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        dispatch({ type: 'SET_VERIFY_SUCCESS', payload: true });
        dispatch({ type: 'SET_VERIFY_MESSAGE', payload: getTranslation(TranslationKeys.CODE_VERIFIED) });

        onChange({
          target: {
            name,
            value: {
              isReveal: true,
              name: inputValues.name,
              email: inputValues.email,
            },
            isValid: true,
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>);
      } else {
        onChange({
          target: {
            name,
            value: {},
            isValid: false,
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>);

        dispatch({ type: 'SET_VERIFY_SUCCESS', payload: false });
        dispatch({ type: 'SET_VERIFY_MESSAGE', payload: getTranslation(TranslationKeys.CODE_VERIFICATION_FAILED) });
        console.error('Error verifying code');
      }
      setIsLoading(false);
    } catch (error) {
      dispatch({ type: 'SET_VERIFY_SUCCESS', payload: false });
      dispatch({ type: 'SET_VERIFY_MESSAGE', payload: getTranslation(TranslationKeys.ERROR) });
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className='FormChoice'>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.7)',
            zIndex: '1000',
          }}
        >
          <Image
            width={100}
            height={100}
            src={loaderGif}
            alt='Loader'
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </div>
      )}
      <div className='FormChoice-Reveal-Identity-Title'>{getTranslation(TranslationKeys.REVEAL_IDENTITY)}</div>
      <div className='FormChoice-Reveal-Identity-Checkboxes'>
        <label className='FormChoice__Item'>
          <input
            type='checkbox'
            name='reveal'
            value='yes'
            onChange={handleRadioChange}
            checked={showInputs}
          />

          <span className='FormChoice__Checkmark'></span>
          <span className='FormChoice__Checkmark-Text'>{getTranslation(TranslationKeys.YES)}</span>
        </label>
        <label className='FormChoice__Item'>
          <input
            type='checkbox'
            name='reveal'
            value='no'
            onChange={handleRadioChange}
            checked={!showInputs}
          />

          <span className='FormChoice__Checkmark'></span>
          <span className='FormChoice__Checkmark-Text'>{getTranslation(TranslationKeys.NO)}</span>
        </label>
      </div>
      {showInputs && (
        <>
          <div>
            <InputTextCustom
              shouldValidate={true}
              name='name'
              type='text'
              onChange={handleChange}
              required={true}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_YOUR_NAME)}
              validationMessage={getTranslation(TranslationKeys.REQUIRED_FIELD)}
            />
            <InputTextCustom
              shouldValidate={true}
              name='email'
              type='email'
              onChange={handleChange}
              required={true}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_YOUR_EMAIL)}
              validationMessage={getTranslation(TranslationKeys.REQUIRED_FIELD)}
              additionalValidation={true}
              additionalValidationMessage={getTranslation(TranslationKeys.VALID_EMAIL)}
              additionalValidationRule={
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              }
            />
            {emailMessage && (
              <div>
                <span className={sentEmail ? 'success' : 'error'}>{emailMessage}</span>
              </div>
            )}
            <button
              disabled={!areAllCheckIdentityInputsValid || sentEmail}
              type='button'
              onClick={handleSubmit}
              className={`Form__Element FormSubmitButton`}
            >
              {getTranslation(TranslationKeys.SEND_VERIFICATION_CODE)}
            </button>
          </div>
          <div>
            <InputTextCustom
              shouldValidate={true}
              name='code'
              type='text'
              onChange={handleChange}
              required={true}
              labelText={getTranslation(TranslationKeys.PLACEHOLDER_VERIFICATION_CODE)}
              validationMessage={getTranslation(TranslationKeys.REQUIRED_FIELD)}
              additionalValidation={true}
              additionalValidationMessage={getTranslation(TranslationKeys.VALID_CODE)}
              additionalValidationRule={/^\d{6}$/}
            />
            {verifyMessage && (
              <div>
                <span className={verifySuccess ? 'success' : 'error'}>{verifyMessage}</span>
              </div>
            )}
            <button
              disabled={!sentEmail || !isVerificationCodeValid || !!verifyMessage}
              type='button'
              onClick={handleVerifyCode}
              className={`Form__Element FormSubmitButton`}
            >
              {getTranslation(TranslationKeys.VERIFY_CODE)}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(CheckIdentity);
