import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForms } from '../context/store';
import {
  StepHelper,
  FormContainer,
  FormSubmitter,
  IdentityInfo,
  isInArray,
  isNull,
  isNullOrEmpty,
  FormSubmitModel,
  FormSubmitResult,
  SubmitButton,
  FormCache,
  FormConstants,
  ProblemDetail,
  StepDependCondition,
  getConfirmationData,
} from '@episerver/forms-sdk';
import { RenderElementInStep } from './RenderElementInStep';
import { DispatchFunctions } from '../context/dispatchFunctions';
import { FormStepNavigation } from './FormStepNavigation';
import { useRouter } from 'next/router';
import loaderGif from '@images/loader-black.gif';
import Image from 'next/image';
import { useData } from '@context/GraphQLDataContext';

interface FormBodyProps {
  identityInfo?: IdentityInfo;
  baseUrl: string;
  history?: any;
  currentPageUrl?: string;
  formEndpoint?: string;
}

export const FormBody = (props: FormBodyProps) => {
  const router = useRouter();
  const formContext = useForms();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useMemo(() => formContext?.formContainer ?? ({} as FormContainer), [formContext]);
  const inactiveElements = formContext?.dependencyInactiveElements ?? [];
  const formSubmitter = new FormSubmitter(form, props.baseUrl);
  const dispatchFunctions = useMemo(() => new DispatchFunctions(), []);
  const stepDependCondition = new StepDependCondition(form, inactiveElements);
  const stepHelper = useMemo(() => new StepHelper(form), [form]);
  const currentPageUrl = props.currentPageUrl ?? window.location.href;

  const formTitleId = `${form.key}_label`;
  const statusMessage = useRef<string>('');
  const statusDisplay = useRef<string>('hide');
  const { translate } = useData();
  const showForm = useRef<boolean>(true);

  const formCache = new FormCache();
  const localFormCache = new FormCache(window.localStorage);
  const currentStepIndex = formContext?.currentStepIndex ?? 0;

  const validateFail = useRef<boolean>(false),
    isFormFinalized = useRef<boolean>(false),
    isProgressiveSubmit = useRef<boolean>(false),
    isSuccess = useRef<boolean>(false),
    submissionWarning = useRef<boolean>(false),
    message = useRef<string>(''),
    submissionStorageKey = FormConstants.FormSubmissionId + form.key,
    isStepValidToDisplay = stepDependCondition.isStepValidToDisplay(currentStepIndex, currentPageUrl),
    isMalFormSteps = stepHelper.isMalFormSteps();

  if ((isFormFinalized.current || isProgressiveSubmit.current) && isSuccess.current) {
    statusDisplay.current = 'Form__Success__Message';
    statusMessage.current = form.properties.submitSuccessMessage ?? message.current;
  } else if ((submissionWarning.current || !isSuccess.current) && !isNullOrEmpty(message.current)) {
    statusDisplay.current = 'Form__Warning__Message';
    statusMessage.current = message.current;
  } else {
    statusDisplay.current = 'hide';
    statusMessage.current = '';
  }

  const validationCssClass = validateFail.current ? 'ValidationFail' : 'ValidationSuccess';

  const showError = (error: string) => {
    submissionWarning.current = !isNullOrEmpty(error);
    message.current = error;
  };

  const handleConfirm = () => {
    const form = formContext?.formContainer ?? ({} as FormContainer);
    const confirmationMessage = form.properties.confirmationMessage ?? '';
    let confirmStatus = true;

    if (form.properties.showSummarizedData) {
      const data = formContext?.formSubmissions ?? [];
      const messageData = getConfirmationData(data, form, currentStepIndex, inactiveElements);
      const showConfirmationMessage = !(isNullOrEmpty(confirmationMessage) && isNullOrEmpty(messageData));
      if (showConfirmationMessage) {
        confirmStatus = confirm(confirmationMessage + '\n\n' + messageData);
      }
    }

    return confirmStatus;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!form.properties.allowAnonymousSubmission && isNullOrEmpty(formContext?.identityInfo?.accessToken)) {
      return;
    }

    //Find submit button, if found then check property 'finalizeForm' of submit button. Otherwise, button Next/Previous was clicked.
    let buttonId = e.nativeEvent.submitter?.id;
    let submitButton = form.formElements.find((fe) => fe.key === buttonId) as SubmitButton;
    if (!isNull(submitButton)) {
      //when submitting by SubmitButton, isProgressiveSubmit default is true
      isProgressiveSubmit.current = true;
    }

    let isLastStep = currentStepIndex === form.steps.length - 1;

    //filter submissions by active elements and current step
    let formSubmissions = (formContext?.formSubmissions ?? []).filter(
      (fs) =>
        !isInArray(fs.elementKey, formContext?.dependencyInactiveElements ?? []) &&
        stepHelper.isInCurrentStep(fs.elementKey, currentStepIndex)
    );

    //validate all submission data before submit
    let formValidationResults = formSubmitter.doValidate(formSubmissions);
    dispatchFunctions.updateAllValidation(formValidationResults);

    //set focus on the 1st invalid element of current step
    let invalid = stepHelper.getFirstInvalidElement(formValidationResults, currentStepIndex);
    if (!isNullOrEmpty(invalid)) {
      dispatchFunctions.updateFocusOn(invalid);
      return;
    }

    // Confirm if user want to submit the form
    if (!isNull(submitButton) || isLastStep) {
      if (!handleConfirm()) {
        isProgressiveSubmit.current = false;
        return;
      }
    }
    const formEndpoint = props.formEndpoint ?? '';
    let model: FormSubmitModel = {
      formKey: form.key,
      locale: form.locale,
      isFinalized: submitButton?.properties?.finalizeForm || isLastStep,
      partialSubmissionKey: localFormCache.get(submissionStorageKey) ?? formContext?.submissionKey ?? '',
      hostedPageUrl: currentPageUrl,
      submissionData: formSubmissions,
      accessToken: formContext?.identityInfo?.accessToken,
      currentStepIndex: currentStepIndex,
      reCaptchaRef: formContext?.reCaptchaRef,
      reCaptchaKey: formContext?.reCaptchaKey,
      formEndpoint,
    };

    //submit data to API
    setIsSubmitting(true);
    dispatchFunctions.updateIsSubmitting(true);
    formSubmitter
      .doSubmit(model, formContext)
      .then((response: FormSubmitResult) => {
        //go here, response.success always is true
        isSuccess.current = response.success;
        const isCRM = model.formEndpoint && model.formEndpoint !== '' && model.formEndpoint.indexOf('api/crm/') > -1;
        isFormFinalized.current = (submitButton?.properties?.finalizeForm || isLastStep) && response.success;
        // dispatchFunctions.updateSubmissionKey(response.submissionKey);
        // localFormCache.set(submissionStorageKey, response.submissionKey);
        if (isFormFinalized.current || isCRM) {
          formCache.remove(FormConstants.FormCurrentStep + form.key);
          localFormCache.remove(submissionStorageKey);
          router.push(`${router.asPath}/success`).finally(() => {
            setIsSubmitting(false);
          });
        }

        // if (isProgressiveSubmit.current) {
        //   message.current = response.messages.map((m) => m.message).join('<br>');
        //   showForm.current = false;
        // }
      })
      .catch((e: ProblemDetail) => {
        let invalidInput = null;
        const isCrm = model.formEndpoint && model.formEndpoint !== '' && model.formEndpoint.indexOf('api/crm/') > -1;
        if (isCrm) {
          const submitButton = formContext?.formValidationResults[formContext?.formValidationResults?.length - 1];
          dispatchFunctions.updateValidation(submitButton?.elementKey ?? '', { valid: false, message: '' });
          showError(translate('crm-form-error'));
        } else {
          switch (e.status) {
            case 401:
              //clear access token to ask login again
              dispatchFunctions.updateIdentity({} as IdentityInfo);
              formCache.remove(FormConstants.FormAccessToken);
              break;
            case 400:
              if (e.errors) {
                //validate fail
                validateFail.current = false;
                let formValidationResults =
                  formContext?.formValidationResults?.map((fr) =>
                    isNull(e.errors[fr.elementKey])
                      ? fr
                      : {
                          ...fr,
                          result: { valid: false, message: e.errors[fr.elementKey].join('<br/>') },
                        }
                  ) ?? [];
                dispatchFunctions.updateAllValidation(formValidationResults);
                const formContainer = formContext?.formContainer;
                invalidInput = formContainer?.steps[formContext?.currentStepIndex ?? 0].elements.find(
                  (e) => e.key === stepHelper.getFirstInvalidElement(formValidationResults, currentStepIndex)
                );
                //set focus on the 1st invalid element of current step
                dispatchFunctions.updateFocusOn(
                  stepHelper.getFirstInvalidElement(formValidationResults, currentStepIndex)
                );
              }
              break;
          }

          if (invalidInput && invalidInput.contentType === 'RecaptchaElementBlock') {
            showError('ReCAPTCHA execution failed. Please try again later or contact web administrator.');
          } else {
            showError(e.detail);
          }
        }
        setIsSubmitting(false);
      })
      .finally(() => {
        dispatchFunctions.updateIsSubmitting(false);
      });
  };

  useEffect(() => {
    dispatchFunctions.updateIdentity(props.identityInfo);
    if (isNullOrEmpty(props.identityInfo?.accessToken) && !form.properties.allowAnonymousSubmission) {
      showError(form.localizations['allowAnonymousSubmissionErrorMessage']);
    } else {
      showError('');
    }
  }, [dispatchFunctions, form.localizations, form.properties.allowAnonymousSubmission, props.identityInfo, props.identityInfo?.accessToken]);

  //reset when change page
  useEffect(() => {
    isSuccess.current = false;
  }, [currentStepIndex]);

  //Run in-case change page by url. The currentStepIndex that get from cache is incorrect.
  useEffect(() => {
    if (!isStepValidToDisplay) {
      dispatchFunctions.updateCurrentStepIndex(stepHelper.getCurrentStepIndex(currentPageUrl));
    }
  }, [currentPageUrl, dispatchFunctions, isStepValidToDisplay, stepHelper]);

  isMalFormSteps && showError(form.localizations['malformstepconfigruationErrorMessage']);

  return (
    <>
      <form
        method="post"
        noValidate={true}
        aria-labelledby={formTitleId}
        encType="application/json"
        className={`EPiServerForms ${validationCssClass}`}
        id={form.key}
        onSubmit={handleSubmit}
      >
        {form.properties.title && (
          <h2
            className="Form__Title"
            id={formTitleId}
          >
            {form.properties.title}
          </h2>
        )}
        {form.properties.description && <aside className="Form__Description">{form.properties.description}</aside>}
        {/* area for showing Form's status or validation */}
        <div className="Form__Status">
          <div
            role="status"
            className={`Form__Status__Message ${statusDisplay.current}`}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: statusMessage.current,
              }}
            ></div>
          </div>
        </div>

        <div
          className="Form__MainBody"
          style={{ display: showForm.current ? 'flow' : 'none' }}
        >
          {/* render element */}
          {Array.isArray(form.steps) &&
            form.steps.map((e, i) => {
              let stepDisplaying =
                currentStepIndex === i && !isFormFinalized.current && isStepValidToDisplay && !isMalFormSteps
                  ? ''
                  : 'hide';
              return (
                <section
                  key={e.formStep.key}
                  id={e.formStep.key}
                  className={`Form__Element__Step ${stepDisplaying}`}
                >
                  <RenderElementInStep
                    elements={e.elements}
                    stepIndex={i}
                  />
                </section>
              );
            })}

          {/* render step navigation*/}
          <FormStepNavigation
            isFormFinalized={isFormFinalized.current}
            history={props.history}
            handleSubmit={handleSubmit}
            isMalFormSteps={isMalFormSteps}
            isStepValidToDisplay={isStepValidToDisplay}
            isSuccess={isSuccess.current}
          />
        </div>
      </form>
      {isSubmitting && (
        <Image
          width={100}
          height={100}
          src={loaderGif}
          alt="submit form gif"
        />
      )}
    </>
  );
};
