import { SearchResultItem } from '@hooks/useSearchInCategory';

export interface FoundResultState {
  isOpen: number | undefined;
  loadedItems: SearchResultItem[] | undefined;
  isLoading: boolean;
  title: { title: string; count: number | undefined };
  categories: any[];
  results: SearchResultItem[] | undefined;
  total: number | undefined;
  allCount: number | undefined;
}

type FoundResultAction =
  | { type: 'SET_IS_OPEN'; payload: number | undefined }
  | { type: 'SET_LOADED_ITEMS'; payload: SearchResultItem[] | undefined }
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_TITLE'; payload: { title: string; count: number | undefined } }
  | { type: 'SET_CATEGORIES'; payload: any[] }
  | { type: 'SET_RESULTS'; payload: SearchResultItem[] }
  | { type: 'SET_TOTAL'; payload: number | undefined }
  | { type: 'SET_ALL_COUNT'; payload: number | undefined };

export const foundResultReducer = (state: FoundResultState, action: FoundResultAction): FoundResultState => {
  switch (action.type) {
    case 'SET_IS_OPEN':
      return { ...state, isOpen: action.payload };
    case 'SET_LOADED_ITEMS':
      return { ...state, loadedItems: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_TITLE':
      return { ...state, title: action.payload };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'SET_RESULTS':
      return { ...state, results: action.payload };
    case 'SET_TOTAL':
      return { ...state, total: action.payload };
    case 'SET_ALL_COUNT':
      return { ...state, allCount: action.payload };
    default:
      return state;
  }
};
