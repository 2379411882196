import { useRef } from 'react';
import * as styles from './HeroContent.module.scss';
import Image from '@components/common/htmlTags/Image/Image';
import { IHeroBanner } from '@interfaces/ICommon';
import { isAbsoluteUrl } from '@helpers/isAbsoluteUrl';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';

/**
 * `HeroContent` is tailored for content pages, displays either an image or a video
 * in the hero section, based on the provided `heroBannerData`. It determines the
 * media type (image or video) to display and constructs the appropriate URL
 * For images, it utilizes the Next.js `Image` component for optimized rendering.
 * The component also supports displaying a title and subtitle.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {IHeroBanner} props.heroBannerData - Data for the hero banner,
 * including URLs for images or videos, title, and subtitle.
 * @returns A hero section with either an image or video, along with title
 *  and subtitle, tailored for content pages.
 */

const HeroContent: React.FC<{ heroBannerData: IHeroBanner }> = ({ heroBannerData }) => {
  const typeOfMedia = heroBannerData.Video && heroBannerData.Video.Url ? 'video' : 'image';
  const heroContentWrapper = useRef<HTMLDivElement>(null);
  useIsScrolledToSection(heroContentWrapper, 'reveal-image-animation', false, 'top 100%');

  let mediaUrl = '';
  if (typeOfMedia === 'image') {
    const imageUrl = heroBannerData.Image.Url;
    mediaUrl = imageUrl ? (isAbsoluteUrl(imageUrl) ? imageUrl : imageUrl) : '';
  } else {
    const videoUrl = heroBannerData.Video.Url;
    mediaUrl = videoUrl ? (isAbsoluteUrl(videoUrl) ? videoUrl : videoUrl) : '';
  }

  return (
    <div
      ref={heroContentWrapper}
      className={`opacity-0 kafd-hero-video ${styles['kafd-hero-video']} ${styles['kafd-hero-image']} full-width js-dark-banner-fullwidth js-dark-component`}
    >
      <section
        data-animate-class='animate-section'
        className={`${styles['slide-item']} h-full animate-section`}
      >
        <div className={`lozad h-full`}>
          <div className={`${styles['banner-image-holder']} relative overflow-hidden h-full`}>
            <div className='h-full'>
              {typeOfMedia === 'image' && (
                <Image
                  url={mediaUrl}
                  alt={heroBannerData.Title ?? ''}
                  style={{
                    // do not use these directly on <Image - it's been deprecated
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  fill // layout="fill" has been deprecated - use this instead
                  quality={100}
                  priority
                />
              )}

              {typeOfMedia === 'video' && (
                <div className={`${styles['videowrapper']} ${styles['primary-video']}`}>
                  <div
                    data-comp-name='videoPlayer'
                    className={`w-full ${styles['vjs-fluid']} `}
                    id='banner'
                    tabIndex={-1}
                    lang='en'
                    role='region'
                    aria-label='Video Player'
                  >
                    <video
                      id='banner_html5_api'
                      className={`w-full ${styles['vjs-tech']}`}
                      playsInline
                      preload='auto'
                      autoPlay
                      loop
                      muted
                      src={mediaUrl}
                      tabIndex={-1}
                      data-comp-name='videoPlayer'
                    >
                      <source
                        src={mediaUrl}
                        type='video/mp4'
                      />
                      <p>
                        To view this video please enable JavaScript, and consider upgrading to a web browser that{' '}
                        <a
                          href='https://videojs.com/html5-video-support/'
                          target='_blank'
                          hidden
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles['hero-center']}>
          <div className={styles['hero-intro']}>
            <div className={styles['intro-text']}>
              <h1>{heroBannerData.Title}</h1>
              <span>{heroBannerData.Subtitle}</span>
            </div>
          </div>
        </div>

        <div className={styles['awards']}>
          <div className={styles['awards-container']}>
            <p className={styles['awards-title']}>
              AWARD-WINNING <br className={styles.brTitleAwards} /> ARCHITECTURE
            </p>
            <div className={styles['awards-list']}>
              <div className={styles['awards-item']}>
                <Image
                  url='/img/awards/good-design.svg'
                  alt='Good Design Award'
                  className={`${styles['awards-logo']}`}
                  width={100}
                  height={100}
                />
                <span>Good Design Award</span>
              </div>
              <div className={styles['awards-item']}>
                <Image
                  url='/img/awards/world-architecture.svg'
                  alt='World Architecture Festival'
                  className={`${styles['awards-logo']}`}
                  width={100}
                  height={100}
                />
                <span>World Architecture Festival</span>
              </div>
              <div className={styles['awards-item']}>
                <Image
                  url='/img/awards/international-architecture.png'
                  alt='International Architecture Awards'
                  className={`${styles['awards-logo']}`}
                  width={100}
                  height={100}
                />
                <span>International Architecture Awards</span>
              </div>
              <div className={styles['awards-item']}>
                <Image
                  url='/img/awards/leed-platinum-certification.svg'
                  alt='Good Design Award'
                  className={`${styles['awards-logo']} `}
                  width={100}
                  height={100}
                />
                <span>Leed Platinum Certification</span>
              </div>
              <div className={styles['awards-item']}>
                <Image
                  url='/img/awards/smartscore-platinum.jpg'
                  alt='World Architecture Festival'
                  className={`${styles['awards-logo']} `}
                  width={100}
                  height={100}
                />
                <span>SmartScore Platinum</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroContent;
