import * as styles from './HeroFormSuccess.module.scss';
import Icon from '@components/Icon/Icon';
import { useData } from '@context/GraphQLDataContext';
import { IHeroBanner } from '@interfaces/ICommon';

const HeroFormSuccess: React.FC<{ heroBannerData: IHeroBanner }> = ({ heroBannerData }) => {
  const { translate } = useData();
  return (
    <div className={styles.HeroFormSuccessWrapper}>
      <div
        className={`kafd-section ${styles['kafd-formpage-style--default']} lozad animate-section`}
        data-toggle-class='animate-section'
        data-loaded='true'
      >
        <div className={`${styles['kafd-form-container']}`}>
          <div className={`${styles['kafd-form-footer']} ${styles['thankyou-msg']}`}>
            <div className={`w-full mr-auto ml-auto ${styles['infog-box-3']}`}>
              <div className={`flex flex-wrap relative ${styles['helpine-wrap']}`}>
                <div className={`${styles['help-patt']} absolute`}>
                  <Icon.Patt6 className={`${styles['help-patt-svg']}`} />
                </div>

                <div className='w-8/12 lg:w-7/12'>
                  <div className={`${styles['help-left']} relative`}>
                    <h2 className={`${styles['title']}`}>
                      {heroBannerData.Title ?? translate('thank-you-for-your-interest')}
                    </h2>
                  </div>
                </div>

                <div className={`${styles['help-right-wrap']} w-8/12 lg:w-5/12 lg:ml-0`}>
                  <div className={`${styles['help-right']}`}>
                    <div className={`text-reveal-animation`}>
                      <div>{heroBannerData.Subtitle ?? translate('well-be-in-touch')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroFormSuccess;
