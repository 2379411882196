import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './UnorderedList.module.scss';

interface IUnorderedList {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * UnorderedList Component
 *
 *A component representing the <ul>{children}</ul>.
 *
 * @component
 * @param {Object} prop - The props for the UnorderedList component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <ul></ul>.
 * @returns {JSX.Element} The rendered JSX element representing the <ul>{children}</ul>.
 *
 * @example
 *  <UnorderedList className={['test1', 'test2', 'test3']}>TEST</UnorderedList>
 */

const UnorderedList = (prop: IUnorderedList): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <ul
      className={clsx(styles.unorderedList, className)}
      style={style}
    >
      {children}
    </ul>
  );
};

export default UnorderedList;
