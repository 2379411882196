import { FC, useRef, useState, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import * as styles from './Carousel.module.scss';
import Icon from '@components//Icon/Icon';
import clsx from 'clsx';
import useWindowSize from '@hooks/useWindowSize';
import useUUID from '@hooks/useUUID';
import { ICarouselItem } from '@interfaces/IContentPageQuery';
import SimpleCardsLink from '@components/SimpleCardsLink/SimpleCardsLink';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import { DetermineLanguage } from '@helpers/determineLanguage';

interface ICarouselProps {
  countSlidesDesktop?: number;
  countSlidesTablet?: number;
  countSlidesMobile?: number;
  items: ICarouselItem[];
  carouselClassName?: string;
}

/**
 * A carousel component for displaying a list of items.
 * @typedef {Object} CarouselType
 * @property {Array} items - The list of items to be displayed in the carousel.
 * @property {number} [countSlidesDesktop=3] - The number of slides to show on desktop screens.
 * @property {number} [countSlidesTablet=2] - The number of slides to show on tablet screens.
 * @property {number} [countSlidesMobile=1] - The number of slides to show on mobile screens.
 */

const Carousel: FC<ICarouselProps> = ({
  items,
  countSlidesDesktop = 3,
  countSlidesTablet = 2,
  countSlidesMobile = 1,
  carouselClassName = '',
}) => {
  const slider = useRef<Slider>(null);
  const sliderWrapper = useRef<HTMLDivElement>(null);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<string | boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<string | boolean>(false);

  const windowSize = useWindowSize();
  const areAllCardsVisible = !!(nextButtonDisabled && prevButtonDisabled);
  const itemsKeys = useUUID(items?.length);
  const isDisabled = () => {
    if (!sliderWrapper.current) return;

    const isPrevDisabled = !!sliderWrapper.current.querySelector('.slick-prev.slick-disabled');
    const isNextDisabled = !!sliderWrapper.current.querySelector('.slick-next.slick-disabled');

    if (isPrevDisabled) {
      setPrevButtonDisabled('slick-disabled');
    } else {
      setPrevButtonDisabled(false);
    }

    if (isNextDisabled) {
      setNextButtonDisabled('slick-disabled');
    } else {
      setNextButtonDisabled(false);
    }
  };

  const handlePrevSlide = () => {
    slider?.current?.slickPrev();
  };

  const handleNextSlide = () => {
    slider?.current?.slickNext();
  };

  useEffect(() => {
    isDisabled();
  }, [windowSize]);
  const language = DetermineLanguage();

  const handleSwipeDirection = (swipeDirection: string) => {
    if (language === 'ar') {
      if (swipeDirection === 'left') handlePrevSlide();
      else if (swipeDirection === 'right') handleNextSlide();
    } else {
      if (swipeDirection === 'left') handleNextSlide();
      else if (swipeDirection === 'right') handlePrevSlide();
    }
  };

  const sliderSettings: { [key: string]: Settings } = {
    default: {
      infinite: false,
      slidesToShow: countSlidesDesktop,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      draggable: false,
      swipeEvent(swipeDirection) {
        handleSwipeDirection(swipeDirection);
      },
      responsive: [
        {
          breakpoint: 1340,
          settings: {
            slidesToShow: countSlidesTablet,
            slidesToScroll: 1,
            draggable: true,
          },
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: countSlidesMobile,
            slidesToScroll: 1,
            draggable: true,

            // centerPadding: '20px',
            // centerMode: true,
          },
        },
      ],
      afterChange: () => isDisabled(),
    },
  };

  useEffect(() => {
    const successPage = document.querySelector("div[class*='HeroFormSuccess']");
    if (successPage) {
      const animatedSection = sliderWrapper.current;
      animatedSection?.classList.add('transform-carousel');
      animatedSection?.setAttribute('data-anim', 'on');
    }
  }, []);

  useIsScrolledToSection(sliderWrapper, 'transform-carousel', true);
  if (!Array.isArray(items)) return null;

  return (
    <section
      ref={sliderWrapper}
      className={clsx('opacity-0', carouselClassName)}
    >
      <div className={clsx(styles.carouselSection)}>
        <Slider
          ref={slider}
          {...sliderSettings.default}
        >
          {items.map((item, index) => (
            <div
              className='transform-slide'
              key={itemsKeys[index]}
            >
              <SimpleCardsLink
                title={item.ContentLink?.Expanded.Title}
                text={`${item.ContentLink?.Expanded.Text ? item.ContentLink?.Expanded.Text : ''} ${item.ContentLink?.Expanded.Subtitle ? item.ContentLink?.Expanded.Subtitle : ''}`}
                imageSrc={item.ContentLink?.Expanded.Image.Expanded.Url}
                href={item.ContentLink?.Expanded.LinkUrl}
                videoSrc={item.ContentLink?.Expanded.Video.Expanded.Url}
              />
            </div>
          ))}
        </Slider>
        <div className={styles.MotifPiano}>
          <Icon.MotifPiano />
        </div>
        {!areAllCardsVisible && (
          <div className={styles.buttonsWrapper}>
            <button
              onClick={handlePrevSlide}
              className={clsx(styles.button, prevButtonDisabled)}
              aria-label='Prev'
              disabled={!!prevButtonDisabled}
            >
              <Icon.ChevronRightIcon className={styles.iconTransformToLeft} />
            </button>
            <div className={styles.dotIcon} />
            <button
              onClick={handleNextSlide}
              aria-label='Next'
              className={clsx(styles.button, nextButtonDisabled)}
              disabled={!!nextButtonDisabled}
            >
              <Icon.ChevronRightIcon />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Carousel;
