import { useCallback, useEffect, useId, useState } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './VideoPlayer.module.scss';
import Icon from '@components/Icon/Icon';

interface IVideoPlayerProps {
  videoSrc?: string | undefined;
  videoType?: string | undefined;
  coverImageSrc?: string | undefined;
  accentColor?: 'red' | 'purple' | 'blue' | undefined;
  playVideo?: boolean;
}

/**
 * VideoPlayer Component
 *
 *A component that creates a placeholder if no props are received. Depending on the props, a card is created with a title, title and text, or just with text. If a video prop comes, an additional button is designed to launch a video on top of the card.
 *
 * @component
 * @param {Object} prop - The props for the VideoPlayer component.
 * @param {string} prop.videoSrc - The path to a video file.
 * @param {string} prop.videoType - Type of the video. By default is video/mp4.
 * @param {string} prop.coverImageSrc - The path to an image file that will be used as the cover image.
 * @param {string} prop.accentColor - The accent color can be used to coloring the play button.
 * @returns {JSX.Element} The rendered JSX element representing the VideoPlayer.
 *
 * @example
      <VideoPlayer
        videoSrc="https://vjs.zencdn.net/v/oceans.mp4"
        videoType = "video/mp4"
        accentColor="red"
        coverImageSrc="/img/test/img_2_1120x880.jpeg"
      /> 
 */

const VideoPlayer = (prop: IVideoPlayerProps): JSX.Element => {
  const { videoSrc, videoType = 'video/mp4', coverImageSrc, accentColor, playVideo } = prop;
  const [hiddenClass, setHiddenClass] = useState('');
  const [topClass, setTopClass] = useState('');

  const accentBgColor = styles['accentBgColor__' + accentColor];

  const videoId = useId();

  const toggleVideoPlayback = useCallback(() => {
    setHiddenClass((prev) => {
      if (prev === styles.hidden) {
        const video = document.getElementById(videoId) as HTMLMediaElement;
        video?.pause();
        setTopClass('');
        return '';
      } else {
        const video = document.getElementById(videoId) as HTMLMediaElement;
        video?.play().catch(() => video?.pause());
        setTopClass(styles.top);
        return styles.hidden;
      }
    });
  }, [videoId]);

  useEffect(() => {
    if (playVideo) toggleVideoPlayback();
  }, [playVideo, toggleVideoPlayback]);

  return (
    <div className={styles.VideoPlayer}>
      <div className={`${styles.video} ${topClass}`}>
        <video
          id={videoId}
          width='100%'
          height='100%'
          controls
          muted
          webkit-playsinline
          playsInline
          controlsList='nodownload noplaybackrate'
        >
          <source
            src={videoSrc}
            type={videoType}
          ></source>
          <p className='vjs-no-js'>
            To view this video please enable JavaScript, and consider upgrading to a web browser that
            <a
              href='https://videojs.com/html5-video-support/'
              target='_blank'
            >
              supports HTML5 video
            </a>
          </p>
        </video>
        <div
          className={`${styles.closeIcon} cursor-pointer flex items-center justify-center absolute`}
          onClick={toggleVideoPlayback}
        >
          <Icon.CloseModalIcon />
        </div>
      </div>
      <div className={`${styles.playerCoverWrapper} ${hiddenClass}`}>
        {coverImageSrc && (
          <Image
            fill
            sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
            url={coverImageSrc}
            alt=''
          />
        )}
        <div
          className={`${styles.playerButton} ${accentBgColor}  cursor-pointer flex items-center justify-center absolute`}
          onClick={toggleVideoPlayback}
        >
          <Icon.PlayVideoIcon />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
