import { FC, useEffect, useReducer } from 'react';
import Link from 'next/link';
import * as styles from './FoundResult.module.scss';
import Accordion from '@components/AccordionList/Accordion/Accordion';
import clsx from 'clsx';
import { useSearchCategories } from '@hooks/useSearchCategories';
import { SearchResultItem, useSearchInCategory } from '@hooks/useSearchInCategory';
import { useSearch } from '@hooks/useSearch';
import { useData } from '@context/GraphQLDataContext';
import GenericButton from '@components/GenericButton/GenericButton';
import { isElementInView } from '@hooks/useIsScrolledToSection';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { highlightMatchString } from '@helpers/highlightMatchString';
import { FoundResultState } from './FoundResult.reducer';
import { foundResultReducer } from './FoundResult.reducer';
import useUUID from '@hooks/useUUID';

type Props = {
  categories?: { [key: string]: any };
  items?: SearchResultItem[];
  query: string;
  initialTotal: number | undefined;
};

const FoundResult: FC<Props> = ({ query, items, initialTotal }) => {
  const [searchCategories] = useSearchCategories();
  const [searchInCategory] = useSearchInCategory();
  const resultLength = items?.length;
  const [search] = useSearch();
  const { translate } = useData();

  const initialState: FoundResultState = {
    isOpen: undefined,
    loadedItems: [],
    isLoading: false,
    title: { title: 'All', count: resultLength },
    categories: [],
    results: items,
    total: initialTotal,
    allCount: initialTotal,
  };
  const [state, dispatch] = useReducer(foundResultReducer, initialState);
  const categoriesKeys = useUUID(state.categories.length);
  const resultsKeys = useUUID(state.results?.length);
  useEffect(() => {
    searchCategories(query)
      .then((data) => {
        dispatch({
          type: 'SET_CATEGORIES',
          payload: data,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_CATEGORIES',
          payload: [],
        });
      });
  }, [query, searchCategories]);

  const handleOpen = (index: number | undefined) => {
    if (typeof index === 'undefined') {
      dispatch({
        type: 'SET_IS_OPEN',
        payload: undefined,
      });
    } else {
      dispatch({
        type: 'SET_IS_OPEN',
        payload: index,
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      gsap.registerPlugin(ScrollTrigger);

      const genericButton = document.querySelector('.bwcta__btn') as HTMLElement;
      const modalEl = document.querySelector('.searchWrapper') as HTMLElement;

      if (!genericButton) return;
      genericButton.removeAttribute('data-anim');
      ScrollTrigger.create({
        scroller: modalEl,
        start: 0,
        end: 'max',
        onUpdate: () => {
          if (isElementInView(genericButton)) {
            genericButton.dataset.anim = 'on';
          }
        },
      });
    }, 300);

    return () => clearTimeout(timer);
  }, [state.results]);

  const handleLoadMore = () => {
    let newResults = { total: 0, items: [] as SearchResultItem[] };
    dispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });

    if (state.title.title === 'All') {
      search(query, 10, false)
        .then((data) => {
          newResults = {
            total: data.total ?? 0,
            items: data.items ?? [],
          };
          dispatch({
            type: 'SET_TOTAL',
            payload: newResults.total,
          });
          dispatch({
            type: 'SET_RESULTS',
            payload: [...(state.results || []), ...(newResults.items || [])] as SearchResultItem[],
          });
        })
        .catch(() => {
          newResults = { total: 0, items: [] };
        });
    } else {
      const displayedItemsCount = state.results?.length || 0;
      const displayedItems = [
        ...(state.results || []),
        ...(state.loadedItems?.slice(displayedItemsCount, displayedItemsCount + 10) || []),
      ];

      dispatch({
        type: 'SET_RESULTS',
        payload: displayedItems,
      });
    }

    dispatch({
      type: 'SET_IS_LOADING',
      payload: false,
    });
  };

  const handleTitle = async (item: any) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });

    dispatch({
      type: 'SET_RESULTS',
      payload: [],
    });

    dispatch({
      type: 'SET_TITLE',
      payload: { title: item.name, count: item.count },
    });
    let newResults;
    if (item.name === 'All') {
      newResults = await search(query, 10);

      dispatch({
        type: 'SET_ALL_COUNT',
        payload: newResults.total,
      });
    } else {
      const loadedData = await searchInCategory(query, item.name);
      const loadedItems = loadedData?.items;
      dispatch({
        type: 'SET_LOADED_ITEMS',
        payload: loadedItems,
      });

      newResults = { total: loadedData.total, items: loadedItems?.slice(0, 10) };
    }

    dispatch({
      type: 'SET_TOTAL',
      payload: newResults.total,
    });

    dispatch({
      type: 'SET_RESULTS',
      payload: newResults.items as SearchResultItem[],
    });

    dispatch({
      type: 'SET_IS_OPEN',
      payload: undefined,
    });
    dispatch({
      type: 'SET_IS_LOADING',
      payload: false,
    });
  };

  return (
    <div className='kafd-container !pt-[2.5rem] w-full'>
      <div className='w-full min-[1024px]:w-10/12 mx-auto min-[1280px]:p-[0_1.875rem] flex max-[1024px]:flex-wrap'>
        <div className='w-full min-[1024px]:col-3  min-[1024px]:max-w-[22.6%]'>
          <ul className={clsx(styles.sidebarHolder, styles.searchCats)}>
            <li key={'All'}>
              <button
                type='button'
                onClick={() => void handleTitle({ name: 'All', count: state.allCount })}
                className={`w-full flex justify-between ${state.title.title === 'All' ? styles.activeCategory : ''}`}
              >
                <span>{translate('all')}</span>
                <span className='pl-[5px]'>{state.allCount}</span>
              </button>
            </li>
            {state.categories.map((item: any, index: number) => (
              <li key={categoriesKeys[index]}>
                <button
                  type='button'
                  onClick={() => void handleTitle(item)}
                  className={`w-full flex justify-between ${item.name === state.title.title ? styles.activeCategory : ''}`}
                >
                  <span>{translate(item.name)}</span>
                  <span className='pl-[5px]'>{item.count}</span>
                </button>
              </li>
            ))}
          </ul>

          <Accordion
            type='search'
            title={`${state.title.title} (${state.title.count})`}
            handleOpen={handleOpen}
            index={0}
            isOpen={0 === state.isOpen}
            classname={styles.searchAccordion}
          >
            <ul className={styles.sidebarHolder}>
              <li key={'All'}>
                <button
                  type='button'
                  onClick={() => void handleTitle({ name: 'All', count: state.allCount })}
                  className={`w-full text-center ${state.title.title === 'All' ? styles.activeCategory : ''}`}
                >
                  <span>{translate('all')}</span>
                  <span className='pl-[5px]'>{state.total}</span>
                </button>
              </li>
              {state.categories
                .filter((item: any) => item.name !== state.title.title)
                .map((item: any, index: number) => (
                  <li key={categoriesKeys[index]}>
                    <button
                      type='button'
                      onClick={() => void handleTitle(item)}
                      className={`w-full text-center ${state.title.title === item.name ? styles.activeCategory : ''}`}
                    >
                      <span>{translate(item.name)}</span>
                      <span className='pl-[5px]'>{item.count}</span>
                    </button>
                  </li>
                ))}
            </ul>
          </Accordion>
        </div>
        <div className={`w-full min-[1024px]:col-9 min-[1024px]:max-w-[60.85%] ${styles['listOfFoundResults']}`}>
          <div className={styles.statsHolder}>
            {translate('showing')} <span>{state.title.title === 'All' ? state.allCount : state.title.count} </span>
            {translate(state.title.count && state.title.count > 1 ? 'results-in' : 'result-in')}{' '}
            <span>{translate(state.title.title)}</span> {translate('for')} <span>&apos;{query}&apos;</span>
          </div>
          {Array.isArray(state.results) &&
            state.results.map((result, index) => {
              return (
                <div
                  key={resultsKeys[index]}
                  className={styles.itemHolder}
                >
                  <Link
                    prefetch={false}
                    href={result?.RelativePath}
                    className={styles.itemHolderLink}
                    target='_blank'
                  >
                    <div className={styles.title}>
                      <h5>{highlightMatchString(result.Name, query, styles.highlightedTitle)}</h5>
                    </div>
                    <div className={styles.desc}>
                      <div dangerouslySetInnerHTML={{ __html: result.fulltext }} />
                    </div>
                  </Link>
                </div>
              );
            })}
          {state.isLoading && <div className='loader-spinner'></div>}
          {state.total && state.results && state.results.length > 0 && state.total > state.results.length && (
            <div className={`bwcta__btn ${styles['listing-load-more']}`}>
              <GenericButton
                className='bwcta__btn__btn'
                onClick={handleLoadMore}
                title={translate('load-more-results')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FoundResult;
