import { FormElementBase } from '@episerver/forms-sdk';
import React from 'react';

interface ElementCaptionProps {
  element: FormElementBase;
  className?: string;
  onClick?: () => void;
}

export const ElementCaption = (props: ElementCaptionProps) => {
  const { element, className, onClick } = props;
  return (
    <label
      htmlFor={element.key}
      className={`Form__Element__Caption ${className}`}
      onClick={onClick}
    >
      {element.properties.label}
    </label>
  );
};
