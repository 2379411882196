import { Textbox } from '@episerver/forms-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ElementCaption, ValidationMessage, DataList } from './shared';
import Icon from '@components/Icon/Icon';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom } from '@components/WhistleBlowerForm/utils/interfaces';

export interface TextboxElementBlockProps {
  element: Textbox;
}

export const TextboxElementBlock = (props: TextboxElementBlockProps) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, elementRef, validatorClasses, extraAttr } = elementContext;
  const [isFocused, setIsFocused] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onBlurHandler = (e: any) => {
    if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsFocused(false);
      handleBlur(e);
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    const target = e.target.ownerDocument.activeElement as HTMLInputElementCustom;
    target.value = '';
    handleChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  };

  return useMemo(
    () => (
      <ElementWrapper
        className={`FormTextbox ${validatorClasses}`}
        validationResults={validationResults}
        isVisible={isVisible}
      >
        <ElementCaption
          element={element}
          className={value ? 'filled textInputLabel' : 'textInputLabel'}
        />

        <input
          name={element.key}
          id={element.key}
          type="text"
          className="FormTextbox__Input textInput"
          aria-describedby={`${element.key}_desc`}
          placeholder={element.properties.placeHolder}
          value={value}
          autoComplete={element.properties.autoComplete}
          {...extraAttr}
          onChange={handleChange}
          onBlur={onBlurHandler}
          onFocus={() => setIsFocused(true)}
          ref={elementRef}
        />

        <ValidationMessage
          element={element}
          validationResults={validationResults}
        />

        {isFocused ? (
          <Icon.CloseModalIcon
            onClick={handleClearInput}
            onMouseDown={handleClearInput}
            onTouchEnd={handleClearInput}
            className="IconClose IconCloseVisible"
          />
        ) : value && validationResults.result.valid && !isFocused ? (
          <Icon.Tick className="TickIcon" />
        ) : (
          <Icon.CloseModalIcon
            onClick={handleClearInput}
            onMouseDown={handleClearInput}
            onTouchEnd={handleClearInput}
            className="IconClose IconCloseVisible"
          />
        )}

        <DataList element={element} />
      </ElementWrapper>
    ),
    [isVisible, validationResults, value, isFocused]
  );
};
