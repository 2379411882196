import SimpleCardsLink from '@components/SimpleCardsLink/SimpleCardsLink';
import * as styles from './NoCarousel.module.scss';
import useUUID from '@hooks/useUUID';
import { useRef } from 'react';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import ResidentsCard from '@components/ResidentsCard/ResidentsCard';

interface IImageFile {
  __typename: string;
  Url: string;
}

interface IVideoFile {
  __typename: string;
  Url: string;
}

interface IContentModelReference<T> {
  __typename: string;
  Expanded: T;
}

interface ICardBlock {
  __typename: string;
  Title: string;
  Subtitle: string;
  LinkText: string;
  LinkUrl: string;
  Text: string;
  Image: IContentModelReference<IImageFile> | { __typename: string; Url: string }; // including both possible structures
  Video?: IContentModelReference<IVideoFile>;
  ImageAsSecond?: boolean;
  Thumbnail?: { __typename: string; Url: string };
}

interface IContentModelReferenceSearch {
  __typename: string;
  Expanded: ICardBlock;
}

interface IContentAreaItemModelSearch {
  __typename: string;
  ContentLink: IContentModelReferenceSearch;
}

interface INoCarouselProps {
  items: IContentAreaItemModelSearch[];
}

type ColorType = 'purple' | 'blue' | 'red' | undefined;

type ImageType = { Url: string } | { Expanded: { Url: string } };

// Extracts the URL from an image object that may have varying structures
function getImageUrl(image: ImageType): string {
  if ('Url' in image) {
    return image.Url || '';
  } else if (image.Expanded && 'Url' in image.Expanded) {
    return image.Expanded.Url || '';
  }
  return '';
}

const NoCarousel: React.FC<INoCarouselProps> = ({ items }) => {
  const specifyClassName = items.length > 2 ? '' : styles.items;
  const itemsKeys = useUUID(items?.length);
  const noCarouselWrapper = useRef<HTMLUListElement>(null);
  useIsScrolledToSection(noCarouselWrapper, 'transform-carousel', true);

  return (
    <ul
      ref={noCarouselWrapper}
      className={items[0]?.ContentLink.Expanded.ImageAsSecond ? '' : styles.NoCarousel}
    >
      {Array.isArray(items) &&
        items.map((item: IContentAreaItemModelSearch, index: number) => (
          <li
            className={`${specifyClassName} ${item?.ContentLink.Expanded.ImageAsSecond ? '' : styles.listItem}`}
            key={itemsKeys[index]}
          >
            {item?.ContentLink.Expanded.ImageAsSecond ? (
              <ResidentsCard
                title={item.ContentLink?.Expanded.Title}
                smallImageSrc={
                  item.ContentLink?.Expanded.Thumbnail
                    ? getImageUrl(item.ContentLink.Expanded.Thumbnail).startsWith('/')
                      ? getImageUrl(item.ContentLink.Expanded.Thumbnail)
                      : getImageUrl(item.ContentLink.Expanded.Thumbnail)
                    : ''
                }
                bigImageSrc={
                  item.ContentLink?.Expanded.Image
                    ? getImageUrl(item.ContentLink.Expanded.Image).startsWith('/')
                      ? getImageUrl(item.ContentLink.Expanded.Image)
                      : getImageUrl(item.ContentLink.Expanded.Image)
                    : ''
                }
                linkHref={item.ContentLink?.Expanded.LinkUrl}
                linkText={item.ContentLink?.Expanded.LinkText}
                linkPosition={index % 2 == 0 ? 'right' : 'left'}
              />
            ) : (
              <SimpleCardsLink
                accentColor={
                  item.ContentLink?.Expanded.LinkUrl ? chooseColor(item.ContentLink?.Expanded.LinkUrl) : undefined
                }
                title={item.ContentLink?.Expanded.Title}
                text={item.ContentLink?.Expanded.Subtitle}
                imageSrc={
                  item.ContentLink?.Expanded.Image
                    ? getImageUrl(item.ContentLink.Expanded.Image).startsWith('/')
                      ? getImageUrl(item.ContentLink.Expanded.Image)
                      : getImageUrl(item.ContentLink.Expanded.Image)
                    : ''
                }
                href={item.ContentLink?.Expanded.LinkUrl}
                videoSrc={
                  item.ContentLink?.Expanded.Video && item.ContentLink?.Expanded.Video.Expanded
                    ? item.ContentLink?.Expanded.Video.Expanded.Url ?? ''
                    : ''
                }
              />
            )}
          </li>
        ))}
    </ul>
  );
};

export default NoCarousel;

const chooseColor = (url: string | undefined): ColorType => {
  if (!url) {
    return undefined;
  }

  switch (true) {
    case url.endsWith('/envision/'):
      return 'purple';
    case url.endsWith('/accelerate/'):
      return 'blue';
    case url.endsWith('/live/'):
      return 'red';
    default:
      return undefined;
  }
};
