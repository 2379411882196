import { useCallback, useEffect, useState } from 'react';
import { IEvent } from '@interfaces/IEventsPage';
import EventCard from './EventCard/EventCard';
import UnorderedList from '@components/common/htmlTags/UnorderedList/UnorderedList';
import ListItem from '@components/common/htmlTags/ListItem/ListItem';
import * as styles from './EventsList.module.scss';
import { DetermineLanguage } from '@helpers/determineLanguage';
import useUUID from '@hooks/useUUID';
import React from 'react';

interface IEventList {
  eventCards: IEvent[];
  isLoading?: boolean;
}

/**
 * EventsList Component
 *
 * Component that displays a list of upcoming events.
 *
 * @component
 * @param {Object} props - The props for the EventsList component.
 * @param {boolean} props.isLoading - Indicates if the events data is still loading.
 * @param {IEventCard[]} props.eventCards - An array of objects with information about the events.
 *
 * @returns {JSX.Element|null} The rendered JSX element representing the EventsList, or null if the data is still loading.
 *
 * @example
 * const eventCards = [
 *   {
 *     DateFrom: '2023-05-01T00:00:00',
 *     DateTo: '2023-05-05T00:00:00',
 *     Heading: 'Event Title',
 *     Image: { Url: 'https://example.com/image.jpg' },
 *     LinkText: 'Learn More',
 *     LinkUrl: 'https://example.com/event',
 *     Perex: { Structure: 'Event description...' }
 *   },
 *   // ...
 * ];
 *
 * <EventsList isLoading={false} eventCards={eventCards} />
 */

const EventsList = ({ isLoading, eventCards }: IEventList): JSX.Element | null => {
  const language = DetermineLanguage();
  const [transformedEventCards, setTransformedEventCards] = useState<any>([]);
  const cardsListItemKeys = useUUID(transformedEventCards.length);

  const formatDate = useCallback(
    (isoString: string): string => {
      const date = new Date(isoString);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      };
      const formattedDate = new Intl.DateTimeFormat(language === 'ar' ? 'ar-AE' : 'en-US', options).format(date);
      const [day, month, year] = formattedDate.split(' ');
      const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${month} ${day}/${monthNumber}/${year}`;
    },
    [language]
  );

  useEffect(() => {
    setTransformedEventCards(
      Array.isArray(eventCards) &&
        eventCards.map((event) => ({
          dateFrom: formatDate(event.DateFrom),
          dateTo: event.DateTo ? formatDate(event.DateTo) : null,
          title: event.Heading,
          imageSrc: event.Image?.Url || '',
          buttonTitle: event.LinkText,
          buttonUrl: event.LinkUrl,
          eventDescription: event.Perex?.Structure || '',
        }))
    );
  }, [eventCards, formatDate]);

  return (
    <div className={styles.EventList}>
      <UnorderedList>
        {Array.isArray(transformedEventCards) &&
          transformedEventCards.map((event, index) => (
            <ListItem key={cardsListItemKeys[index]}>
              <EventCard
                dateFrom={event.dateFrom}
                dateTo={event.dateTo}
                title={event.title}
                imageSrc={event.imageSrc}
                buttonTitle={event.buttonTitle}
                buttonUrl={event.buttonUrl}
                eventDescription={event.eventDescription}
                isArticlePreview={false}
              />
            </ListItem>
          ))}
      </UnorderedList>
      {isLoading && <div className='loader-spinner'></div>}
    </div>
  );
};

export default EventsList;
