import Link from 'next/link';
import React, { ReactNode, MouseEvent, CSSProperties } from 'react';
import clsx from 'clsx';
import * as styles from './GenericButton.module.scss';

interface IGenericButtonProps {
  className?: string | string[];
  title?: string;
  children?: ReactNode;
  id?: string;
  url?: string;
  style?: CSSProperties;
  innerSpanStyle?: CSSProperties;
  innerSpanClass?: string | string[];
  target?: '_blank' | '_self' | '_parent' | '_top';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

/**
 * GenericButton Component
 *
 * A component based on a classic link or button with a span inside.
 *
 * @component
 * @param {Object} props - The props for the GenericButton component.
 *  * @param {string} [props.className] - additional class that can be passed to style the GenericButton.
 * @param {string} [props.title] - The text to display inside a span within the link/button. If `children` is provided, `title` is ignored.
 * @param {ReactNode} [props.children] - The children nodes to be displayed inside the span within the link/button. Takes precedence over `title`.
 * @param {string} [props.id] - The ID of the button or link for DOM identification.
 * @param {string} [props.url] - The href URL for the link. When provided, the component renders as an `<a>` element.
 * @param {Function} [props.onClick] - The event handler function called when the button is clicked. When provided,
 * the component renders as a `<button>` element. Note: If both `url` and `onClick` are provided,
 * the component will render as a link, and the `onClick` will not be utilized.
 * @returns {JSX.Element | null} - The rendered JSX element representing the button, or null if neither `url` nor `onClick` is provided. *
 * @example
 *
 * <GenericButton
 *   id="button_id"
 *   onClick={() => console.log('ONCLICK')}
 * >
 *   BACK TO HOMEPAGE BUTTON
 * </GenericButton>
 *
 * @example
 * // To render as a link with an href:
 * <GenericButton
 *   id="button_id"
 *   url="#"
 * >
 *   BACK TO HOMEPAGE LINK
 * </GenericButton>
 */

const GenericButton: React.FC<IGenericButtonProps> = (prop): JSX.Element | null => {
  const {
    className: classNamePropForGenericButton,
    style,
    innerSpanStyle,
    innerSpanClass,
    children,
    title,
    target,
    id,
    url,
    onClick,
  } = prop;

  const spanClassName = clsx(styles.text, innerSpanClass);

  const buttonContent =
    title && title.toLowerCase() !== 'button' ? (
      <span
        style={innerSpanStyle}
        className={spanClassName}
      >
        {title}
      </span>
    ) : children ? (
      <span
        style={innerSpanStyle}
        className={spanClassName}
      >
        {children}
      </span>
    ) : (
      ''
    );

  const className = styles['btn__primary-1'];

  if (url)
    return (
      <Link
        prefetch={false}
        id={id}
        href={url}
        style={style}
        target={target}
        className={clsx(className, classNamePropForGenericButton)}
      >
        {buttonContent}
      </Link>
    );
  if (onClick)
    return (
      <button
        id={id}
        style={style}
        className={clsx(className, classNamePropForGenericButton)}
        onClick={onClick}
      >
        {buttonContent}
      </button>
    );
  return null;
};

export default GenericButton;
