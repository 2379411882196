import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

interface ISpan {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Span Component
 *
 *A component representing the <p>{children}</p>.
 *
 * @component
 * @param {Object} prop - The props for the Span component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <p></p>.
 * @returns {JSX.Element} The rendered JSX element representing the <p>{children}</p>.
 *
 * @example
 *  <Span className={['test1', 'test2', 'test3']}>TEST</Span>
 */

const Span = (prop: ISpan): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <span
      className={clsx(className)}
      style={style}
    >
      {children}
    </span>
  );
};

export default Span;
