import AccordionList from '@components/AccordionList/AccordionList';
import GoogleMap from '@components/GoogleMap/GoogleMap';
import RichTextComponent from '@components/RichText/RichText';
import TabsBlockWithFetch from '@components/TabsBlock/TabsBlockWithFetch';
import VideoPlayer from '@components/VideoPlayer/VideoPlayer';
import { IComponentsArea } from '@layouts/ContentPageLayout';
import Carousel from '@components/Carousel/Carousel';
import CarouselLive from '@components/CarouselLive/CarouselLive';
import NoCarousel from '@components/NoCarousel/NoCarousel';
import FileList from '@components/FileList/FileList';
import { IFileListData } from '@interfaces/IFileListData';
import CarouselWithControl from '@components/CarouselWithControl/CarouselWithControl';
import PersonCardsList from '@components/PersonCardsList/PersonCardsList';
import TextAndImage from '@components/CardTextAndImage/CardTextAndImage';

export const renderContentByType = (item: IComponentsArea) => {
  const type = item.ContentLink.Expanded.__typename;
  switch (type) {
    case 'RichtextBlock': {
      const data = item.ContentLink.Expanded.Editor?.Structure ?? '';
      return (
        <RichTextComponent
          className={`contenteditor ${item.ContentLink.Expanded.Classname}`}
          data={data}
        />
      );
    }
    case 'CardListBlock':
      return renderCarouselByType(item.ContentLink.Expanded);
    case 'FaqSectionBlock':
      return <AccordionList {...(item.ContentLink.Expanded ?? {})} />;

    case 'TabListBlock':
      return <TabsBlockWithFetch id={item.ContentLink.Expanded.ContentLink.Id} />;

    case 'VideoBlock':
      return (
        <VideoPlayer
          {...{
            videoSrc: item.ContentLink.Expanded.Video.Expanded.Url,
            coverImageSrc: item.ContentLink.Expanded.PreviewImage.Url,
          }}
        />
      );
    case 'MapBlock':
      return (
        <GoogleMap
          content={item.ContentLink.Expanded.Editor?.Structure ?? ''}
          config={item.ContentLink.Expanded.Config ?? ''}
        />
      );
    case 'FileListBlock':
      return <FileList fileListData={item.ContentLink.Expanded as unknown as IFileListData} />;
    case 'ImageTextBlock':
      return <TextAndImage data={item.ContentLink.Expanded} />;
    default:
      return null;
  }
};

export const renderCarouselByType = ({ Type, Items, Classname = '' }: any) => {
  switch (Type) {
    case 'carousel':
      return (
        <Carousel
          items={Items ?? []}
          carouselClassName={Classname}
        />
      );
    case 'carousel_text':
      return <CarouselLive items={Items ?? []} />;
    case 'carousel_controls':
      return <CarouselWithControl items={Items ?? []} />;
    case 'persons_list':
      return <PersonCardsList items={Items} />;
    case 'no_carousel':
      return <NoCarousel items={Items ?? []} />;
    default:
      return null;
  }
};
