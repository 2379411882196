interface DownloadIconLinkSvgProps {
  className?: string;
}

const DownloadIconLinkSvg: React.FC<DownloadIconLinkSvgProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25 25'
    className={className}
  >
    <path
      fillRule='evenodd'
      d='M1.876.123c.759 0 1.376.615 1.376 1.376v20.75h18.497v-5.271c0-.76.615-1.376 1.376-1.376.76 0 1.375.615 1.375 1.376v5.45c0 1.419-1.153 2.572-2.572 2.572H3.073C1.654 25 .5 23.847.5 22.428V1.498C.5.74 1.117.124 1.876.124zm16.449 2.83c.365-.007.717.132.98.385.263.254.413.601.42.965l.139 7.98c.007.38-.142.727-.386.98-.244.252-.587.413-.966.42-.76.012-1.387-.593-1.4-1.352l-.082-4.724-7.387 7.65c-.528.546-1.398.56-1.945.033-.547-.528-.561-1.398-.034-1.945l7.323-7.582-4.594.08c-.759.013-1.386-.593-1.399-1.351-.013-.76.592-1.387 1.352-1.4z'
    />
  </svg>
);

export default DownloadIconLinkSvg;
