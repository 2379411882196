import React, { useCallback, useEffect, useState } from 'react';
import { DetermineLanguage } from '@helpers/determineLanguage';
import { IConcernWithValue, HTMLInputElementCustom, IInputProps } from '../utils/interfaces';
import { validateAllConcerns, getWhistleblowerData, getTranslation } from '../utils/helpers';
import { TranslationKeys } from '../translations/translationKeys';

const ENDPOINT_CONCERNS = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/Concerns`;

const Concerns: React.FC<IInputProps> = ({
  name,
  onChange,
  required = false,
  validationMessage,
  shouldValidate,
  setHasFetchError,
  counterLoaderHelper,
}) => {
  const [concerns, setConcerns] = useState<IConcernWithValue[]>([]);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const language = DetermineLanguage();

  const validateConcerns = useCallback(() => {
    return validateAllConcerns(concerns);
  }, [concerns]);
  const isValid = validateConcerns();

  const fetchConcerns = useCallback(async () => {
    try {
      if (counterLoaderHelper) counterLoaderHelper((prev) => prev + 1);

      await getWhistleblowerData(
        ENDPOINT_CONCERNS,
        setConcerns,
        (itemConcern) => ({ ...itemConcern, value: false }),
        setHasFetchError
      ).finally(() => {
        if (counterLoaderHelper) counterLoaderHelper(-1);
      });
    } catch (error) {
      if (setHasFetchError) setHasFetchError(true);
    } finally {
      if (counterLoaderHelper) counterLoaderHelper(-1);
    }
  }, [counterLoaderHelper, setHasFetchError]);

  useEffect(() => {
    void fetchConcerns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = useCallback(() => {
    setIsTouched(true);
    const target = {
      name,
      value: concerns,
      isValid,
    } as unknown as HTMLInputElementCustom;
    onChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  }, [concerns, isValid, name, onChange]);

  useEffect(() => {
    const target = { name, value: concerns, isValid } as unknown as HTMLInputElementCustom;
    onChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  }, [concerns, isValid, name, onChange]);

  useEffect(() => {
    if (shouldValidate) {
      handleBlur();
    }
  }, [handleBlur, shouldValidate]);

  const handleRadioButtonChange = useCallback((e: React.ChangeEvent<HTMLInputElementCustom>) => {
    setConcerns((prevConcerns: IConcernWithValue[]) =>
      prevConcerns.map((item) => {
        if (item.Id === e.target.id) {
          return { ...item, value: true };
        } else {
          return { ...item, value: false };
        }
      })
    );
  }, []);

  return (
    <div className='FormChoice'>
      <h5 className='FormChoice__Title'>
        <span>{getTranslation(TranslationKeys.PLACEHOLDER_CONCERNS)}</span>
      </h5>
      {concerns?.map((item) => {
        return (
          <React.Fragment key={item.Id}>
            <label
              htmlFor={item.Id}
              className='FormChoice__Item'
            >
              <input
                type='radio'
                id={item.Id}
                name='concerns'
                className='FormChoice__Input FormChoice__Input--Radio'
                checked={item.value}
                onChange={handleRadioButtonChange}
                onBlur={handleBlur}
              />
              <span className='FormChoice__Dotmark'></span>

              <span className='FormChoice__Checkmark-Text'>{language === 'ar' ? item.arabicLabel : item.label}</span>
            </label>
          </React.Fragment>
        );
      })}

      {!isValid && isTouched && required && <span style={{ color: 'red' }}>{validationMessage}</span>}
    </div>
  );
};

export default Concerns;
