import { useRef } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './PersonCard.module.scss';
import HtmlTags from '@components/common/htmlTags';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import React from 'react';

interface IPersonCard {
  memberName: string;
  memberTitle: string;
  imageSrc: string;
}

const PersonCard: React.FC<IPersonCard> = ({ memberName, memberTitle, imageSrc }) => {
  const personWrapper = useRef<HTMLDivElement>(null);
  useIsScrolledToSection(personWrapper, '', true);
  return (
    <div
      ref={personWrapper}
      className={styles.PersonCard}
    >
      <div className={styles['member-image-holder']}>
        <div className={styles['img-holder']}>
          <Image
            width={245}
            height={246}
            url={imageSrc ?? ''}
            alt={memberName ?? ''}
          />
        </div>
      </div>
      <div className={styles['member-details']}>
        <HtmlTags.Heading4 className={styles['member-name']}>{memberName}</HtmlTags.Heading4>
        <HtmlTags.Paragraph className={styles['member-title']}>{memberTitle}</HtmlTags.Paragraph>
      </div>
    </div>
  );
};

export default React.memo(PersonCard);
