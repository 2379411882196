import gsap from 'gsap';

export const animateSection = (el: any) => {
  const tl = gsap.timeline();
  return tl.fromTo(el, { opacity: 0, y: 40 }, { opacity: 1, y: 0, duration: 1, ease: 'Power1.in' });
};

export const fadeIn = (el: any) => {
  const tl = gsap.timeline();
  return tl.fromTo(el, { opacity: 0 }, { opacity: 1, transition: 'opacity', duration: 0.8 });
};
