import React, { useCallback, useEffect, useState } from 'react';
import Hero from '@components/Hero/Hero';
import { IHeroBanner } from '@interfaces/ICommon';
import { queryGraphQl } from '@lib/apolloClient';
import ArticleListQuery from '@graphql/queries/ArticleListQuery.graphql';
import * as styles from '@components/ArticleList/ArticleList.module.scss';
import ArticleList from '@components/ArticleList/ArticleList';
import LazyGallery from '@components/LazyGallery/LazyGallery';
import Tabs from '@components/TabsBlock/Tabs/Tabs';
import GenericButton from '@components/GenericButton/GenericButton';
import EventCard from '@components/EventsList/EventCard/EventCard';
import { useData } from '@context/GraphQLDataContext';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { DetermineLanguage } from '@helpers/determineLanguage';

interface IMediaCenterPageLayout {
  HeroBanner: {
    ContentLink: {
      Expanded: IHeroBanner;
    };
  };
  ContentLink: {
    Id: string;
  };
  Classname: string;
  GalleryItems: any[];
}

const MediaCenterPageLayout = ({ data }: { data: IMediaCenterPageLayout }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState<any[]>([]);

  const [totalArticles, setTotalArticles] = useState<number>(0);
  // 1st query's cursor is empty; subsequent queries use generated cursor to fetch subset of articles (cached by Optimizely cca 10 mins)
  // https://docs.developers.optimizely.com/platform-optimizely/v1.4.0-optimizely-graph/docs/cursor
  const [cursor, setCursor] = useState<string>('');
  const { translate } = useData();
  const language = DetermineLanguage();

  const heroBannerData: IHeroBanner = data?.HeroBanner.ContentLink.Expanded;
  const id = data?.ContentLink.Id;

  const initialLoadNews = useCallback(async () => {
    setIsLoading(true);
    try {
      const articleList = await queryGraphQl<any>(ArticleListQuery, { id, locale: language });
      setTotalArticles(articleList?.ArticlePage.total);
      setCursor(articleList?.ArticlePage.cursor);
      return articleList ?? [];
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching Articles:', error.message || error);
      } else {
        console.error('Unknown error fetching Articles:', error);
      }
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [id, language]);

  const loadMoreNews = async () => {
    setIsLoading(true);
    try {
      const loadedArticleList = await queryGraphQl<any>(ArticleListQuery, { id, locale: language, cursor });
      setArticles([...articles, ...loadedArticleList.ArticlePage.items]);
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error loading more Articles:', error.message || error);
      } else {
        console.error('Unknown error loading more Articles:', error);
      }
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchArticles = async () => {
      const fetchedArticles = await initialLoadNews();
      fetchedArticles?.ArticlePage && setArticles(fetchedArticles.ArticlePage.items);
    };
    fetchArticles().catch(() => setArticles([]));
  }, [initialLoadNews]);

  const tabsItems = [
    {
      title: translate('in-the-news'),
      content: (
        <>
          {articles.length ? <ArticleList articles={articles} /> : <ErrorMessage className='text-center' />}
          {totalArticles > articles?.length &&
            (isLoading ? (
              <div className='loader-spinner'></div>
            ) : (
              <div className={`${styles['news__load_more_btn']}`}>
                <GenericButton
                  className='bwcta__btn__btn'
                  onClick={() => void loadMoreNews()}
                >
                  {translate('load-more-results')}
                </GenericButton>
              </div>
            ))}
        </>
      ),
    },
    {
      title: translate('media-gallery'),
      content: data ? (
        <LazyGallery
          cards={data?.GalleryItems}
          title={translate('media-gallery')}
        />
      ) : (
        <ErrorMessage className='text-center' />
      ),
    },
  ];

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  return (
    <>
      {heroBannerData && <Hero heroBannerData={heroBannerData} />}

      <section className={`kafd-main-sec kafd-container mediaCenterPage ${data?.Classname}`}>
        {articles?.length > 0 ? (
          <div className='w-full xl:w-11/12 ml-auto xl:pr-8'>
            <EventCard
              dateFrom={formatDate(articles[0].Date)}
              title={articles[0].PreviewTitle}
              imageSrc={articles[0].PreviewImage.Url}
              buttonTitle={translate('read-more')}
              buttonUrl={articles[0].Url}
              isArticlePreview={true}
            />
          </div>
        ) : (
          <ErrorMessage className='text-center' />
        )}

        <Tabs items={tabsItems} />
      </section>
    </>
  );
};

export default MediaCenterPageLayout;
