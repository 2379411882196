import { FC, useRef, useState, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import * as styles from './CarouselWithControl.module.scss';
import Icon from '@components/Icon/Icon';
import clsx from 'clsx';
import useWindowSize from '@hooks/useWindowSize';
import useUUID from '@hooks/useUUID';
import { ICarouselItem } from '@interfaces/IContentPageQuery';
import Image from '@components/common/htmlTags/Image/Image';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import { DetermineLanguage } from '@helpers/determineLanguage';

type ICarouselWithControlsProps = {
  items: ICarouselItem[];
};

const CarouselWithControl: FC<ICarouselWithControlsProps> = ({ items }) => {
  const slider = useRef<Slider>(null);
  const sliderWrapper = useRef<HTMLDivElement>(null);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<string | boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<string | boolean>(false);
  const windowSize = useWindowSize();
  const areAllCardsVisible = !!(nextButtonDisabled && prevButtonDisabled);
  const itemsKeys = useUUID(items?.length);
  useIsScrolledToSection(sliderWrapper, 'visible-section', true);

  const isDisabled = () => {
    if (!sliderWrapper.current) return;

    const isPrevDisabled = !!sliderWrapper.current.querySelector('.slick-prev.slick-disabled');
    const isNextDisabled = !!sliderWrapper.current.querySelector('.slick-next.slick-disabled');

    if (isPrevDisabled) {
      setPrevButtonDisabled('slick-disabled');
    } else {
      setPrevButtonDisabled(false);
    }

    if (isNextDisabled) {
      setNextButtonDisabled('slick-disabled');
    } else {
      setNextButtonDisabled(false);
    }
  };

  const handlePrevSlide = () => {
    slider?.current?.slickPrev();
  };

  const handleNextSlide = () => {
    slider?.current?.slickNext();
  };

  useEffect(() => {
    isDisabled();
  }, [windowSize]);

  const language = DetermineLanguage();

  const handleSwipeDirection = (swipeDirection: string) => {
    if (language === 'ar') {
      if (swipeDirection === 'left') handlePrevSlide();
      else if (swipeDirection === 'right') handleNextSlide();
    } else {
      if (swipeDirection === 'left') handleNextSlide();
      else if (swipeDirection === 'right') handlePrevSlide();
    }
  };

  const handleClasses = (el: Element, removeClass = false) => {
    if (removeClass) {
      el.classList.remove('switched-slide');
      el.classList.remove('reveal-container');
      return;
    }

    el.classList.add('switched-slide');
    el.classList.add('reveal-container');
  };

  const sliderSettings: { [key: string]: Settings } = {
    default: {
      infinite: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      draggable: false,
      onReInit: () => {
        if (!sliderWrapper.current) return;
        const currentSlide = sliderWrapper.current.querySelector('.slick-current');

        if (!currentSlide) return;
        handleClasses(currentSlide);
      },
      beforeChange: () => {
        if (!sliderWrapper.current) return;
        const currentSlide = sliderWrapper.current.querySelector('.slick-current');

        if (!currentSlide) return;
        handleClasses(currentSlide, true);
      },
      afterChange: () => {
        isDisabled();
        if (!sliderWrapper.current) return;
        const currentSlide = sliderWrapper.current.querySelector('.slick-current');

        if (!currentSlide) return;
        handleClasses(currentSlide);
      },
      swipeEvent(swipeDirection) {
        handleSwipeDirection(swipeDirection);
      },
    },
  };

  if (!Array.isArray(items)) return null;

  return (
    <section
      ref={sliderWrapper}
      className='opacity-0'
    >
      <div className={clsx(styles.carouselWithControls, 'w-full')}>
        <Slider
          ref={slider}
          {...sliderSettings.default}
        >
          {items.map((item, index) => (
            <div
              key={itemsKeys[index]}
              className={styles.sectionItem}
            >
              <div className={clsx('image-container / h-[inherit]')}>
                <Image
                  sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 100vw'
                  url={item.ContentLink?.Expanded.Image.Expanded.Url}
                  alt='Image'
                  fill
                  loading='lazy'
                />
              </div>
              <div className={clsx('reveal-container-text-wrapper / ', styles.sectionItemDescWrapper)}>
                <div className={clsx('reveal-container-text / ', styles.sectionItemDesc)}>
                  <div className='flex flex-col'>
                    <span className={styles.contentLabel}>{item.ContentLink?.Expanded.Subtitle}</span>
                    <span className={styles.contentTitle}>{item.ContentLink?.Expanded.Title}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {!areAllCardsVisible && (
          <div className={styles.buttonsWrapper}>
            <button
              onClick={handlePrevSlide}
              className={clsx(styles.button, prevButtonDisabled)}
              aria-label='Prev'
              disabled={!!prevButtonDisabled}
            >
              <Icon.ChevronRightIcon className={styles.iconTransformToLeft} />
            </button>
            <div className={styles.dotIcon} />
            <button
              onClick={handleNextSlide}
              aria-label='Next'
              className={clsx(styles.button, nextButtonDisabled)}
              disabled={!!nextButtonDisabled}
            >
              <Icon.ChevronRightIcon className={styles.iconTransformToRight} />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default CarouselWithControl;
