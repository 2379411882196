import React from 'react';
import styles from './ConfirmationModal.module.scss';
import Icon from '@components/Icon/Icon';
import { getTranslation } from '../../utils/helpers';
import { TranslationKeys } from '../../translations/translationKeys';
import { IConfirmationModal } from '../../utils/interfaces';

const ConfirmationModal: React.FC<IConfirmationModal> = ({ handleModal, title, onConfirm }) => {
  return (
    <div
      className={`${styles['kafd-file-popup']} ${styles['js-file-popup']} ${styles['active']}`}
      data-active-file-instance=''
    >
      <div className={`${styles['file-popup-content']}`}>
        <a
          href='#'
          className={`${styles['close-btn']} ${styles['js-popup-close-btn']}`}
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            handleModal('confirmation', false);
          }}
        >
          <Icon.CloseModalIcon className={`${styles['close-icon']} ${styles['icon-img']}`} />
        </a>

        <span className={styles['file-remove-msg']}>{title}</span>

        <div className={styles['file-btn-group']}>
          <a
            className={`${styles['btn__primary-1']} ${styles['btn-primary']} ${styles['btn-reject']} ${styles['js-popup-close-btn']}`}
            href='#'
            onClick={(e) => {
              e.preventDefault();
              handleModal('confirmation', false);
            }}
          >
            <span className={styles['text']}>{getTranslation(TranslationKeys.CANCEL)}</span>
          </a>
          <a
            className={`${styles['btn__primary-1']} ${styles['btn-primary']} ${styles['js-confirm-delete']}`}
            href=''
            onClick={(e) => {
              e.preventDefault();
              if (onConfirm) onConfirm();
            }}
          >
            <span className={styles['text']}>{getTranslation(TranslationKeys.YES)}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
