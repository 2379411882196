import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  target: string;
  children: ReactNode;
};

const Portal: FC<Props> = ({ target, children }) =>
  createPortal(children, document.getElementById(target) as HTMLElement);

export default Portal;
