import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { hydrateRoot } from 'react-dom/client';
import Head from 'next/head';
import LayoutAdmin from '@layouts/Layout';
import '@styles/index.scss';
import { DataProvider } from '../context/GraphQLDataContext';

const App = ({ Component, pageProps }: any) => {
  const router = useRouter();
  const [isAdminMode, setAdminMode] = useState(false);

  useEffect(() => {
    setAdminMode(router.query.epieditmode === 'true');
  }, [router.query]);

  useEffect(() => {
    if (isAdminMode) {
      const container = document.querySelector("[data-epi-property-name='ComponentsArea']");
      if (container) {
        hydrateRoot(
          container,
          <LayoutAdmin
            data={pageProps}
            isAdmin={isAdminMode}
          >
            <Component {...pageProps} />
          </LayoutAdmin>
        );
      } else {
        console.log('no container');
      }
    }
  }, [isAdminMode, Component, pageProps]);

  return (
    <>
      <Head>
        <title>{pageProps.data?.MetaTitle || 'KAFD'}</title>
        <meta
          name='description'
          content={pageProps.data?.MetaDescription || ''}
        />
        <meta
          name='keywords'
          content={pageProps.data?.MetaKeywords || ''}
        />
      </Head>
      <DataProvider value={pageProps.globalData}>
        <Component {...pageProps} />
      </DataProvider>
    </>
  );
};

export default App;
