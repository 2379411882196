import { useRef } from 'react';
import Link from 'next/link';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './Article.module.scss';
import Icon from '@components/Icon/Icon';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import { useData } from '@context/GraphQLDataContext';
import { trimUrl } from '@helpers/url.helper';
import React from 'react';
import useUUID from '@hooks/useUUID';

export interface IArticle {
  Date: string;
  Tags: string[] | undefined;
  Url: string;
  PreviewImage: {
    Url: string;
  };
  PreviewTitle: string;
  PreviewSubtitle?: string;
}

/**
 * Article Component
 *
 * This component displays an article about an upcoming event, including details such as title, date, preview image, and tags. It uses the IArticle interface to ensure the props match the expected data structure.
 *
 * @component
 * @param {IArticle} props The props for the Article component.
 * @param {string} props.Date The date of the upcoming event.
 * @param {string[]} props.Tags An array of tags related to the article. For now hardcoded, but will come from Optimisely.
 * @param {Object} props.PreviewImage An object containing the URL for the preview image.
 * @param {string} props.PreviewImage.Url The URL for the preview image.
 * @param {string} props.PreviewTitle The title shown in the preview of the article.
 * @param {string} [props.PreviewSubtitle] The subtitle shown in the preview of the article, if available.
 * @returns {JSX.Element | null} The rendered JSX element representing the Article or null if required props are missing.
 *
 * @example
 * <Article
 *   Date='2024-02-22T16:40:20Z'
 *   Tags={['Business', 'Finance', 'Real Estate']}
 *   PreviewImage={{ Url: '/img/360x360_FH4FU3_dV24.jpg' }}
 *   PreviewTitle='KAFD Awards'
 *   PreviewSubtitle='A glimpse into the future of hospitality'
 * />
 */

const Article = (prop: IArticle): JSX.Element | null => {
  const { Date: articleDate, PreviewSubtitle, PreviewTitle, PreviewImage, Tags, Url } = prop;
  const articleWrapper = useRef(null);
  useIsScrolledToSection(articleWrapper, '', true, 'top 100%');
  const { translate, data } = useData();
  const tagsListItemKeys = useUUID(Tags?.length);

  if (!articleDate || !PreviewTitle || !PreviewImage?.Url) {
    return null;
  }

  const fullDate = new Date(articleDate);
  const year = fullDate.getFullYear();
  const month = fullDate.toLocaleDateString(data?.language === 'ar' ? 'ar-AE' : 'en-US', { month: 'short' });
  const day = fullDate.getDate();
  const safeTags = Array.isArray(Tags) ? Tags : [];

  return (
    <section
      ref={articleWrapper}
      className={`${styles['news__section']} animate-section`}
    >
      {/* Flex 1st item - date */}
      <div className={`${styles['news__date']}`}>
        <div className={`${styles['_year']}`}>{year}</div>
        <div className={`${styles['_month']}`}>{month}</div>
        <div className={`${styles['_day']}`}>{day}</div>
      </div>

      {/* Flex 2nd item - text */}
      <div className={`${styles['news__content']}`}>
        <ul className={`${styles['news__keyword']}`}>
          {safeTags.map((item: string, index: number) => (
            <li key={tagsListItemKeys[index]}>{item}</li>
          ))}
        </ul>

        <div className={`${styles['news__title']}`}>
          <h6>{PreviewTitle}</h6>
        </div>

        {PreviewSubtitle && (
          <div className={`${styles['news__desc']}`}>
            <div>{PreviewSubtitle}</div>
          </div>
        )}

        <Link
          prefetch={false}
          href={trimUrl(Url ?? '#')}
          target='_self'
          className={`button-style-2 ${styles['news__link']} ${styles['envi__link']}`}
        >
          <span className='cta-text'>{translate('read-more')}</span>
          <div className='icon cta-icon'>
            <Icon.ArrowAnchorIcon />
          </div>
        </Link>
      </div>

      {/* Flex 3rd item - image */}
      <div className={`${styles['news__img']}`}>
        <div className={`${styles['news__img_holder']} reveal-vertical-animation _up`}>
          <Image
            width={450}
            height={344}
            className={`${styles['img']}`}
            sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
            url={PreviewImage.Url}
            alt={PreviewTitle ?? ''}
            priority
          />
        </div>
        <div className={`${styles['news__img_bg']} ${styles['fix']} reveal-vertical-animation _up`}></div>
      </div>
    </section>
  );
};

export default React.memo(Article, (prevProps, nextProps) => prevProps.PreviewTitle === nextProps.PreviewTitle);
