import { useData } from '@context/GraphQLDataContext';
import * as styles from './ArticleList.module.scss';
import Article, { IArticle } from '@components/ArticleList/Article/Article';
import useUUID from '@hooks/useUUID';

interface IArticleList {
  articles: IArticle[];
}

/**
 * ArticleList Component
 *
 * This component renders a list of articles, each detailing an upcoming event or announcement. It dynamically loads more articles when the user clicks the "Load More" button, enhancing user engagement and improving the page's performance by not loading all articles at once. The `IArticle` interface defines the structure for each article's data, ensuring consistency and type safety.
 *
 * @component
 * @param {IArticleList} props - The props for the ArticleList component.
 * @param {IArticle[]} props.articles - An array of article objects to be displayed.
 * @returns {JSX.Element} The rendered JSX element representing the list of articles.
 *
 * @example
 * Example of an article array:
 * const articles = [
 *   {
 *     Date: "2024-03-04T23:00:00Z",
 *     Title: "Regent and InterContinental hotels to open in the King Abdullah Financial District (KAFD)",
 *     Tags: ["Discover", "Live", "Thrive"],
 *     PreviewImage: { Url: "/globalassets/images/pages/media-center/ihg-regent.jpg" },
 *     PreviewTitle: "Regent and InterContinental hotels to open in KAFD",
 *     PreviewSubtitle: "Set to open in January 2027, these hotels will add to IHG's luxury portfolio in KAFD."
 *   },
 *   {
 *     Date: "2024-02-06T23:00:00Z",
 *     Title: "With a focus on the customer experience, KAFD Seals New Partnerships at PIF Private Sector Forum",
 *     Tags: ["Discover", "Live", "Thrive"],
 *     PreviewImage: { Url: "/globalassets/images/news/214.jpg" },
 *     PreviewTitle: "KAFD Seals New Partnerships at PIF Private Sector Forum",
 *     PreviewSubtitle: "Highlighting the focus on customer experience, new partnerships were announced."
 *   }
 * ];
 *
 * Usage:
 * <ArticleList articles={articles} />
 */

const ArticleList = (prop: IArticleList): JSX.Element => {
  const { articles } = prop;
  const { translate } = useData();
  const articleListItemKeys = useUUID(articles.length);
  return (
    <div className={styles.ArticleList}>
      <ul>
        {Array.isArray(articles) &&
          articles.map(({ Date, Tags, PreviewImage, PreviewTitle, PreviewSubtitle, Url }: IArticle, index: number) => {
            return (
              <li key={articleListItemKeys[index]}>
                {/* TODO - make it not hardcoded */}
                <Article
                  Date={Date}
                  Tags={Tags ? Tags : [translate('business'), translate('finance'), translate('real-estate')]}
                  PreviewImage={PreviewImage}
                  PreviewSubtitle={PreviewSubtitle}
                  PreviewTitle={PreviewTitle}
                  Url={Url}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ArticleList;
