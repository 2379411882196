import { IEvent } from '@interfaces/IEventsPage';

export interface FormState {
  pastEvents: IEvent[];
  currentEvents: IEvent[];
  futureEvents: IEvent[];
  isLoading: boolean;
  isError: { pastEvents: boolean; currentEvents: boolean; futureEvents: boolean };
  pastLoadedCount: number;
  currentLoadedCount: number;
  futureLoadedCount: number;
  allPastLoaded: boolean;
  allCurrentLoaded: boolean;
  allFutureLoaded: boolean;
}
type FormAction =
  | { type: 'SET_PAST_EVENTS'; payload: IEvent[] }
  | { type: 'SET_CURRENT_EVENTS'; payload: IEvent[] }
  | { type: 'SET_FUTURE_EVENTS'; payload: IEvent[] }
  | { type: 'SET_IS_ERROR'; payload: { pastEvents: boolean; currentEvents: boolean; futureEvents: boolean } }
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_PAST_LOADED_COUNT'; payload: number }
  | { type: 'SET_CURRENT_LOADED_COUNT'; payload: number }
  | { type: 'SET_FUTURE_LOADED_COUNT'; payload: number }
  | { type: 'SET_ALL_PAST_LOADED'; payload: boolean }
  | { type: 'SET_ALL_CURRENT_LOADED'; payload: boolean }
  | { type: 'SET_ALL_FUTURE_LOADED'; payload: boolean };

export const eventsReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_PAST_EVENTS':
      return { ...state, pastEvents: [...state.pastEvents, ...action.payload] };
    case 'SET_CURRENT_EVENTS':
      return { ...state, currentEvents: [...state.currentEvents, ...action.payload] };
    case 'SET_FUTURE_EVENTS':
      return { ...state, futureEvents: [...state.futureEvents, ...action.payload] };
    case 'SET_IS_ERROR':
      return { ...state, isError: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_PAST_LOADED_COUNT':
      return { ...state, pastLoadedCount: action.payload };
    case 'SET_CURRENT_LOADED_COUNT':
      return { ...state, currentLoadedCount: action.payload };
    case 'SET_FUTURE_LOADED_COUNT':
      return { ...state, futureLoadedCount: action.payload };
    case 'SET_ALL_PAST_LOADED':
      return { ...state, allPastLoaded: action.payload };
    case 'SET_ALL_CURRENT_LOADED':
      return { ...state, allCurrentLoaded: action.payload };
    case 'SET_ALL_FUTURE_LOADED':
      return { ...state, allFutureLoaded: action.payload };
    default:
      return state;
  }
};
