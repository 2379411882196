import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { IsAdminMode } from '@helpers/isAdminMode';

export const isElementInView = (el: HTMLElement) => ScrollTrigger.isInViewport(el);

const useIsScrolledToSection = (wrapperRef: any, classname?: string, dataAnimation = false, start = 'top 60%') => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (IsAdminMode()) {
      animateElement(wrapperRef, classname, dataAnimation);
    } else {
      ScrollTrigger.create({
        trigger: wrapperRef.current,
        start: start,
        onEnter: () => {
          animateElement(wrapperRef, classname, dataAnimation);
        },
      });
    }
  }, [classname, dataAnimation, start, wrapperRef]);
};

const animateElement = (wrapperRef: any, classname?: string, dataAnimation = false) => {
  if (!wrapperRef.current) return;
  classname && wrapperRef.current.classList.add(classname);
  if (dataAnimation) {
    wrapperRef.current.dataset.anim = 'on';
  }
};

export default useIsScrolledToSection;
