import { IsAdminMode } from '@helpers/isAdminMode';
import clsx from 'clsx';
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils';
import Image, { StaticImageData } from 'next/image';
import { CSSProperties, useState } from 'react';
import styles from './Image.module.scss';

interface ImageInRichText {
  width?: number;
  height?: number;
  url: string | StaticImageData;
  sizes?: string;
  alt?: string;
  layout?: string;
  fill?: boolean;
  loading?: 'eager' | 'lazy';
  className?: string | string[];
  unoptimized?: boolean;
  style?: CSSProperties;
  priority?: boolean;
  quality?: number;
  isSpinnerEnabled?: boolean;
}

const ImageInRichText = (prop: ImageInRichText): JSX.Element => {
  const {
    width,
    height,
    url,
    sizes = '(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw',
    alt = '',
    layout,
    fill,
    loading,
    className,
    unoptimized,
    style,
    priority,
    quality,
    isSpinnerEnabled,
  } = prop;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  let imageUrl = url;

  if (IsAdminMode()) {
    return (
      // Cannot use next/image in admin mode using CSR
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={typeof imageUrl === 'string' ? imageUrl : ''}
        style={{ width: width, height: height }}
        alt={alt}
        className={clsx(className)}
      />
    );
  } else {
    if (url && !isAbsoluteUrl(typeof url === 'string' ? url : '')) {
      imageUrl = url;
    }
    return (
      <>
        {isSpinnerEnabled && !isImageLoaded && <div className={`loader-spinner ${styles.loadSpinner}`}></div>}
        <Image
          fill={fill}
          width={width}
          height={height}
          sizes={sizes}
          src={imageUrl}
          alt={alt}
          layout={layout}
          loading={loading}
          className={clsx(className)}
          unoptimized={unoptimized}
          style={style}
          priority={priority}
          quality={quality}
          onLoad={() => {
            setIsImageLoaded(true);
          }}
        />
      </>
    );
  }
};

export default ImageInRichText;
