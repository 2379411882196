import { Number } from '@episerver/forms-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ValidationMessage, ElementCaption } from './shared';
import Icon from '@components/Icon/Icon';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom } from '@components/WhistleBlowerForm/utils/interfaces';

export interface NumberElementBlockProps {
  element: Number;
}

export const NumberElementBlock = (props: NumberElementBlockProps) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, elementRef, validatorClasses, extraAttr } = elementContext;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const onBlurHandler = (e: any) => {
    if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsFocused(false);
      handleBlur(e);
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    const target = e.target.ownerDocument.activeElement as HTMLInputElementCustom;
    target.value = '';
    handleChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  };

  return useMemo(
    () => (
      <ElementWrapper
        className={`FormTextbox FormTextbox--Number ${validatorClasses}`}
        validationResults={validationResults}
        isVisible={isVisible}
      >
        <div lang={element.locale}>
          <ElementCaption
            element={element}
            className={value ? 'filled textInputLabel' : 'textInputLabel'}
          />

          <input
            name={element.key}
            id={element.key}
            type="number"
            className="FormTextbox__Input"
            step="any"
            placeholder={element.properties.placeHolder}
            {...extraAttr}
            value={value}
            aria-describedby={`${element.key}_desc`}
            autoComplete={element.properties.autoComplete}
            onChange={handleChange}
            onBlur={onBlurHandler}
            onFocus={() => setIsFocused(true)}
            ref={elementRef}
          />

          <ValidationMessage
            element={element}
            validationResults={validationResults}
          />
          {isFocused ? (
            <Icon.CloseModalIcon
              onClick={handleClearInput}
              onMouseDown={handleClearInput}
              onTouchEnd={handleClearInput}
              className="IconClose IconCloseVisible"
            />
          ) : value && validationResults.result.valid && !isFocused ? (
            <Icon.Tick className="TickIcon" />
          ) : (
            <Icon.CloseModalIcon
              onClick={handleClearInput}
              onMouseDown={handleClearInput}
              onTouchEnd={handleClearInput}
              className="IconClose IconCloseVisible"
            />
          )}
        </div>
      </ElementWrapper>
    ),
    [isVisible, validationResults, value]
  );
};
