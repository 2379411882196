import Image from '@components/common/htmlTags/Image/Image';
import Link from 'next/link';
import * as styles from './SimpleCardsLink.module.scss';
import Icon from '@components/Icon/Icon';
import VideoPlayer from '@components/VideoPlayer/VideoPlayer';
import { trimUrl } from '@helpers/url.helper';
import { useState } from 'react';
import { DetermineLanguage } from '@helpers/determineLanguage';
import React from 'react';

interface ISimpleCardsLinkProps {
  title?: string | undefined;
  text?: string | undefined;
  imageSrc?: string | undefined;
  href?: string | undefined;
  videoSrc?: string | undefined;
  videoType?: string | undefined;
  accentColor?: 'red' | 'purple' | 'blue' | undefined;
}
/**
 * SimpleCardsLink Component
 *
 *A component that creates a placeholder if no props are received. Depending on the props, a card is created with a title, title and text, or just with text. If a video prop comes, an additional button is designed to launch a video on top of the card.
 *
 * @component
 * @param {Object} prop - The props for the SimpleCardsLink component.
 * @param {string} prop.title - The title on the card.
 * @param {string} prop.text - The text on the card will be placed under the title.
 * @param {string} prop.imageSrc - The path to an image file.
 * @param {string} prop.href - The href that will be used for a link.
 * @param {string} prop.videoSrc - The path to a video file.
 * @param {string} prop.videoType - Type of the video. By default is video/mp4.
 * @param {string} prop.accentColor - The accent color can be used to animate the title.
 * @returns {JSX.Element} The rendered JSX element representing the SimpleCard.
 *
 * @example
      <SimpleCardsLink
        title="THE WADI"
        text="additional information"
        imageSrc="/img/test/img_2_1120x880.jpeg"
        href="/en/about-board-of-directors"
        videoSrc="https://vjs.zencdn.net/v/oceans.mp4"
        accentColor="red"
      ></SimpleCardsLink>
 */

const SimpleCardsLink = (prop: ISimpleCardsLinkProps): JSX.Element => {
  const { title, text, imageSrc, href, videoSrc, videoType = 'video/mp4', accentColor } = prop;
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const accentClass = styles['accentColor__' + accentColor];

  const textContent = (
    <div className={styles['tabbed-carousel-item-content-col']}>
      <h4 className={accentClass}>{title}</h4>
      {text && <p>{text}</p>}
    </div>
  );
  const src = {
    imageSrc: imageSrc?.startsWith('/') ? imageSrc ?? '' : imageSrc,
    videoSrc: videoSrc?.startsWith('/') ? videoSrc ?? '' : videoSrc,
  };
  const language = DetermineLanguage();
  return (
    <div
      className={styles.SimpleCardsLink}
      onClick={(e) => {
        if (e.target instanceof HTMLDivElement) {
          if (e.target.className.startsWith('SimpleCardsLink_decor')) {
            setIsPlayingVideo(true);
            setTimeout(() => {
              setIsPlayingVideo(false);
            }, 200);
          }
        }
      }}
    >
      <div
        className={`${styles['lozad']} ${styles['tabbed-carousel-item-image']}`}
        data-alt={title}
        data-loaded='true'
      >
        {videoSrc && (
          <VideoPlayer
            videoSrc={src.videoSrc}
            videoType={videoType}
            accentColor={accentColor}
            playVideo={isPlayingVideo}
          />
        )}
        {imageSrc ? (
          <div className={styles.decor}>
            <Image
              loading={language === 'ar' ? 'eager' : 'lazy'}
              fill
              sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
              url={`${src.imageSrc}`}
              alt={text ?? ''}
              className={`${styles['img']}`}
            />
          </div>
        ) : (
          <div className={`${styles['img']}`}></div>
        )}
        {href ? (
          <Link
            prefetch={false}
            href={trimUrl(href)}
            target='_self'
            className={styles['tabbed-carousel-item-row']}
            tabIndex={0}
          >
            {textContent}
            <div className={styles['tabbed-carousel-item-content-col']}>
              <div className={styles['tabbed-carousel-item-content-arrow']}>
                <Icon.ArrowRightIcon />
              </div>
            </div>
          </Link>
        ) : (
          <div
            className={styles['tabbed-carousel-item-row']}
            tabIndex={0}
          >
            {textContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SimpleCardsLink);
