import InformationModal from './InformationModal/InformationModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { IWhistleBlowerModal } from '../utils/interfaces';

const WhistleBlowerModal: React.FC<IWhistleBlowerModal> = ({
  handleModal,
  title,
  trackingNumber = '',
  secretCode = '',
  modalType = 'information',
  onConfirm,
}) => {
  return (
    <>
      {modalType === 'information' ? (
        <InformationModal
          handleModal={handleModal}
          title={title}
          trackingNumber={trackingNumber}
          secretCode={secretCode}
        />
      ) : (
        <ConfirmationModal
          handleModal={handleModal}
          title={title}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default WhistleBlowerModal;
