import { useRef } from 'react';
import * as styles from './ResidentsCard.module.scss';
import Image from '@components/common/htmlTags/Image/Image';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import { renderImageOrFallback } from '@helpers/renderImageOrFallback';
import { trimUrl } from '@helpers/url.helper';

interface IResidentsCardProps {
  title: string;
  bigImageSrc: string;
  smallImageSrc: string;
  linkPosition: 'left' | 'right';
  linkHref: string;
  linkText: string;
}

/**
 * ResidentsCard Component
 *
 * A component that returns 2 images, and button "Learn more"
 *
 * @component
 * @param {Object} prop - The props for the ResidentsCard component.
 * @param {string} prop.title - The title.
 * @param {string} prop.bigImageSrc - The path to the big image.
 * @param {string} prop.smallImageSrc - The path to the small image.
 * @param {string} prop.linkPosition - The link with small image can be on the left or right side
 * @returns {JSX.Element} 2 images with button going to each residence.
 *
 * @example
 * <ResidentsCard
 *   title='BLISS EAST'
 *   bigImageSrc='/img/building.jpg'
 *   smallImageSrc='/img/building-sm.jpg'
 *   linkHref='/'
 *   linkPosition="right"
 * />
 */

const ResidentsCard: React.FC<IResidentsCardProps> = ({
  title,
  bigImageSrc,
  smallImageSrc,
  linkPosition = 'right',
  linkHref = '/',
  linkText = 'Learn more',
}) => {
  const residentCardWrapper = useRef<HTMLDivElement>(null);
  useIsScrolledToSection(residentCardWrapper, styles['animate-section'], false, 'top 100%');

  return (
    <div
      ref={residentCardWrapper}
      className='opacity-0'
    >
      <div className='flex flex-wrap w-full'>
        <div className='w-full'>
          <div className={styles['residence-listing']}>
            <div className={styles['residence-listing__list']}>
              <div
                className={`${styles['residence-listing__item']} ${linkPosition === 'left' ? styles['residence-listing__item-left'] : styles['residence-listing__item-right']}`}
              >
                <div className={styles['residence-listing__media']}>
                  <Image
                    fill
                    url={renderImageOrFallback(bigImageSrc)}
                    alt={`${title} Residence` ?? ''}
                    className={`${styles['img']}`}
                  />
                </div>

                <div className={styles['residence-listing__main']}>
                  <div className={styles['residence-listing__picture']}>
                    <Image
                      fill
                      url={renderImageOrFallback(smallImageSrc)}
                      alt={`${title} Thumbnail` ?? ''}
                      className={`${styles['img']}`}
                    />
                  </div>

                  <div className={styles['residence-listing__content']}>
                    <div className={styles['residence-listing__title']}>
                      <span className={styles['title__text']}>{title}</span>
                    </div>
                    <div className={styles['residence-listing__action']}>
                      <a
                        href={trimUrl(linkHref)}
                        className={styles['button-style-2']}
                        target='_self'
                      >
                        <span className={styles['cta-text']}>{linkText}</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          className={`${styles['cta-icon']}`}
                        >
                          <path
                            fill='#28334A'
                            fillRule='evenodd'
                            d='M15.735 7.313L12.034 3.28c-.341-.372-.91-.372-1.25 0-.341.371-.341.991 0 1.363l2.184 2.396H4.884c-.492 0-.884.427-.884.964 0 .536.392.963.884.963h8.084l-2.185 2.383c-.34.371-.34.991 0 1.363.177.192.404.289.632.289.227 0 .455-.096.631-.29l3.689-4.02c.164-.179.265-.427.265-.688 0-.262-.088-.51-.265-.689z'
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResidentsCard;
