import { Textarea } from '@episerver/forms-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ElementCaption, ValidationMessage } from './shared';
import Icon from '@components/Icon/Icon';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom } from '@components/WhistleBlowerForm/utils/interfaces';

export interface TextareaElementBlockProps {
  element: Textarea;
}

export const TextareaElementBlock = (props: TextareaElementBlockProps) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, extraAttr, validatorClasses, elementRef } = elementContext;
  const [isFocused, setIsFocused] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    const target = e.target.ownerDocument.activeElement as HTMLInputElementCustom;
    target.value = '';
    handleChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const onBlurHandler = (e: any) => {
    if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsFocused(false);
      handleBlur(e);
    }, 100);
  };

  return useMemo(
    () => (
      <ElementWrapper
        className={`FormTextbox FormTextbox--Textarea ${validatorClasses}`}
        validationResults={validationResults}
        isVisible={isVisible}
      >
        <ElementCaption
          element={element}
          className={value ? 'filled textInputLabel' : 'textInputLabel'}
        />

        <textarea
          name={element.key}
          id={element.key}
          className="FormTextbox__Input"
          placeholder={element.properties.placeHolder}
          {...extraAttr}
          aria-describedby={`${element.key}_desc`}
          value={value}
          autoComplete={element.properties.autoComplete}
          onChange={handleChange}
          onBlur={onBlurHandler}
          onFocus={() => setIsFocused(true)}
          ref={elementRef}
        ></textarea>

        <ValidationMessage
          element={element}
          validationResults={validationResults}
        />
        {isFocused ? (
          <Icon.CloseModalIcon
            onClick={handleClearInput}
            onMouseDown={handleClearInput}
            onTouchEnd={handleClearInput}
            className="IconClose IconCloseVisible"
          />
        ) : value && validationResults.result.valid && !isFocused ? (
          <Icon.Tick className="TickIcon" />
        ) : (
          <Icon.CloseModalIcon
            onClick={handleClearInput}
            onMouseDown={handleClearInput}
            onTouchEnd={handleClearInput}
            className="IconClose IconCloseVisible"
          />
        )}
      </ElementWrapper>
    ),
    [isVisible, validationResults, value]
  );
};
