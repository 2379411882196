import Hero from '@components/Hero/Hero';
import { IHeroBanner } from '@interfaces/ICommon';
import { IContentPageLayout } from './ContentPageLayout';
import { renderContentByType } from '@helpers/renderContentByType';
import FormWrapper from '@components/FormContainerBlock/FormWrapper/FormWrapper';
import { IContentType } from '@components/FormContainerBlock/FormWrapper/FormWrapper';
import useUUID from '@hooks/useUUID';

export interface IFormPageLayoutProps extends IContentPageLayout {
  BottomContent: {
    __typename: string;
    ContentLink: {
      Expanded: any;
    };
  }[];
  ContentLink: object;
  Form: {
    ContentLink: {
      Id: number;
      GuidValue: string;
      Expanded: IContentType;
    };
  };
  Editor: {
    Structure: string;
  };
  Classname: string;
  FormEndpoint: string;
}

const FormPageLayout = ({ data }: { data: IFormPageLayoutProps }) => {
  const heroBannerData: IHeroBanner = data?.HeroBanner.ContentLink.Expanded;
  const leftContent = data?.Editor.Structure;
  const BottomContent = data?.BottomContent;
  const bottomContentKeys = useUUID(BottomContent.length);

  return (
    <>
      <Hero heroBannerData={heroBannerData} />
      <section className={`kafd-main-sec kafd-container formPage ${data?.Classname}`}>
        <FormWrapper
          formData={data?.Form}
          formEndpoint={data?.FormEndpoint}
          leftContent={leftContent}
        />
        <div>
          {Array.isArray(BottomContent) &&
            BottomContent.map((item, index) => <div key={bottomContentKeys[index]}>{renderContentByType(item)}</div>)}
        </div>
      </section>
    </>
  );
};

export default FormPageLayout;
