export interface ISearchBarState {
  result: boolean;
  showInputText: boolean;
  showAutoComplete: boolean;
  isSubmitted: boolean;
  foundData: any[] | undefined;
  total: number | undefined;
}

type SearchBarAction =
  | { type: 'SET_RESULT'; payload: boolean }
  | { type: 'SET_SHOW_INPUT_TEXT'; payload: boolean }
  | { type: 'SET_SHOW_AUTOCOMPLETE'; payload: boolean }
  | { type: 'SET_IS_SUBMITTED'; payload: boolean }
  | { type: 'SET_FOUND_DATA'; payload: any[] | undefined }
  | { type: 'SET_TOTAL'; payload: number | undefined }
  | { type: 'RESET_STATE' };

export const SearchBarReducer = (state: ISearchBarState, action: SearchBarAction): ISearchBarState => {
  switch (action.type) {
    case 'RESET_STATE':
      return {
        result: false,
        showInputText: false,
        showAutoComplete: false,
        isSubmitted: false,
        foundData: [],
        total: 0,
      };
    case 'SET_RESULT':
      return { ...state, result: action.payload };
    case 'SET_SHOW_INPUT_TEXT':
      return { ...state, showInputText: action.payload };
    case 'SET_SHOW_AUTOCOMPLETE':
      return { ...state, showAutoComplete: action.payload };
    case 'SET_IS_SUBMITTED':
      return { ...state, isSubmitted: action.payload };
    case 'SET_FOUND_DATA':
      if (action.payload) return { ...state, foundData: [...action.payload] };
      else return state;
    case 'SET_TOTAL':
      return { ...state, total: action.payload };
    default:
      return state;
  }
};
