import useHandleResize from '@hooks/useHandleResize';
import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';
import React from 'react';

interface INavProps {
  headerMode: 'dark' | 'light';
}

const Header: React.FC<INavProps> = (headerProps) => {
  const isMobile = useHandleResize();
  return isMobile ? <NavMobile {...headerProps} /> : <NavDesktop {...headerProps} />;
};

export default React.memo(Header);
