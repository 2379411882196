import { useState, useRef, useEffect, useCallback } from 'react';
import { useData } from '@context/GraphQLDataContext';
import { useRouter } from 'next/router';
import { trimUrl } from '@helpers/url.helper';
import { useHandleScroll } from '@hooks/useHandleScroll';
import { useOutsideAlerter } from '@hooks/useOutsideAlerter';
import * as styles from './Header.module.scss';
import Icon from '@components/Icon/Icon';
import Portal from '@components/Portal/Portal';
import SearchBar from '@components/SearchBar/SearchBar';
import { DetermineLanguage } from '@helpers/determineLanguage';
import useUUID from '@hooks/useUUID';
import React from 'react';
import SubMenuList from './MobileSubMenuList';

interface INavProps {
  headerMode: 'dark' | 'light';
}

const NavMobile: React.FC<INavProps> = ({ headerMode }) => {
  const headerMenuData = useData()?.data?.headerMenu;
  const headerMenuDataKeys = useUUID(headerMenuData?.length);
  const currentLanguage = DetermineLanguage();
  const [query, setQuery] = useState('');
  const { translate } = useData();
  const router = useRouter();
  const handleLanguageChange = () => {
    const currentPath = router.asPath;
    const newLanguageCode = currentLanguage === 'ar' ? 'en' : 'ar';
    let newPath;
    if (/^\/(en|ar)(\/|$)/.test(currentPath)) {
      newPath = currentPath.replace(/^\/(en|ar)/, `/${newLanguageCode}`);
    } else {
      newPath = `/${newLanguageCode}${currentPath}`;
    }
    window.location.href = newPath;
  };

  // Header visibility
  const { navMode, headerVisible } = useHandleScroll({ initialNavMode: headerMode });

  const getHeaderModeClass = (headerMode: 'dark' | 'light' | '') => styles[`nav__${headerMode}`] || '';

  // Mobile menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen((current) => (current === false ? true : false));
  };

  const bodyElementRef = useRef(document.body);

  useEffect(() => {
    const bodyElement = bodyElementRef.current;

    bodyElement.classList.toggle('mobilemenu-open', isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'hidden' : '';

    return () => {
      bodyElement.classList.remove('mobilemenu-open');
    };
  }, [isMenuOpen]);

  // Mobile submenus visibility
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);

  const toggleSubmenu = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fancyUnderline?: string) => {
      e.preventDefault();
      if (fancyUnderline) {
        setActiveSubmenu(activeSubmenu === fancyUnderline ? null : fancyUnderline);
      }
    },
    [activeSubmenu]
  );

  const menuRef = useRef<HTMLDivElement>(null);
  const horizontalMenuRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter([menuRef, horizontalMenuRef], () => {
    setIsMenuOpen(false);
    document.body.classList.remove('mobilemenu-open');
  });

  const handleClose = (arg: boolean) => setIsOpenSearch(arg);

  return (
    <>
      <div
        className={`${styles['mheader']} js-nav-theme js-top-nav js-new-item ${headerVisible ? styles['header--showing'] : styles['header--hidden']} ${getHeaderModeClass(navMode)}`}
        ref={horizontalMenuRef}
        onClick={() => isMenuOpen && toggleMenu()}
      >
        <div className={`${styles['m-logo']}`}>
          <a
            href={`/${currentLanguage}`}
            className={`${styles['anchor']}`}
            target='_self'
          >
            <span className={`${styles['icon']}`}>
              <Icon.MKafdIcon className={`${styles['logoicon']} ${styles['replaced-svg']}`} />
            </span>
            <span className='h-full hidden'>{translate('logo')}</span>
          </a>
        </div>

        {/* Burger menu */}
        <div
          className={`${styles['burger-menu']} ${isMenuOpen ? styles['menu-on'] : ''}`}
          onClick={() => !isMenuOpen && toggleMenu()}
        >
          <div className={`${styles['burger']}`}></div>
        </div>

        {/* Search icon */}
        <a
          href='#'
          className={`${styles['d-search-icon']} js_search_trigger_btn`}
          target='_self'
          onClick={(event) => {
            event.preventDefault();
            setIsOpenSearch(true);
          }}
        >
          <span className={`${styles['icon']}`}>
            <Icon.IcoSearchIcon className={`${styles['svgicon']} ${styles['replaced-svg']}`} />
          </span>
          <span className='h-full hidden'>{translate('search')}</span>
        </a>
      </div>

      {isOpenSearch && (
        <Portal target='search'>
          <SearchBar
            close={handleClose}
            query={query}
            setQuery={setQuery}
          />
        </Portal>
      )}

      <div
        className={`${styles['m-menu-wrap']} 
                    ${isMenuOpen ? styles['expanded'] : ''} 
                    ${activeSubmenu ? styles['prevent-scroll'] : ''}`}
        ref={menuRef}
      >
        <div className={`${styles['mwrap-for-scroll']} js-top-nav js-new-item h-full`}>
          <div className={`${styles['lang-wrapper']}`}>
            <a
              onClick={handleLanguageChange}
              href='#'
              className={`${styles['d-lang']} js-site-lang`}
            >
              <span className={`${styles['lang-text']}`}>{currentLanguage === 'ar' ? 'English' : 'عربى'}</span>
            </a>
          </div>

          <hr className={`${styles['separator']}`} />

          {/* Dynamically generated menu items */}
          {headerMenuData &&
            Array.isArray(headerMenuData) &&
            headerMenuData.map((menuItem: any, menuIndex: number) => {
              const hasMenuBlock = menuItem.ContentLink.Expanded.__typename === 'MenuBlock';

              if (!hasMenuBlock) {
                return (
                  <div
                    key={headerMenuDataKeys[menuIndex]}
                    className={`${styles['m-secondarylinks-wrap']}`}
                  >
                    <ul className={`${styles['sub-links']} uppercase`}>
                      <li>
                        <a
                          href={trimUrl(menuItem.ContentLink.Expanded.Url || '#')}
                          target='_self'
                        >
                          {menuItem.ContentLink.Expanded.Label}
                        </a>
                      </li>
                    </ul>
                  </div>
                );
              } else {
                // Items with submenu items
                return (
                  <ul
                    key={headerMenuDataKeys[menuIndex]}
                    className={`${styles['m-primarylinks']} ${styles['style--2']}`}
                    role='navigation'
                    data-links-label={menuItem.children[0].Title ? menuItem.children[0].Title.toUpperCase() : ''}
                  >
                    {Array.isArray(menuItem.children[0].Items) && (
                      <SubMenuList
                        menuItem={menuItem}
                        activeSubmenu={activeSubmenu}
                        toggleSubmenu={toggleSubmenu}
                      />
                    )}
                  </ul>
                );
              }
            })}

          {/* CTA Button  */}
          <div className={`${styles['m-cta-link']}`}>
            <a
              href={`/${currentLanguage || 'en'}/leasing-inquiry`}
              className={`${styles['m-anchor']} uppercase`}
              target='_self'
            >
              {translate('leasing-and-enquire')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(NavMobile);
