import { useRef } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import RichTextComponent from '@components/RichText/RichText';
import * as styles from './CardTextAndImage.module.scss';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import { renderImageOrFallback } from '@helpers/renderImageOrFallback';

interface IEditor {
  __typename?: string;
  Structure: string;
}

interface IImage {
  __typename: string;
  Url: string;
}

export interface IContentLinkExpanded {
  Align?: string;
  ContentType?: string[];
  Editor?: IEditor;
  Image: IImage;
  Title?: string;
  __typename: string;
}

interface ITextAndImageProps {
  data: IContentLinkExpanded;
}

/**
 * The `TextAndImage` component displays a combination of text and an image, aligned side by side in a flexible layout.
 *
 * @component
 * @param {Object} props - The properties for configuring the TextAndImage component.
 * @param {Object} props.data - The data object containing the properties needed to render the component.
 *   @param {string} props.data.title - The title of the content to be displayed. This is a prominent heading that introduces the content.
 *   @param {string} props.data.text - The descriptive text that accompanies the title. This can be formatted as HTML or plain text,
 *   providing detailed information or context related to the title.
 *   @param {string} props.data.imageSrc - The source URL of the image to be displayed alongside the text. This URL can be absolute or
 *   relative, pointing to the image that visually complements the textual content.
 *   @param {('left'|'right')} props.data.textPosition - Determines the positioning of the text relative to the image; can be either
 *   'left' or 'right'. This allows for dynamic layout adjustments to best fit the content and design scheme.
 * @returns {JSX.Element} A JSX element that visually combines a title, descriptive text, and an image.
 *
 * <TextAndImage data={data} />
 */

const TextAndImage: React.FC<ITextAndImageProps> = ({ data }) => {
  const { Title, Editor, Image: imageObject, Align } = data;

  const cardTextImageWrapper = useRef<HTMLDivElement>(null);
  const textPosition = Align?.toLowerCase();

  const imageSrc =
    imageObject && imageObject.Url ? (imageObject.Url.startsWith('/') ? imageObject.Url : imageObject.Url) : '';

  useIsScrolledToSection(cardTextImageWrapper, 'visible-section', true, 'top 100%');

  return (
    <div
      ref={cardTextImageWrapper}
      className={`${styles.container}`}
    >
      <div className={`${styles['hori-img-comp']}`}>
        <div className='flex flex-wrap w-full'>
          <div className='w-full ml-auto'>
            <div className={`${styles['hori-img-comp__list']}`}>
              <div
                className={`${styles['hori-img-comp__item']} ${styles['img-' + textPosition]} ${styles['animate-section']}`}
              >
                <div
                  className={`${styles['hori-img-comp__col']} ${styles['hori-img-comp__col_main']} ${styles['block-reveal-animation']}`}
                >
                  <div className={`${styles['hori-img-comp__content']}`}>
                    <h2 className={`${styles['hori-img-comp__title']}`}>{Title}</h2>
                    <RichTextComponent
                      className='contenteditor'
                      data={Editor?.Structure ?? ''}
                    />
                  </div>
                </div>
                <div className={`${styles['hori-img-comp__col']} ${styles['hori-img-comp__col_media']}`}>
                  <Image
                    fill
                    sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
                    url={renderImageOrFallback(imageSrc)}
                    alt={Title ?? ''}
                    className={`${styles['img']}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAndImage;
