import { useEffect, useReducer, useMemo, useCallback } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './LazyGallery.module.scss';
import HtmlTags from '@components/common/htmlTags';
import GenericButton from '@components/GenericButton/GenericButton';
import GalleryModal from './GalleryModal/GalleryModal';
import ListItem from '@components/common/htmlTags/ListItem/ListItem';
import useHandleResize from '@hooks/useHandleResize';
import { useData } from '@context/GraphQLDataContext';
import { ILazyGalleryState, LazyGalleryReducer } from './LazyGallery.reducer';
import useUUID from '@hooks/useUUID';

interface IGalleryItemExpanded {
  Alt: string;
  Name: string;
  Url: string;
}

interface IGalleryItem {
  Expanded: IGalleryItemExpanded;
}

interface ILazyGallery {
  cards: IGalleryItem[];
  title?: string;
}

const LazyGallery = (prop: ILazyGallery): JSX.Element => {
  const { title, cards } = prop;
  const isMobile = useHandleResize();
  const initialState: ILazyGalleryState = {
    isModalOpen: false,
    isLoading: false,
    modal: { href: '' },
    imagesLoaded: Array(cards.length).fill(false),
    visibleCount: isMobile ? 4 : 6,
  };
  const cardsListItemKeys = useUUID(cards?.length);
  const [state, dispatch] = useReducer(LazyGalleryReducer, initialState);
  const { translate } = useData();

  useEffect(() => {
    dispatch({ type: 'SET_VISIBLE_COUNT', payload: isMobile ? 4 : 6 });
  }, [isMobile]);

  const loadMore = () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    dispatch({ type: 'SET_VISIBLE_COUNT', payload: state.visibleCount + 6 });
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };

  const handleModal = useCallback(
    (index?: number) => {
      if (typeof index === 'number') {
        const fullUrl = cards[index].Expanded.Url;
        dispatch({ type: 'SET_MODAL', payload: { href: fullUrl } });
      }
      dispatch({ type: 'SET_IS_MODAL_OPEN', payload: !state.isModalOpen });
    },
    [state.isModalOpen, cards]
  );

  const imageList = useMemo<JSX.Element>(() => {
    return (
      <HtmlTags.UnorderedList className={`${styles['kafd-gallery-wrapper']} flex flex-wrap -mx-4`}>
        {Array.isArray(cards) &&
          cards.slice(0, state.visibleCount).map((galleryItem, index) => {
            return (
              <ListItem
                key={cardsListItemKeys[index]}
                className={`${styles['kafd-gallery__item']}`}
                onClick={() => handleModal(index)}
              >
                <div className={styles['kafd-gallery__img']}>
                  <Image
                    fill
                    sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
                    url={galleryItem?.Expanded?.Url}
                    alt={galleryItem?.Expanded?.Name ?? ''}
                    isSpinnerEnabled
                  />
                  <div className={`${styles['gallery__overlay']} lozad`}>
                    <div className={styles['gallery__item-title']}>{galleryItem?.Expanded?.Alt}</div>
                  </div>
                </div>
              </ListItem>
            );
          })}
      </HtmlTags.UnorderedList>
    );
  }, [cards, handleModal, state.visibleCount, cardsListItemKeys]);

  return (
    <section className={`relative ${styles.LazyGallery}`}>
      <div className=''>
        <div className={`${styles['kafd-image-gallery']} ${styles['image-gallery-events']} brand-live mt-16`}>
          <div className='flex flex-wrap component-pb'>
            <div className='flex flex-grow flex-col justify-center w-full xl:w-11/12 relative'>
              <div className={styles['kafd-title-group']}>
                <HtmlTags.Heading2 className={`${styles['kafd-gallery-title']} ${styles['block-reveal-animation']}`}>
                  {title}
                </HtmlTags.Heading2>
              </div>
              {imageList}
              {state.visibleCount < cards.length &&
                (state.isLoading ? (
                  <div className='loader-spinner'></div>
                ) : (
                  <div className={`${styles['btn-lm-ig']} load_more_btn`}>
                    <GenericButton
                      className='bwcta__btn__btn'
                      onClick={loadMore}
                      innerSpanClass={`${styles['lazyGalleryLoadMoreButton']}`}
                    >
                      {translate('load-more-images')}
                    </GenericButton>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {state.isModalOpen && (
        <div id='modal'>
          <GalleryModal
            onClose={handleModal}
            href={state.modal.href}
          />
        </div>
      )}
    </section>
  );
};

export default LazyGallery;
