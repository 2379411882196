import { FC } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './CarouselLiveCard.module.scss';
import Link from 'next/link';
import Icon from '@components/Icon/Icon';
import { trimUrl } from '@helpers/url.helper';

export type ICarouselLiveCardProps = {
  title: string;
  text: string;
  image: string;
  href: string;
  linkText: string;
};

const CarouselLiveCard: FC<ICarouselLiveCardProps> = ({ image, title, text, linkText, href }) => {
  return (
    <div className={styles.imageCard}>
      <div className={styles.imageInnerBlock}>
        <Image
          fill
          alt='image'
          url={image}
          sizes='100vw'
        />
        <div className={styles.decor}> </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <h4>{title}</h4>
        </div>
        <div className={styles.contentDesc}>
          <p>{text}</p>
        </div>
        <div className={styles.contentLink}>
          {href && (
            <Link
              prefetch={false}
              href={trimUrl(href)}
            >
              <span>{linkText}</span>
              <Icon.ArrowAnchorIcon />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselLiveCard;
