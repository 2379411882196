import HtmlTags from '@components/common/htmlTags';
import * as styles from './PersonCardsList.module.scss';
import PersonCard from './PersonCard/PersonCard';
import useUUID from '@hooks/useUUID';

interface IMedia {
  __typename: string;
  Url?: string | null;
  Expanded?: {
    Url: string | null;
  };
}

interface ICardBlock {
  __typename: string;
  Name: string;
  Title: string;
  Text: string;
  LinkText: string;
  LinkUrl: string | null;
  Subtitle: string;
  Url: string | null;
  Image: IMedia;
  Video: IMedia;
}

interface IContentModelReferenceSearch {
  __typename: string;
  Expanded: ICardBlock;
}

interface IContentAreaItemModelSearch {
  __typename: string;
  ContentLink: IContentModelReferenceSearch;
}

interface IPersonCardsListProps {
  items: IContentAreaItemModelSearch[];
}

const PersonCardsList: React.FC<IPersonCardsListProps> = ({ items }) => {
  const cardsListItemKeys = useUUID(items?.length);
  return (
    <div className={styles.PersonCardsList}>
      <HtmlTags.UnorderedList className={styles['kafd-card-column']}>
        {Array.isArray(items) &&
          items.map((item, index) => {
            const { Image, Title, Text } = item.ContentLink.Expanded;
            return (
              <HtmlTags.ListItem
                key={cardsListItemKeys[index]}
                className={styles.item}
              >
                <PersonCard
                  memberName={Title}
                  memberTitle={Text}
                  imageSrc={Image.Url ? Image.Url : Image.Expanded && Image.Expanded.Url ? Image.Expanded.Url : ''}
                />
              </HtmlTags.ListItem>
            );
          })}
      </HtmlTags.UnorderedList>
    </div>
  );
};

export default PersonCardsList;
