import useUUID from '@hooks/useUUID';
import React from 'react';
import * as styles from './Header.module.scss';
import { normalizePath } from '@helpers/normalizePath';

const DesktopSubSubMenuList: React.FC<any> = ({ subMenuItem }) => {
  const subSubMenuItems = subMenuItem.ContentLink.Expanded.Items;
  const subSubMenuItemsKeys = useUUID(subSubMenuItems.length);

  return subSubMenuItems.map((subSubMenuItem: any, subSubMenuIndex: number) => (
    <li key={subSubMenuItemsKeys[subSubMenuIndex]}>
      <a href={normalizePath(subSubMenuItem.ContentLink.Expanded.Url)}>
        <div className={`${styles['secondary-info']}`}>
          <h4 className={`${styles['secondary-title']}`}>{subSubMenuItem.ContentLink.Expanded.Label}</h4>
        </div>
      </a>
    </li>
  ));
};

export default React.memo(DesktopSubSubMenuList);
