import { trimUrl } from '@helpers/url.helper';
import useUUID from '@hooks/useUUID';
import React from 'react';

const MobileSubSubMenuList: React.FC<any> = ({ subMenuItem }) => {
  const subSubMenuItems = subMenuItem.ContentLink.Expanded.Items;
  const subSubMenuItemsKeys = useUUID(subSubMenuItems.length);

  return subSubMenuItems.map((subSubMenuItem: any, subSubMenuIndex: number) => (
    <li key={subSubMenuItemsKeys[subSubMenuIndex]}>
      <a
        href={trimUrl(subSubMenuItem.ContentLink?.Expanded?.Url || '#')}
        target='_self'
      >
        {subSubMenuItem.ContentLink.Expanded.Label}
      </a>
    </li>
  ));
};

export default React.memo(MobileSubSubMenuList);
