import { useRef } from 'react';
import * as styles from './FileList.module.scss';
import FileItem from './FileItem/FileItem';
import { IFileListData, IFileItem } from '@interfaces/IFileListData';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';

const FileList: React.FC<{ fileListData: IFileListData }> = ({ fileListData }): JSX.Element | null => {
  const pressSection = useRef(null);
  useIsScrolledToSection(pressSection, 'reveal-section-with-title');

  if (fileListData === null) {
    return null;
  }

  return (
    <div
      ref={pressSection}
      className='opacity-0'
    >
      <div className={`${styles['kafd-press-kit-section__header']}`}>
        <span className={`${styles['title-text']}`}>{fileListData.Title}</span>
      </div>

      <div className='flex flex-wrap w-full component-pb'>
        <ul className={`move-bottom-to-top ${styles['press-kit_boxes']}`}>
          {Array.isArray(fileListData.Files) &&
            fileListData.Files.map((fileItem: IFileItem) => {
              const { Title, Description, RelativePath, ContentLink } = fileItem.Expanded;

              return (
                <FileItem
                  key={ContentLink.Id}
                  Title={Title}
                  Description={Description}
                  RelativePath={RelativePath}
                />
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default FileList;
