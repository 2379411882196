import Icon from '@components/Icon/Icon';
import * as styles from '../Footer.module.scss';
import { IContentAreaItemModel, IMenuEntryBlock } from '@interfaces/IConfigPage';
import { useData } from '@context/GraphQLDataContext';

import { trimUrl } from '@helpers/url.helper';
import Image from 'next/image';

const FooterBottomMenu: React.FC<{ bottomMenu: IContentAreaItemModel[] }> = ({ bottomMenu }) => {
  const { data } = useData();

  return (
    <div className={`${styles['last-bottom']} ${styles['footer-bottom-wrapper']} `}>
      <div className={`${styles['pif-wrap']} w-auto ${styles['text-reveal-animation']}`}>
        <div className={`${styles['pif__logo2']}`}>
          <Image
            width={150}
            height={150}
            alt='KAFD LOGO'
            src={'/img/logo.png'}
          />
        </div>
      </div>

      <ul className={`${styles['footerlinks']} ${styles['text-reveal-animation']} lvl-1 `}>
        {Array.isArray(bottomMenu) &&
          bottomMenu.map((menuItem, index) => {
            if (menuItem.ContentLink.Expanded.__typename === 'MenuEntryBlock') {
              // type assertion of `IMenuEntryBlock` after check
              const menuEntryBlock = menuItem.ContentLink.Expanded as IMenuEntryBlock;
              return (
                <li key={index}>
                  <a
                    href={trimUrl(menuEntryBlock.Url || '#')}
                    target='_self'
                  >
                    {menuEntryBlock.Label}
                  </a>
                </li>
              );
            }
            return null;
          })}
      </ul>

      <div className={`${styles['copyrights']} text-defaultsolidwhite ${styles['text-reveal-animation']}`}>
        <div className={`${styles['copyrights']} text-defaultsolidwhite ${styles['text-reveal-animation']}`}>
          <span className={`${styles['anchor']} flex items-center gap-2`}>
            {' '}
            <span className={`${styles['copyright-txt']} ${styles['label']} mr-2`}>© {new Date().getFullYear()}</span>
            <a
              href={data?.language ? `/${data?.language}` : '/en'}
              className={`${styles['icon']} ${styles['anchor']}`}
            >
              <Icon.MKafdIcon
                viewBox='0 0 99 23'
                className={`${styles['icon']} ${styles['svgicon']} ${styles['m-logo']} replaced-svg`}
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterBottomMenu;
