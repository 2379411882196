import { useEffect, useId, useState } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './VideoBlock.module.scss';
import Icon from '@components/Icon/Icon';
import { queryGraphQl } from '@lib/apolloClient';
import VideoBlockQuery from '@graphql/queries/VideoBlockQuery.graphql';

interface IVideoBlockProps {
  Classname?: string;
  Video?: {
    Url: string;
  };
  PreviewImage?: {
    Url: string;
  };
}

/**
 * `VideoBlock` is a React component for displaying a video player with a preview image and custom play button.
 * It fetches video and preview image data using a GraphQL query, and toggles between showing the video and the preview image when the play button is clicked.
 *
 * @param {string} contentGuid - A unique identifier for the content, used to fetch video data.
 * @returns {JSX.Element} The rendered `VideoBlock` component.
 * @example
 * <VideoBlock contentGuid="unique-content-guid" />
 */

const VideoBlock = ({ contentGuid, accentColor }: any): JSX.Element => {
  const [videoData, setVideoData] = useState<IVideoBlockProps | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await queryGraphQl<any>(VideoBlockQuery, { id: contentGuid });
      setVideoData(res?.VideoBlock.items[0]);
    };
    fetchData().catch(() => setVideoData(null));
  }, [contentGuid]);

  const [hiddenClass, setHiddenClass] = useState('');
  const [topClass, setTopClass] = useState('');

  const accentBgColor = styles['accentBgColor__' + accentColor];

  const videoId = useId();

  const handleClick = () => {
    setHiddenClass((prev) => {
      if (prev === styles.hidden) {
        const video = document.getElementById(videoId) as HTMLMediaElement;
        video?.pause();
        setTopClass('');
        return '';
      } else {
        const video = document.getElementById(videoId) as HTMLMediaElement;
        video?.play().catch(() => video?.pause());
        setTopClass(styles.top);
        return styles.hidden;
      }
    });
  };
  if (videoData)
    return (
      <div className={styles.Wrapper}>
        <div className={styles.VideoPlayer}>
          <div className={`${styles.video} ${topClass}`}>
            <video
              id={videoId}
              width='100%'
              height='100%'
              controls
              muted
            >
              <source
                src={videoData?.Video?.Url}
                type={'video/mp4'}
              ></source>
              <p className='vjs-no-js'>
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a
                  href='https://videojs.com/html5-video-support/'
                  target='_blank'
                >
                  supports HTML5 video
                </a>
              </p>
            </video>
            <div
              className={`${styles.closeIcon} cursor-pointer flex items-center justify-center absolute`}
              onClick={handleClick}
            >
              <Icon.CloseModalIcon />
            </div>
          </div>
          <div className={`${styles.playerCoverWrapper} ${hiddenClass}`}>
            {videoData?.PreviewImage && (
              <Image
                fill
                sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
                url={videoData.PreviewImage.Url}
                alt=''
              />
            )}
            <div
              className={`${styles.playerButton} ${accentBgColor}  cursor-pointer flex items-center justify-center absolute`}
              onClick={handleClick}
            >
              <Icon.PlayVideoIcon />
            </div>
          </div>
        </div>
        <div className={styles.decoration}>
          <Icon.MotifCircle />
        </div>
      </div>
    );
  return (
    <div>
      <p>no video found text</p>
    </div>
  );
};

export default VideoBlock;
