import React, { useState, useEffect, useRef } from 'react';
import { Selection } from '@episerver/forms-sdk';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ElementCaption, ValidationMessage } from './shared';
import Icon from '@components/Icon/Icon';
import { useRouter } from 'next/router';
import { DispatchFunctions } from '@episerver/forms-react/context/dispatchFunctions';

interface SelectionElementBlockProps {
  element: Selection;
}

export const SelectionElementBlock = (props: SelectionElementBlockProps) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, extraAttr, validatorClasses, elementRef } = elementContext;
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedCaption, setSelectedCaption] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const dispatchFuncs = new DispatchFunctions();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, ref]);

  const handleOptionClick = (item: any) => {
    if (item.value && item.value.indexOf('<link>') > -1) {
      router.push(item.value.split('<link>')[1]);
    }
    setSelectedValue(item.value);
    setSelectedCaption(item.caption);
    setIsOpen(false);
    handleChange({ target: { value: item.value } });
    dispatchFuncs.updateValidation(element.key, {
      valid: true,
      message: '',
    });
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <ElementWrapper
      className={`FormSelection ${validatorClasses}`}
      validationResults={validationResults}
      isVisible={isVisible}
    >
      <ElementCaption
        element={element}
        className={selectedValue ? 'filled' : ''}
        onClick={toggleDropdown}
      ></ElementCaption>
      <div
        ref={ref}
        className={`select-display ${isOpen ? 'open' : ''}`}
        onClick={toggleDropdown}
        tabIndex={0}
      >
        <span className="selectListSelectedValue">{selectedCaption}</span>
        <ul className={`selectList ${isOpen ? 'open' : ''}`}>
          {element.properties.items?.map((item, index) => (
            <li
              className={`selectOption ${selectedValue === item.value ? 'selected' : ''}`}
              key={index}
              onClick={() => handleOptionClick(item)}
            >
              {item.caption}
            </li>
          ))}
        </ul>
        <Icon.ChevronDownIcon className={`iconChevron iconChevronActive`} />
      </div>
      <div
        className="custom-select-wrapper"
        tabIndex={0}
      ></div>
      <select
        name={element.key}
        id={element.key}
        multiple={element.properties.allowMultiSelect}
        aria-describedby={element.displayName + '_desc'}
        {...extraAttr}
        autoComplete={element.properties.autoComplete}
        value={selectedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={elementRef}
        className="hidden"
      >
        {element.properties.items?.map((feed) => (
          <option
            key={feed.value}
            value={feed.value}
            defaultChecked={feed.checked}
          >
            {feed.caption}
          </option>
        ))}
      </select>
      <ValidationMessage
        element={element}
        validationResults={validationResults}
      ></ValidationMessage>
    </ElementWrapper>
  );
};
