import React, { useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useElement } from '../../hooks/useElement';
import { DispatchFunctions } from '@episerver/forms-react/context/dispatchFunctions';

export const RecaptchaElementBlock = (props: any) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { elementRef, extraAttr } = elementContext;

  const dispatchFuncs = new DispatchFunctions();
  const [recaptchaError, setRecaptchaError] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    handleChange({ target: { name: element.key, value: props.element.properties.siteKey } });
    dispatchFuncs.updateRecaptchaRef(recaptchaRef, element.key);
  }, [props.element.properties.siteKey]);

  const onRecaptchaSuccess = (initialToken: string | null) => {
    setRecaptchaError(null); // Clear any previous errors
  };

  const onRecaptchaError = () => {
    setRecaptchaError('ReCAPTCHA execution failed. Please check your site key.');
  };

  const onRecaptchaExpired = () => {
    setRecaptchaError('ReCAPTCHA token expired. Please try again.');
  };

  return (
    <div className="fixed z-10 bottom-5 right-5">
      <input
        hidden
        name={element.key}
        id={element.key}
        type="text"
        className="FormTextbox__Input"
        aria-describedby={`${element.key}_desc`}
        placeholder={element.properties.placeHolder}
        value={props.element.properties.siteKey}
        autoComplete={element.properties.autoComplete}
        {...extraAttr}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={elementRef}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={props.element.properties.siteKey}
        size="invisible"
        onChange={onRecaptchaSuccess}
        onErrored={onRecaptchaError}
        onExpired={onRecaptchaExpired}
      />
      {recaptchaError && <div className="error">{recaptchaError}</div>}
    </div>
  );
};
