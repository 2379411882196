import React, { useCallback, useEffect, useState } from 'react';
import { DetermineLanguage } from '@helpers/determineLanguage';
import {
  IReportTypeWithValue,
  HTMLInputElementCustom,
  IInputProps,
  HTMLTextAreaElementCustom,
} from '../utils/interfaces';
import { validateAllReportTypes, getWhistleblowerData, getTranslation } from '../utils/helpers';
import Icon from '@components/Icon/Icon';
import { TranslationKeys } from '../translations/translationKeys';

const ENDPOINT_REPORT_TYPES = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/ReportTypes`;

const ReportTypes: React.FC<IInputProps> = ({
  name,
  onChange,
  required = false,
  validationMessage,
  shouldValidate,
  setHasFetchError,
  counterLoaderHelper,
}) => {
  const [reportTypes, setReportTypes] = useState<IReportTypeWithValue[]>([]);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [othersTextArea, setOthersTextArea] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const language = DetermineLanguage();

  const validateReports = useCallback(() => {
    return validateAllReportTypes(reportTypes);
  }, [reportTypes]);
  const isValid = validateReports();

  const fetchReportTypes = useCallback(async () => {
    if (counterLoaderHelper) {
      counterLoaderHelper(1);
    }
    try {
      await getWhistleblowerData(
        ENDPOINT_REPORT_TYPES,
        setReportTypes,
        (itemReport) => ({ ...itemReport, value: false, otherValue: itemReport.other ? '' : undefined }),
        setHasFetchError
      );
    } catch (error) {
      if (setHasFetchError) setHasFetchError(true);
    } finally {
      if (counterLoaderHelper) counterLoaderHelper(-1);
    }
  }, [counterLoaderHelper, setHasFetchError]);

  useEffect(() => {
    void fetchReportTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = useCallback(() => {
    setIsTouched(true);
    const target = {
      name,
      value: reportTypes,
      isValid,
    } as unknown as HTMLInputElementCustom;
    onChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  }, [name, reportTypes, isValid, onChange]);

  useEffect(() => {
    const target = { name, value: reportTypes, isValid } as unknown as HTMLInputElementCustom;
    onChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  }, [reportTypes, validateReports, name, onChange, isValid]);

  useEffect(() => {
    if (shouldValidate) {
      handleBlur();
    }
  }, [handleBlur, shouldValidate]);

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setReportTypes((prevReportTypes: IReportTypeWithValue[]) =>
      prevReportTypes.map((item) => (item.Id === e.target.id ? { ...item, value: !item.value } : item))
    );
  }, []);

  const handleTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentTextAreaValue = e.target.value;
    setOthersTextArea(currentTextAreaValue);

    setReportTypes((prevReportTypes: IReportTypeWithValue[]) =>
      prevReportTypes.map((item) => {
        return item.Id === e.target.id ? { ...item, otherValue: currentTextAreaValue } : item;
      })
    );
  }, []);

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    const target = e.target.ownerDocument.activeElement as HTMLInputElementCustom;
    target.value = '';
    handleTextAreaChange({ target } as unknown as React.ChangeEvent<HTMLTextAreaElement>);
  };

  return (
    <div className='FormChoice'>
      <h5 className='FormChoice__Title'>
        <span>{getTranslation(TranslationKeys.PLACEHOLDER_REPORT_TYPES)}</span>
      </h5>
      {reportTypes?.map((item) => {
        return (
          <React.Fragment key={item.Id}>
            <label
              className='FormChoice__Item'
              htmlFor={item.Id}
            >
              <input
                type='checkbox'
                id={item.Id}
                name={item.Id}
                className='FormChoice__Input FormChoice__Input--Checkbox'
                checked={item.value}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
              />
              <span className='FormChoice__Checkmark'></span>
              <span className='FormChoice__Checkmark-Text'>{language === 'ar' ? item.arabicLabel : item.label}</span>
            </label>

            {item.other === true && item.value && (
              <div className={`Form__Element FormTextbox ValidationRequired FormTextbox--Textarea`}>
                <textarea
                  className='FormTextbox__Input Whistleblower Whistleblower__Others'
                  id={item.Id}
                  onChange={handleTextAreaChange}
                  value={othersTextArea}
                  onBlur={() => setIsFocused(false)}
                  onFocus={() => setIsFocused(true)}
                />
                {isFocused ? (
                  <Icon.CloseModalIcon
                    onMouseDown={handleClearInput}
                    className='IconClose IconCloseVisible'
                  />
                ) : othersTextArea && isValid && !isFocused ? (
                  <Icon.Tick className='TickIcon' />
                ) : (
                  <></>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}

      {!isValid && isTouched && required && <span style={{ color: 'red' }}>{validationMessage}</span>}
    </div>
  );
};

export default ReportTypes;
