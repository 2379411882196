import { Choice, equals, isNullOrEmpty } from '@episerver/forms-sdk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ValidationMessage } from './shared';
import Icon from '@components/Icon/Icon';
import { DispatchFunctions } from '@episerver/forms-react/context/dispatchFunctions';

export interface ChoiceElementBlockProps {
  element: Choice;
}

interface ChoiceItem {
  value: string;
  caption: string;
}

export const ChoiceElementBlock = (props: ChoiceElementBlockProps) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, validatorClasses, extraAttr } = elementContext;
  const dispatcher = useRef(new DispatchFunctions());

  // State and ref for dropdown management
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, ref]);

  useEffect(() => {
    const captionElement = ref.current?.previousElementSibling;
    if (captionElement) {
      if (isOpen) {
        captionElement.classList.add('open');
      } else {
        captionElement.classList.remove('open');
      }
    }
  }, [isOpen]);

  const handleOptionClick = useCallback(
    (item: ChoiceItem) => {
      let newValue: string[] = value ? value.split(',') : [];
      if (newValue.includes(item.value)) {
        newValue = newValue.filter((v: string) => v !== item.value);
      } else {
        newValue.push(item.value);
      }
      handleChange({ target: { value: newValue.join(',') } });
      if (newValue.length > 0) {
        dispatcher.current.updateValidation(element.key, {
          valid: true,
          message: '',
        });
      } else {
        dispatcher.current.updateValidation(element.key, {
          valid: false,
          message: 'Please select at least one option',
        });
      }
    },
    [element.key, handleChange, value]
  );

  const toggleDropdown = useCallback(() => {
    return setIsOpen(!isOpen);
  }, [isOpen]);

  const handleListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const generatePreview = useMemo(
    () =>
      (selectedValues: string): string => {
        const values = selectedValues.split(',').filter(Boolean);
        if (values.length === 0) return '';

        values.sort();
        const baseValue = values[0];
        const additionalCount = values.length - 1;

        const baseItem = element.properties.items.find((item) => item.value === baseValue);
        const basePreviewText = baseItem ? baseItem.caption : baseValue;

        return additionalCount > 0 ? `${basePreviewText} + ${additionalCount}` : basePreviewText;
      },
    [element.properties.items]
  );

  return useMemo(
    () => (
      <ElementWrapper
        className={`FormChoice ${validatorClasses}`}
        validationResults={validationResults}
        isVisible={isVisible}
        extraAttr={extraAttr}
      >
        {!isNullOrEmpty(element.properties.label) && (
          <label
            className={`Form__Element__Caption ${isOpen ? 'open' : ''}`}
            htmlFor={element.key}
          >
            {element.properties.label}
          </label>
        )}

        {element.properties.allowMultiSelect ? (
          // DROPDOWN WITH CHECKBOXES
          <div
            className='custom-select-wrapper'
            tabIndex={0}
          >
            <div
              ref={ref}
              className={`select-display ${isOpen ? 'open' : ''}`}
              onClick={toggleDropdown}
              tabIndex={0}
            >
              <span className='selectListSelectedValue choiceValuePreview'>{generatePreview(value)}</span>

              <ul
                className={`selectList ${isOpen ? 'open' : ''}`}
                onClick={handleListClick}
              >
                {element.properties.items?.map((item, index) => {
                  let isChecked = value && value.split(',').includes(item.value);
                  return (
                    <li key={index}>
                      <label className='custom-checkbox'>
                        <span>{item.caption}</span>
                        <input
                          type='checkbox'
                          id={`dropdown_${index}`}
                          checked={isChecked}
                          onChange={() => handleOptionClick(item)}
                          name='FormModel.LookingFor'
                          value={item.value}
                        />
                        <span className='checkmark'></span>
                      </label>
                    </li>
                  );
                })}
              </ul>

              <Icon.ChevronDownIcon className={`iconChevron iconChevronActive`} />
            </div>
            <select
              name={element.key}
              id={element.key}
              className='hidden'
              aria-describedby={`${element.key}_desc`}
            >
              {element.properties.items?.map((item, index) => (
                <option
                  key={index}
                  value={item.value}
                >
                  {item.caption}
                </option>
              ))}
            </select>
          </div>
        ) : (
          // RADIO BUTTONS
          element.properties.items?.map((item, index) => {
            let isChecked =
              !isNullOrEmpty(item.value) &&
              !isNullOrEmpty(value) &&
              value.split(',').some((s: string) => equals(s, item.value));
            let choiceId = `${element.key}_${index}`;

            return (
              <React.Fragment key={`${item.value}_${index}`}>
                <div>
                  <input
                    type='radio'
                    id={choiceId}
                    name={element.key}
                    value={item.value}
                    className='FormChoice__Input FormChoice__Input--Radio'
                    checked={isChecked}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    htmlFor={choiceId}
                    className='FormChoice__Label'
                  >
                    {item.caption}
                  </label>
                </div>
              </React.Fragment>
            );
          })
        )}

        <ValidationMessage
          element={element}
          validationResults={validationResults}
        />
      </ElementWrapper>
    ),
    [
      validatorClasses,
      validationResults,
      isVisible,
      extraAttr,
      element,
      isOpen,
      toggleDropdown,
      generatePreview,
      value,
      handleOptionClick,
      handleChange,
      handleBlur,
    ]
  );
};
