import React, { useEffect, useRef } from 'react';
import InfoWindowContent from './GoogleMapInfoWindow';
import ReactDOMServer from 'react-dom/server';
import * as styles from './GoogleMap.module.scss';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import RichTextComponent from '@components/RichText/RichText';

declare global {
  interface Window {
    initMap: () => void;
  }
}

interface IGoogleMapProps {
  config: string;
  content: string;
}

const apiKey: string = process.env.NEXT_PUBLIC_APIKEY || '';

const GoogleMap: React.FC<IGoogleMapProps> = (prop) => {
  const contentText = prop.content;
  const { lat, lng, zoom, link, markText } = (prop?.config && JSON.parse(prop.config)) || {};
  const mapRef = useRef<HTMLDivElement>(null);
  const googleMapWrapper = useRef<HTMLDivElement>(null);
  useIsScrolledToSection(googleMapWrapper, '', true);

  useEffect(() => {
    if (!apiKey) {
      console.error('Google Maps API key is missing.');
      return;
    }

    const loadGoogleMapScript = (callback: () => void) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      window.initMap = callback;
    };

    const initializeMap = () => {
      if (mapRef.current && !isNaN(lat) && !isNaN(lng)) {
        const map = new google.maps.Map(mapRef.current, {
          center: { lat, lng },
          zoom,
          disableDefaultUI: true,
          draggableCursor: 'default',
          gestureHandling: 'cooperative',
          mapTypeControl: false,
          scaleControl: false,
          zoomControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          clickableIcons: false,
          styles: [
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#e9e9e9',
                },
                {
                  lightness: 17,
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5',
                },
                {
                  lightness: 20,
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 17,
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 29,
                },
                {
                  weight: 0.2,
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 18,
                },
              ],
            },
            {
              featureType: 'road.local',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#ffffff',
                },
                {
                  lightness: 16,
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5',
                },
                {
                  lightness: 21,
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#dedede',
                },
                {
                  lightness: 21,
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#ffffff',
                },
                {
                  lightness: 16,
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  saturation: 36,
                },
                {
                  color: '#333333',
                },
                {
                  lightness: 40,
                },
              ],
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f2f2f2',
                },
                {
                  lightness: 19,
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#fefefe',
                },
                {
                  lightness: 20,
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#fefefe',
                },
                {
                  lightness: 17,
                },
                {
                  weight: 1.2,
                },
              ],
            },
            {
              featureType: 'poi.place_of_worship',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'poi.business',
              elementType: 'labels.icon',
              stylers: [
                {
                  color: '#e5e5e5',
                },
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'landscape.man_made.building',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f2f2f2',
                },
                {
                  visibility: 'on',
                },
              ],
            },
          ],
        });
        // Create a marker and set its position.
        const marker = new google.maps.Marker({
          map: map,
          position: { lat, lng },
          title: markText,
          icon: '/img/svg/map-pin.svg',
        });
        const infoWindow = new google.maps.InfoWindow({
          content: ReactDOMServer.renderToString(
            <InfoWindowContent
              link={link}
              content={markText}
            />
          ),
        });

        // Open the info window immediately.
        infoWindow.open(map, marker);
      }
    };

    if (!window.google) {
      loadGoogleMapScript(initializeMap);
    } else {
      initializeMap();
    }

    return () => {
      window.initMap = () => {};
    };
  }, [lat, lng, zoom, link, markText]);
  return (
    <div
      ref={googleMapWrapper}
      className={styles['googleMapComp']}
    >
      <div className='flex flex-wrap component-pb w-full'>
        <div className='flex flex-wrap w-full'>
          <div className='w-full md:w-1/3 flex  md:block lozad relative justify-center animate-section'>
            <div className={`${styles['address-col']} ${styles['block-reveal-animation']}`}>
              <div className='w-10/12 lg:w-9/12'>
                <RichTextComponent data={contentText} />
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/3 lozad animate-section'>
            <div className={`${styles['map-col']} ${styles['block-reveal-animation']}`}>
              <div
                ref={mapRef}
                className={`${styles['google-map']} js-google-map`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
