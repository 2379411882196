import { useRef } from 'react';
import * as styles from './HeroArticle.module.scss';
import Image from '@components/common/htmlTags/Image/Image';
import { IHeroBanner } from '@interfaces/ICommon';
import { renderImageOrFallback } from '@helpers/renderImageOrFallback';
import { isAbsoluteUrl } from '@helpers/isAbsoluteUrl';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';

/**
 * `HeroArticle` displays a hero section specifically designed for article pages.
 * The component is styled with a responsive design.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {IHeroBanner} props.heroBannerData - Contains the image URL and title for the hero section.
 * @returns The hero section with a featured image for article pages, wrapped in a responsive
 * design for adaptive display.
 */

const baseUrl: string = process.env.NEXT_PUBLIC_LINK_ADDRESS || '';

const HeroArticle: React.FC<{ heroBannerData: IHeroBanner }> = ({ heroBannerData }) => {
  let imageUrl = heroBannerData.Image?.Url ? heroBannerData.Image.Url : '';
  const articleHeroWrapper = useRef<HTMLDivElement>(null);
  useIsScrolledToSection(articleHeroWrapper, '', true);

  if (!isAbsoluteUrl(imageUrl) && imageUrl) {
    imageUrl = `${baseUrl}${imageUrl}`;
  }
  return (
    <div
      ref={articleHeroWrapper}
      className={`kafd-section ${styles['kafd-banner']}`}
    >
      <div className={`flex flex-wrap component-pb md:mb-24`}>
        <div className={`w-full`}>
          <div className={`${styles['kafd-banner-carousel']} js-banner-carousel`}>
            <section
              data-animate-class='animate-section'
              className={`${styles['slide-item']}`}
            >
              <div className={`${styles['banner-image-holder']} relative overflow-hidden`}>
                <div className={`animate-scale text-reveal-animation`}>
                  <div className={`${styles['image-wrapper-relative']}`}>
                    <Image
                      alt={heroBannerData.Title ?? ''}
                      url={renderImageOrFallback(imageUrl)}
                      width={0}
                      height={0}
                      sizes='100vw'
                      className={`${styles['img']}`}
                      priority
                    />
                  </div>
                </div>
                <div className={`${styles['banner-inner-content']}`}>
                  <h1 className={`${styles['banner-heading']}`}>{heroBannerData.Title}</h1>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArticle;
