import GenericButton from '@components/GenericButton/GenericButton';
import * as styles from './Tabs.module.scss';
import { ReactNode, useState, useRef, useEffect } from 'react';
import { useData } from '@context/GraphQLDataContext';
import { isElementInView } from '@hooks/useIsScrolledToSection';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

type ITab = {
  title: string;
  content: ReactNode;
  disabled?: boolean;
  onLoadMore?: () => () => void;
};

type ITabsProps = {
  items: ITab[];
  activeTab?: number;
};

/**
 * Tabs Component
 *
 *  A component that returns tabs"
 *
 * @component
 * @param {Object} prop - The props for the ResidentsCard component.
 * @param {string} prop.items - The object with array of TabType items.
 * @param {number} prop.activeTab - Active tab.
 * @returns {JSX.Element} The rendered JSX element representing tabs.
 *
 * @example
 *
 * const items = [
    {
      title: 'Tab 1',
      content: (
        <div>
          <h2>Content for Tab 1</h2>
          <p>This is the content for the first tab.</p>
        </div>
      ),
    },
    {
      title: 'Tab 2',
      content: (
        <div>
          <h2>Content for Tab 2</h2>
          <p>This is the content for the second tab.</p>
        </div>
      ),
    },
    {
      title: 'Tab 3',
      content: (
        <div>
          <h2>Content for Tab 3</h2>
          <p>This is the content for the third tab.</p>
        </div>
      ),
    },
  ];
 *
 * <Tabs items={items} />
 *
 */

const Tabs: React.FC<ITabsProps> = ({ items, activeTab }) => {
  const { translate } = useData();
  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>(
    typeof activeTab === 'number' && activeTab >= 0 && activeTab < items.length ? activeTab : 0
  );
  const tabsWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      gsap.registerPlugin(ScrollTrigger);

      const genericButton = document.querySelector("div[class*='load_more_btn']") as HTMLElement;

      if (!genericButton) return;
      genericButton.removeAttribute('data-anim');
      ScrollTrigger.create({
        start: 0,
        end: 'max',
        onUpdate: () => {
          if (isElementInView(genericButton)) {
            genericButton.dataset.anim = 'on';
          }
        },
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [activeTabIndex, items]);

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);

    if (!tabsWrapper.current) return;
    const existDataAnimAttrs = tabsWrapper.current.querySelectorAll('[data-anim=on]') as unknown as HTMLElement[];

    if (!existDataAnimAttrs) return;
    existDataAnimAttrs.forEach((item: HTMLElement) => {
      item.removeAttribute('data-anim');

      setTimeout(() => (item.dataset.anim = 'on'), 200);
    });
  };

  if (!Array.isArray(items)) {
    return null;
  }
  return (
    <div
      ref={tabsWrapper}
      className={`${styles['page__tabs']} ${styles['animate-section']}`}
      data-toggle-class='animate-section'
      data-loaded='true'
    >
      <ul className={styles['tabs__header']}>
        {items.map((tab, index) => (
          <li
            key={tab.title || index}
            onClick={() => handleTabClick(index)}
            className={`${styles['tab__anchor']} ${activeTabIndex === index ? styles.active : ''}`}
          >
            {tab.title}
          </li>
        ))}
      </ul>

      {typeof activeTabIndex === 'number' && (
        <div className='w-full'>
          {items[activeTabIndex]?.content}
          {items[activeTabIndex] && !items[activeTabIndex].disabled && (
            <div className='load_more_btn'>
              <GenericButton
                className='bwcta__btn__btn'
                onClick={items[activeTabIndex].onLoadMore}
                title={translate('load-more-results')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tabs;
