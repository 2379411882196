import { FC, useRef } from 'react';
import Link from 'next/link';
import Image from '@components/common/htmlTags/Image/Image';
import * as styles from './EventCard.module.scss';
import GenericButton from '@components/GenericButton/GenericButton';
import RichTextComponent from '@components/RichText/RichText';
import { renderImageOrFallback } from '@helpers/renderImageOrFallback';
import Icon from '@components/Icon/Icon';
import { useData } from '@context/GraphQLDataContext';
import useIsScrolledToSection from '@hooks/useIsScrolledToSection';
import React from 'react';
import { normalizePath } from '@helpers/normalizePath';

export interface IEventCard {
  dateFrom: string;
  dateTo?: string | null;
  title: string;
  imageSrc?: string;
  buttonTitle?: string;
  buttonUrl?: string;
  eventDescription?: string | undefined;
  isArticlePreview?: boolean;
}

/**
 * EventCard Component
 *
 * A component that returns event article with date, title, text, image and generic button"
 *
 * @component
 * @param {Object} prop - The props for the EventCard component.
 * @param {string} prop.dateFrom - The DateFrom of event.
 * @param {string} prop.dateTo - The DateTo of event.
 * @param {string} prop.title - The title of event.
 * @param {string} prop.imageSrc - The image of event.
 * @param {string} prop.buttonTitle - The title of generic button.
 * @param {string} prop.buttonUrl - The URL of generic button.
 * @param {string} prop.eventDescription - The text of event.
 * @param {boolean} prop.isArticlePreview - if EventCard is being used on MediaCentre
 * page (as article preview at the top)
 * @returns {JSX.Element} The rendered JSX element representing article with DateFrom, DateTo, Heading, Image, *        LinkText, LinkUrl and Perex(Richtext)
 *
 * @example
 * {eventsList.map((event: EventCardType) => (
 *   <EventCard
 *     date={event.date}
 *     key={event.imgSrc}
 *     title={event.title}
 *     text={event.text}
 *     imgSrc={event.imgSrc}
 *     linkHref={event.linkHref}
 *     linkTitle={event.linkTitle}
 *   />
 * ))}
 */

// Helper function: strip protocol and domain from a URL, leaving only the path.
function stripDomain(url: string): string {
  if (!url) return '';

  const anchor = document.createElement('a');
  anchor.href = url;
  let pathname = anchor.pathname;
  const search = anchor.search;
  if (pathname && pathname[0] !== '/') {
    pathname = '/' + pathname;
  }
  try {
    if (anchor.host) {
      return pathname + search;
    } else {
      return url;
    }
  } catch (e: any) {
    console.log(`${e} happened in ${url}`);
    return url;
  }
}

const EventCard: FC<IEventCard> = ({
  dateFrom,
  dateTo,
  title,
  imageSrc = '',
  buttonTitle,
  buttonUrl,
  eventDescription,
  isArticlePreview = false,
}): JSX.Element => {
  const { translate } = useData();
  const ASSETS_PATH = process.env.NEXT_PUBLIC_ASSETS_PATH || '';

  const normalizedImagePath = stripDomain(imageSrc);
  const imageUrl = `${ASSETS_PATH}${normalizedImagePath}`;

  const normalizedButtonPath = buttonUrl ? stripDomain(buttonUrl) : '';
  const finalButtonUrl = `${ASSETS_PATH}${normalizedButtonPath}`;

  const articlePreview = useRef(null);
  useIsScrolledToSection(articlePreview, '', true, 'top 100%');

  return (
    <div ref={articlePreview}>
      <div
        className={`${isArticlePreview ? styles['articlePreview'] : ''} 
                    ${styles['event-listing-wrap']} ${styles['block-reveal-animation']}`}
      >
        <div className={`${styles['event-listing-card']}`}>
          <div className={`${isArticlePreview ? styles['displaySecond'] : ''} flex flex-col flex-1`}>
            <div className={styles['event-listing-date']}>
              <span>{dateFrom}</span>
              {dateTo && <span>&nbsp; - &nbsp;</span>}
              <span>{dateTo}</span>
            </div>

            <div className={styles['event-listing-title']}>{title}</div>

            {!isArticlePreview && (
              <div className={`${styles['event-listing-description']} contenteditor`}>
                <RichTextComponent data={eventDescription ? eventDescription : ''} />
              </div>
            )}

            <div className={styles['event-listing-footer-wrap']}>
              <div className={`flex ${styles['event-listing-footer']}`}>
                <div className={styles['event-listing-footer-col']}>
                  <div
                    className={`${styles['event-listing-read-more']}`}
                    data-loaded='true'
                  >
                    {finalButtonUrl && buttonTitle && (
                      <>
                        <GenericButton
                          url={normalizePath(finalButtonUrl)}
                          className={`${isArticlePreview ? styles['hideThisOnMobile'] : ''} ${styles['btn__primary-1']}`}
                          title={buttonTitle}
                        />

                        {isArticlePreview && (
                          <Link
                            prefetch={false}
                            href={finalButtonUrl}
                            target='_self'
                            className={`md:hidden button-style-2 ${styles['articlePreviewButton']}`}
                          >
                            <span>{translate('read-more')}</span>
                            <div className='icon cta-icon'>
                              <Icon.ArrowAnchorIcon />
                            </div>
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${isArticlePreview ? styles['displayFirst'] : ''} ${styles['event-listing-picture']}`}>
            <Image
              width={0}
              height={0}
              sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
              url={renderImageOrFallback(imageUrl)}
              alt={title ?? ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EventCard);
