import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

interface IOrderedList {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * OrderedList Component
 *
 *A component representing the <ol>{children}</ol>.
 *
 * @component
 * @param {Object} prop - The props for the OrderedList component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <ol></ol>.
 * @returns {JSX.Element} The rendered JSX element representing the <ol>{children}</ol>.
 *
 * @example
 *  <OrderedList className={['test1', 'test2', 'test3']}>TEST</OrderedList>
 */

const OrderedList = (prop: IOrderedList): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <ol
      className={clsx(className)}
      style={style}
    >
      {children}
    </ol>
  );
};

export default OrderedList;
