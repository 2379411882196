import { FC, useState, useRef, useEffect } from 'react';
import Accordion from '@components/AccordionList/Accordion/Accordion';
import * as styles from './AccordionList.module.scss';
import NextImage from '@components/common/htmlTags/Image/Image';
import useUUID from '@hooks/useUUID';
import gsap from 'gsap';
import GenericButton from '@components/GenericButton/GenericButton';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { animateSection } from '@helpers/animations.helper';
import { useData } from '@context/GraphQLDataContext';

interface IMixedHtmlModelSearch {
  __typename: 'MixedHtmlModelSearch';
  Structure: string;
}

interface IFaqEntryBlock {
  __typename: 'FaqEntryBlock';
  Editor: IMixedHtmlModelSearch;
  Title: string;
}

interface IContentModelReferenceSearch {
  __typename: 'ContentModelReferenceSearch';
  Expanded: IFaqEntryBlock;
}

interface IContentAreaItemModelSearch {
  __typename: 'ContentAreaItemModelSearch';
  ContentLink: IContentModelReferenceSearch;
}

interface IAccordionListProps {
  Title?: string;
  VisibleAmount?: number;
  Loadmore?: string;
  Loadless?: string;
  Items?: IContentAreaItemModelSearch[];
  Image?: {
    Url: string;
  };
}

/**
 * Component representing an accordion list.
 *
 * @component
 * @param {object} props - The props of the AccordionList component.
 * @param {string} Title - The title of the accordion list.
 * @param {number} VisibleAmount - The number of items visible in the accordion list. Default is 5
 * @param {array} Items - The array of items for the accordion list.
 * @returns {JSX.Element} - The rendered AccordionList component.
 */

const AccordionList: FC<IAccordionListProps> = ({ Title, VisibleAmount = 5, Items, Image, Loadmore, Loadless }) => {
  const [maxItems, setMaxItems] = useState(VisibleAmount);
  const [isOpen, setIsOpen] = useState<number | undefined>(undefined);
  const lengthOfItems: number | undefined = Items?.length;
  const accordionListItemKeys = useUUID(lengthOfItems);
  const timeoutIDs = useRef<number[]>([]);
  const { translate } = useData();
  // Refs
  const accordionWrapperRef = useRef<HTMLDivElement>(null);
  const accordionListItemBtnRef = useRef<HTMLDivElement>(null);
  const accordionTitleWrapperRef = useRef<HTMLDivElement>(null);

  const handleLoadItems = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Load less items
    if (maxItems === lengthOfItems) {
      if (!accordionWrapperRef.current) return;

      const loadedItems = accordionWrapperRef.current.querySelectorAll('.loaded-item');
      const tl = gsap.timeline();
      loadedItems.forEach((item) => {
        tl.to(item, { opacity: 0, y: -40 }, '<');
      });

      const timeoutId = window.setTimeout(() => {
        setMaxItems(VisibleAmount);
        ScrollTrigger.refresh();
        // Scroll adjustment for collapsing
        if (accordionWrapperRef.current) {
          const wrapperTop = accordionWrapperRef.current.getBoundingClientRect().top;
          if (wrapperTop < 0) {
            accordionWrapperRef.current.scrollIntoView({
              block: 'start',
            });
          }
        }
      }, 200);
      timeoutIDs.current.push(timeoutId);
    } else {
      // Load more items
      setMaxItems(maxItems + ((lengthOfItems || 0) - VisibleAmount));

      const timeoutId = window.setTimeout(() => {
        if (!accordionWrapperRef.current) return;
        const newLoadedItems = Array.from(accordionWrapperRef.current.querySelectorAll('.loaded-item')).slice(
          -((lengthOfItems || 0) - maxItems)
        );
        gsap.set(newLoadedItems, { opacity: 0, y: 40 });
        newLoadedItems.forEach((item) => {
          gsap.to(item, { opacity: 1, y: 0, duration: 0.5, delay: 0.5 });
        });
        // Ensure that ScrollTrigger updates its calculations based on the new scroll height
        // whenever the accordion expands or collapses significantly
        ScrollTrigger.refresh();
      }, 0);
      timeoutIDs.current.push(timeoutId);
    }
  };

  const handleOpen = (index: number | undefined) => {
    if (typeof index === 'undefined') {
      setIsOpen(undefined);
    } else {
      setIsOpen(index);
      const timeoutId = window.setTimeout(() => {
        const accordionItem = accordionWrapperRef.current?.children[index];
        if (accordionItem) {
          const { top, bottom } = accordionItem.getBoundingClientRect();
          const isVisible = top >= 0 && bottom <= window.innerHeight;

          if (!isVisible) {
            accordionItem.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      }, 500);
      timeoutIDs.current.push(timeoutId);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Animation for accordionListItemBtn
    const btnTrigger = ScrollTrigger.create({
      trigger: accordionListItemBtnRef?.current,
      start: 'top 90%',
      animation: animateSection(accordionListItemBtnRef?.current).play(),
    });

    // Animation for accordionTitle
    const titleTrigger = ScrollTrigger.create({
      trigger: accordionTitleWrapperRef?.current,
      start: 'top 90%',
      once: true,
      onToggle: (self) => {
        const element = accordionTitleWrapperRef?.current;
        if (element && self.isActive) {
          element.classList.add('reveal-content');
        }
      },
    });

    return () => {
      btnTrigger.kill();
      titleTrigger.kill();
      timeoutIDs.current.forEach(clearTimeout);
      timeoutIDs.current = [];
    };
  }, []);

  const getButtonText = (): string => {
    if (maxItems === lengthOfItems) {
      return Loadless && Loadless !== '' ? Loadless : `${translate('load-less')} ${Title}`;
    }
    return Loadmore && Loadmore !== '' ? Loadmore : `${translate('load-all')} ${Title}`;
  };

  return (
    <div className={styles.accordionWrapper}>
      {Image?.Url && (
        <NextImage
          width={139.5}
          height={139.5}
          sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
          url={Image?.Url}
          alt=''
          className={` ${styles.decor}`}
        />
      )}
      <div
        ref={accordionTitleWrapperRef}
        className='animate-block'
      >
        <h2 className={styles.accordionWrapperTitle}>{Title}</h2>
      </div>
      <div
        ref={accordionWrapperRef}
        className={styles.accordionInnerWrapper}
      >
        {Array.isArray(Items) &&
          Items.map((acc: IContentAreaItemModelSearch, index: number) => {
            if (index + 1 > maxItems) return null;

            return (
              <Accordion
                key={accordionListItemKeys[index]}
                handleOpen={handleOpen}
                isOpen={index === isOpen}
                index={index}
                classname={index < VisibleAmount ? 'default-item' : 'loaded-item'}
                title={acc.ContentLink?.Expanded.Title}
                content={acc.ContentLink?.Expanded.Editor.Structure}
              />
            );
          })}
      </div>
      {(lengthOfItems || 0) > VisibleAmount && (
        <div
          ref={accordionListItemBtnRef}
          className={styles.buttonWrapper}
        >
          <GenericButton onClick={(event) => handleLoadItems(event)}>{getButtonText()}</GenericButton>
        </div>
      )}
    </div>
  );
};

export default AccordionList;
