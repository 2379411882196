import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './ListItem.module.scss';
import React from 'react';

interface IListItem {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
  onClick?: (event: any) => void;
}

/**
 * ListItem Component
 *
 *A component representing the <li>{children}</li>.
 *
 * @component
 * @param {Object} prop - The props for the ListItem component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <li></li>.
 * @param {function} prop.onClick - Event handler called when one is clicked inside the list.
 * @returns {JSX.Element} The rendered JSX element representing the <li>{children}</li>.
 *
 * @example
      <UnorderedList className={['test1', 'test2', 'test3']}>
        <ListItem className='test4'>TEST</ListItem>
      </UnorderedList>
 *  
 */

const ListItem = (prop: IListItem): JSX.Element => {
  const { className, style, onClick, children } = prop;
  return (
    <li
      className={clsx(styles.listItem, className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </li>
  );
};

export default React.memo(ListItem);
