import React, { useMemo } from 'react';
import * as styles from './FormWrapper.module.scss';
import RichTextComponent from '@components/RichText/RichText';
import FormContainerBlock from '@components/FormContainerBlock/FormContainerBlock';
import WhistleBlowerForm from '@components/WhistleBlowerForm/WhistleBlowerForm';
import WhistleBlowerFormStatus from '@components/WhistleBlowerForm/WhistleBlowerStatusForm';

export interface IContentType {
  __typename?: string;
  ContentType: string[];
  ContentLink: IContentModelReference;
  Id?: number;
  Type?: string;
}

interface IContentModelReference {
  __typename?: string;
  Id: number;
  GuidValue: string;
  Expanded: IContentType;
}

interface IContentAreaItemModel {
  __typename?: string;
  ContentLink: IContentModelReference;
}

interface IFormWrapperProps {
  formData: IContentAreaItemModel;
  leftContent: string;
  formEndpoint?: string;
}

const FormWrapper: React.FC<IFormWrapperProps> = ({ formData, leftContent, formEndpoint }) => {
  const isWhistleBlowerForm = useMemo(
    () => formData.ContentLink.Expanded.ContentType.includes('WhistleblowerFormBlock'),
    [formData.ContentLink.Expanded.ContentType]
  );

  const whistleblowingType = formData?.ContentLink?.Expanded?.Type;

  return (
    <div className={styles.FormWrapper}>
      <div className={styles.container}>
        <RichTextComponent
          className={`contenteditor animate-block ${styles.leftContent} ${styles.whistleblowingLeftContent}`}
          data={leftContent}
        />
        <div className={`${styles.decoration}`}></div>
        <div className={`${styles.icon}`} />
      </div>
      <div className={`${styles.container}`}>
        <div className={styles.formWrapper}>
          {formData &&
            (isWhistleBlowerForm ? (
              whistleblowingType === 'report_form' ? (
                <WhistleBlowerFormStatus />
              ) : (
                <WhistleBlowerForm />
              )
            ) : (
              <FormContainerBlock
                formEndpoint={formEndpoint}
                GuidValue={formData.ContentLink?.GuidValue ?? formData.ContentLink.Expanded.ContentLink?.GuidValue}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormWrapper);
