import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

interface ISubscript {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Subscript Component
 *
 *A component representing the <sup>{children}</sup>.
 *
 * @component
 * @param {Object} prop - The props for the Subscript component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <sup></sup>.
 * @returns {JSX.Element} The rendered JSX element representing the <sup>{children}</sup>.
 *
 * @example
 *  <Subscript className={['test1', 'test2', 'test3']}>TEST</Subscript>
 */

const Subscript = (prop: ISubscript): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <sub
      className={clsx(className)}
      style={style}
    >
      {children}
    </sub>
  );
};

export default Subscript;
