import React from 'react';
import * as styles from './GoogleMapInfoWindow.module.scss';
import { trimUrl } from '@helpers/url.helper';

interface InfoWindowContentProps {
  link: string;
  content: string;
}

const InfoWindowContent: React.FC<InfoWindowContentProps> = ({ link, content }) => {
  return (
    <div className={styles['map-modal']}>
      <a
        href={trimUrl(link || '#')}
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className={styles['map-modal-content']}>{content}</div>
      </a>
    </div>
  );
};

export default InfoWindowContent;
