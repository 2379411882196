import Icon from '@components/Icon/Icon';
import * as styles from '@layouts/Footer/Footer.module.scss';
import { ISocialMediaLinks } from '@interfaces/IConfigPage';
import { useData } from '@context/GraphQLDataContext';

const FooterSocials: React.FC<{ socialMediaLinks?: ISocialMediaLinks }> = ({ socialMediaLinks }) => {
  const { translate } = useData();
  return (
    <div className={`items-center visible flex ${styles['first-bottom']}`}>
      {/* DESKTOP-ONLY Socials */}
      <ul className={`${styles['besocial']} ${styles['text-reveal-animation']} lvl-1`}>
        <li>
          <a
            href={socialMediaLinks?.TwitterLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Tw</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoTwIcon
                viewBox='0 0 16 14'
                className={`${styles['svgicon']} replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Tw</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.FacebookLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Fb</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoFbIcon
                viewBox='0 0 9 16'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Fb</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.InstagramLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Ig</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoInIcon
                viewBox='0 0 15 15'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Ig</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.LinkedInLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Ln</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoLiIcon
                viewBox='0 0 13 12'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Ln</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.YoutubeLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Yt</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoYtIcon
                viewBox='0 0 16 12'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Yt</span>
          </a>
        </li>
      </ul>

      {/* MOBILE-ONLY Socials */}
      <ul className={`${styles['besocial']} mx-auto ${styles['besocial_mobile']} lvl-1 `}>
        <li>
          <a
            href={socialMediaLinks?.TwitterLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Tw</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoTwIcon
                viewBox='0 0 16 14'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Tw</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.FacebookLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Fb</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoFbIcon
                viewBox='0 0 9 16'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Fb</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.InstagramLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Ig</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoInIcon
                viewBox='0 0 15 15'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Ig</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.LinkedInLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Ln</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoLiIcon
                viewBox='0 0 13 12'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Ln</span>
          </a>
        </li>
        <li>
          <a
            href={socialMediaLinks?.YoutubeLink || '#'}
            target='_blank'
          >
            <span className={`${styles['title']}`}>Yt</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoYtIcon
                viewBox='0 0 16 12'
                className={`${styles['svgicon']}  replaced-svg`}
              />
            </span>
            <span className={`txt__right_nav hidden`}>Yt</span>
          </a>
        </li>
      </ul>

      {/* DESKTOP-ONLY Phone & Contact */}
      <ul className={`${styles['f-contact']} ${styles['socials-contact-us']} ${styles['text-reveal-animation']} lvl-1`}>
        <li>
          <a
            className={`${styles['footer-tel']}`}
            href={`tel:${translate('phone')}`}
            target='_blank'
          >
            <span className={`${styles['title']}`}>{translate('phone')}</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoTelV2Icon className={`${styles['icon']}  replaced-svg`} />
            </span>
            <span className={`txt__right_nav ${styles['label']}`}>{translate('phone')}</span>
          </a>
        </li>
        <li>
          <a
            className={`${styles['footer-tel']}`}
            href={`mailto:${translate('mail')}`}
            target='_self'
          >
            <span className={`${styles['title']}`}>{translate('mail')}</span>
            <span className={`${styles['icon']}`}>
              <Icon.IcoMailEnvIcon className={`${styles['icon']}  replaced-svg`} />
            </span>
            <span className={`txt__right_nav ${styles['label']}`}>{translate('mail')}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocials;
