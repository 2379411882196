import { Number } from '@episerver/forms-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ValidationMessage, ElementCaption } from './shared';
import Icon from '@components/Icon/Icon';
import { DispatchFunctions } from '@episerver/forms-react/context/dispatchFunctions';
import { useData } from '@context/GraphQLDataContext';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom } from '@components/WhistleBlowerForm/utils/interfaces';

export interface NumberElementBlockProps {
  element: Number;
}

export const NumberLimitElementBlock = (props: NumberElementBlockProps) => {
  const { element } = props;
  const dispatch = new DispatchFunctions();
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { isVisible, validationResults, value, elementRef, validatorClasses, extraAttr } = elementContext;
  const [isFocused, setIsFocused] = useState(false);
  const [toValue, setToValue] = useState<number>(1);
  const isInputFrom = element.properties.relation === 'from';
  let message = element.properties.validators[0].model.message;
  const { translate } = useData();

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    const target = e.target.ownerDocument.activeElement as HTMLInputElementCustom;
    target.value = '';
    handleChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
    if (element.properties.validators[0].type === 'RequiredValidator') {
      dispatch.updateValidation(element.key, {
        valid: false,
        message: element.properties.validators[0].model.message,
      });
    }
  };

  const onBlurHandler = (e: any) => {
    if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsFocused(false);
      if (isValueValid(e)) {
        handleBlur(e);
      }
    }, 100);
  };

  const doValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValueValid(e)) {
      dispatch.updateValidation(element.key, {
        valid: false,
        message: message,
      });
    } else {
      dispatch.updateValidation(element.key, {
        valid: true,
        message: '',
      });
    }
  };

  useEffect(() => {
    if (!isInputFrom) return;
    doValidation({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
  }, [toValue]);

  useEffect(() => {
    if (element.properties.relation === 'from') {
      const toDiv = document.querySelector('input[data-limit-type="to"]');

      if (!toDiv) return;
      const config = { attributes: true, childList: false, subtree: false };

      const callback = function (mutationsList: any, observer: any) {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
            const toValue = parseFloat(toDiv.getAttribute('value') ?? '0');
            setToValue(toValue);
          }
        }
      };

      const observer = new MutationObserver(callback);

      if (toDiv) {
        observer.observe(toDiv, config);
      }

      return () => observer.disconnect();
    }
  }, [element.properties.relation, value]);

  const isValueValid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);

    if (value < (element.properties.min ?? 0) || value > element.properties.max) {
      message = element.properties.validators[0].model.message;
      return false;
    } else if (isInputFrom && value > toValue) {
      message = translate('form-error-min-max');
      return false;
    }
    return true;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    doValidation(e);
    handleChange(e);
  };

  return useMemo(
    () => (
      <ElementWrapper
        className={` FormTextbox FormTextbox--Number ${validatorClasses}`}
        validationResults={validationResults}
        isVisible={isVisible}
      >
        <div lang={element.locale}>
          <ElementCaption
            element={element}
            className={value || value === 0 ? 'filled textInputLabel' : 'textInputLabel'}
          />

          <input
            data-limit-type={element.properties.relation}
            name={element.key}
            id={element.key}
            className="FormTextbox__Input"
            type="number"
            min={element.properties.min ?? 0}
            max={element.properties.max}
            step="any"
            placeholder={element.properties.placeHolder}
            {...extraAttr}
            value={value}
            aria-describedby={`${element.key}_desc`}
            autoComplete={element.properties.autoComplete}
            onChange={onChange}
            onBlur={onBlurHandler}
            onFocus={() => setIsFocused(true)}
            ref={elementRef}
          />

          <ValidationMessage
            element={element}
            validationResults={validationResults}
          />
          {isFocused ? (
            <Icon.CloseModalIcon
              onClick={handleClearInput}
              onMouseDown={handleClearInput}
              onTouchEnd={handleClearInput}
              className="IconClose IconCloseVisible"
            />
          ) : (value || value === 0) && validationResults.result.valid && !isFocused ? (
            <Icon.Tick className="TickIcon" />
          ) : (
            <Icon.CloseModalIcon
              onClick={handleClearInput}
              onMouseDown={handleClearInput}
              onTouchEnd={handleClearInput}
              className="IconClose IconCloseVisible"
            />
          )}
        </div>
      </ElementWrapper>
    ),
    [isVisible, validationResults, value]
  );
};
