import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

const useUUID = (arrayLength: number = 1): string[] => {
  return useMemo(() => {
    const ids: string[] = [];
    for (let i = 0; i < arrayLength; i += 1) {
      ids.push(uuid());
    }
    return ids;
  }, [arrayLength]);
};

export default useUUID;
