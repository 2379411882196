export interface ICheckIdentityState {
  inputValues: {
    name: string;
    email: string;
    code: string;
  };
  isValid: boolean;
  sentEmail: boolean;
  verifySuccess: boolean;
  showInputs: boolean;
  verifyMessage: string;
  emailMessage: string;
  checkIdentityInputsValid: any;
  isVerificationCodeValid: boolean;
}

type Action =
  | { type: 'SET_INPUT_VALUES'; payload: { name: string; value: string } }
  | { type: 'SET_IS_VALID'; payload: boolean }
  | { type: 'SET_SENT_EMAIL'; payload: boolean }
  | { type: 'SET_VERIFY_SUCCESS'; payload: boolean }
  | { type: 'SET_SHOW_INPUTS'; payload: boolean }
  | { type: 'SET_VERIFY_MESSAGE'; payload: string }
  | { type: 'SET_EMAIL_MESSAGE'; payload: string }
  | { type: 'SET_CHECK_IDENTITY_INPUTS_VALID'; payload: any }
  | { type: 'SET_IS_VERIFICATION_CODE_VALID'; payload: boolean };

export const checkIdentityReducer = (state: ICheckIdentityState, action: Action): ICheckIdentityState => {
  switch (action.type) {
    case 'SET_INPUT_VALUES':
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_IS_VALID':
      return {
        ...state,
        isValid: action.payload,
      };
    case 'SET_SENT_EMAIL':
      return {
        ...state,
        sentEmail: action.payload,
      };
    case 'SET_VERIFY_SUCCESS':
      return {
        ...state,
        verifySuccess: action.payload,
      };
    case 'SET_SHOW_INPUTS':
      return {
        ...state,
        showInputs: action.payload,
      };
    case 'SET_VERIFY_MESSAGE':
      return {
        ...state,
        verifyMessage: action.payload,
      };
    case 'SET_EMAIL_MESSAGE':
      return {
        ...state,
        emailMessage: action.payload,
      };
    case 'SET_CHECK_IDENTITY_INPUTS_VALID':
      return {
        ...state,
        checkIdentityInputsValid: action.payload,
      };
    case 'SET_IS_VERIFICATION_CODE_VALID':
      return {
        ...state,
        isVerificationCodeValid: action.payload,
      };
    default:
      return state;
  }
};
