import { DetermineLanguage } from '@helpers/determineLanguage';
import en from '@components/WhistleBlowerForm/translations/en.json';
import ar from '@components/WhistleBlowerForm/translations/ar.json';
import { IReportTypeWithValue, IConcernWithValue, IQuestionWithValue } from './interfaces';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TranslationKeys } from '../translations/translationKeys';

interface TranslationPair {
  [key: string]: string;
}
interface IWhistleblowerData {
  Id: string;
  Title: string;
  KAFDFeedback: string | null;
  ViewOnly: boolean;
}

const languageTranslations: { [key: string]: TranslationPair } = { en, ar };

export function getTranslation(key: string): string {
  const language = DetermineLanguage();
  return languageTranslations[language][key] || key;
}

export const getWhistleblowerData = async (
  endpoint: string,
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  mapFunc: (item: any) => any,
  setError?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setData(data.map(mapFunc));
  } catch (error) {
    if (setError) {
      setError(true);
    }
    console.error(`Error fetching data from ${endpoint}`, error);
  }
};

export const submitWhistleblowerData = async <T>(
  endpoint: string,
  data: any,
  setResponse?: React.Dispatch<React.SetStateAction<T>> | null,
  setError?: React.Dispatch<React.SetStateAction<boolean>> | null,
  method: string = 'POST'
): Promise<IWhistleblowerData | undefined> => {
  try {
    let finalEndpoint = endpoint;
    let requestBody: string | undefined;

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (method === 'GET') {
      // For GET requests, append parameters to URL
      const params = new URLSearchParams({
        code: data.code,
        referenceNumber: data.referenceNumber,
      });
      finalEndpoint = `${endpoint}?${params.toString()}`;
    } else {
      if (data.recaptchaToken) {
        headers['X-Recaptcha-Token'] = data.recaptchaToken;
      }
      // For PUT/POST requests, send data in body
      requestBody = JSON.stringify(data);
    }

    const requestOptions: RequestInit = {
      method,
      headers,
      ...(requestBody && { body: requestBody }),
    };

    const submitResponse = await fetch(finalEndpoint, requestOptions);

    if (!submitResponse.ok) {
      console.error('Server responded with an error:', submitResponse.statusText);
      if (setError) {
        setError(true);
      }
      return;
    }

    if (submitResponse.status === 204) {
      return undefined; // Indicate success without data
    }

    const contentType = submitResponse.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      try {
        const responseData = await submitResponse.json();
        if (setResponse) {
          setResponse(responseData);
        }
        return responseData;
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        if (setError) {
          setError(true);
        }
        return;
      }
    } else {
      // Handle non-JSON responses if necessary
      const textData = await submitResponse.text();
      console.warn('Received non-JSON response:', textData);
      return;
    }
  } catch (error) {
    console.error('Network error occurred while submitting form data:', error);
    if (setError) {
      setError(true);
    }
  }
};

export async function getRecaptchaToken(
  recaptchaRef: React.RefObject<ReCAPTCHA> | undefined,
  dispatch: React.Dispatch<any>
) {
  if (!recaptchaRef) {
    dispatch({
      type: 'SET_RECAPTCHA_ERROR',
      payload: getTranslation(TranslationKeys.RECAPTCHA_VERIFICATION_FAILED),
    });
    return null;
  }
  const captchaInstance = recaptchaRef.current as ReCAPTCHA;

  const token = await captchaInstance.executeAsync();

  return token;
}

export function validateAllFormInputs(formInputsValid: { [key: string]: boolean }): boolean {
  return Object.values(formInputsValid).every((isValid) => isValid);
}

export function validateAllReportTypes(reportTypes: IReportTypeWithValue[]): boolean {
  return reportTypes.some((report) => report.value === true && report.otherValue !== '');
}

export function validateAllConcerns(concerns: IConcernWithValue[]): boolean {
  return concerns.some((concern) => concern.value === true);
}

export function validateAllQuestions(questions: IQuestionWithValue[]): boolean {
  return questions.some((question) => question.mandatory === true && question.value !== '');
}

export interface UploadedAttachment {
  id: string;
  fileName: string;
}
