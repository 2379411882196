import React from 'react';
import { IHeroBanner } from '@interfaces/ICommon';
import Hero from '@components/Hero/Hero';
import Events from './Events/Events';
import RichTextComponent from '@components/RichText/RichText';
import { IEvent, IEventsIDs, IEventsPageLayout } from '@interfaces/IEventsPage';

const categorizeEvents = (events: IEvent[]): IEventsIDs => {
  const now = new Date();

  const pastEventsIds: string[] = [];
  const currentEventsIds: string[] = [];
  const futureEventsIds: string[] = [];

  Array.isArray(events) &&
    events.forEach((event) => {
      const dateFrom = new Date(event.ContentLink.Expanded.DateFrom);
      const dateTo = event.ContentLink.Expanded.DateTo ? new Date(event.ContentLink.Expanded.DateTo) : null;

      if ((dateTo && dateTo < now) || (!dateTo && dateFrom < now)) {
        pastEventsIds.push(event.ContentLink.GuidValue);
      } else if (
        (dateTo && dateTo > now && dateFrom <= now) ||
        (!dateTo && dateFrom.toDateString() === now.toDateString())
      ) {
        currentEventsIds.push(event.ContentLink.GuidValue);
      } else if (dateFrom > now) {
        futureEventsIds.push(event.ContentLink.GuidValue);
      }
    });
  return { pastEventsIds, currentEventsIds, futureEventsIds };
};

const EventsPageLayout = ({ data }: { data: IEventsPageLayout }) => {
  const heroBannerData: IHeroBanner = data?.HeroBanner.ContentLink.Expanded;

  return (
    <>
      <Hero heroBannerData={heroBannerData} />
      <div className={`kafd-container ${data?.Classname}`}>
        <div className='offset-1 col-9 offset-md-1 col-md-9 offset-sm-0 col-sm-12'>
          <div className={`contenteditor`}>
            <RichTextComponent
              data={data?.TopContent[0]?.ContentLink.Expanded.Editor.Structure}
              className={data?.TopContent[0]?.ContentLink.Expanded.Classname}
            />
          </div>
        </div>

        <Events {...categorizeEvents(data?.Events)} />
      </div>
    </>
  );
};

export default EventsPageLayout;
