import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './Heading1.module.scss';

interface IHeading1 {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Heading1 Component
 *
 *A component representing the <h1>{children}</h1>.
 *
 * @component
 * @param {Object} prop - The props for the Heading1 component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <h1></h1>.
 * @returns {JSX.Element} The rendered JSX element representing the <h1>{children}</h1>.
 *
 * @example
 *  <Heading1 className={['test1', 'test2', 'test3']}>TEST</Heading1>
 */

const Heading1 = (prop: IHeading1): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <h1
      className={clsx(styles.heading1, className)}
      style={style}
    >
      {children}
    </h1>
  );
};

export default Heading1;
