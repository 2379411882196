import RichTextComponent from '@components/RichText/RichText';
import Tabs from './Tabs/Tabs';

interface ITabsBlock {
  data: any;
  activeTab?: number;
}

const TabsBlock = ({ data, activeTab }: ITabsBlock) => {
  if (Object.keys(data).some((key) => key === '__typename')) {
    const tabs =
      Array.isArray(data.Tabs) &&
      data.Tabs.map((item: { ContentLink: { Expanded: { Title: any; Content: any } } }) => {
        const { Title, Content } = item.ContentLink.Expanded;
        const tabContent = (
          <RichTextComponent
            data={Content[0]?.ContentLink.Expanded.Editor.Structure}
            className={Content[0]?.ContentLink.Expanded.Classname}
          />
        );
        return { title: Title, content: tabContent };
      });
    return (
      <Tabs
        activeTab={activeTab}
        items={tabs}
      />
    );
  }
  return (
    <Tabs
      activeTab={activeTab}
      items={data}
    />
  );
};

export default TabsBlock;
