import { queryGraphQl } from '@lib/apolloClient';
import SearchQueryCategories from '@graphql/queries/SearchQueryCategories.graphql';
import { useData } from '@context/GraphQLDataContext';

type SearchQueryParams = {
  query: string;
  limit: number;
  cursor: string | null;
  locale?: string;
};

interface Category {
  name: string;
  count: number;
}

interface CategoryModelFacet {
  Name: Category[];
}

interface Facet {
  Category: CategoryModelFacet;
}

interface PageOutput {
  facets?: Facet;
}

interface Data {
  [key: string]: PageOutput;
}

function aggregateCategories(data: Data): Category[] {
  const categories: { [name: string]: number } = {};
  const addCategory = (name: string, count: number): void => {
    categories[name] = (categories[name] || 0) + count;
  };
  Object.values(data).forEach((page: PageOutput) => {
    page.facets?.Category.Name.forEach((category: Category) => {
      addCategory(category.name, category.count);
    });
  });

  const result: Category[] = Object.keys(categories).map((name: string) => ({
    name,
    count: categories[name],
  }));

  return result;
}

export const useSearchCategories = (): [(query: string) => Promise<any>] => {
  const { data } = useData();
  const search = async (query: string): Promise<Category[]> => {
    const queryResult = await queryGraphQl<any>(SearchQueryCategories, {
      query: query,
      locale: data?.language,
    } as SearchQueryParams);
    const aggregatedCategories = aggregateCategories(queryResult);
    return aggregatedCategories;
  };

  return [search];
};
