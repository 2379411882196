import React from 'react';
import styles from './InformationModal.module.scss';
import Icon from '@components/Icon/Icon';
import { getTranslation } from '../../utils/helpers';
import { TranslationKeys } from '../../translations/translationKeys';
import { IInformationModal } from '../../utils/interfaces';

const InformationModal: React.FC<IInformationModal> = ({ handleModal, title, trackingNumber, secretCode }) => {
  return (
    <div
      className={`${styles['kafd-file-popup']} ${styles['js-file-popup']} ${styles['active']}`}
      data-active-file-instance=''
    >
      <div className={`${styles['file-popup-content']}`}>
        <a
          href='#'
          className={`${styles['close-btn']} ${styles['js-popup-close-btn']}`}
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            handleModal('information', false);
          }}
        >
          <Icon.CloseModalIcon className={`${styles['close-icon']} ${styles['icon-img']}`} />
        </a>

        <div className={`${styles['popup-content-in']}`}>
          <p className={styles['popup-title']}>{title}</p>

          <p className={`${styles['popup-msg']} ${styles['file-remove-msg']}`}>
            {`${getTranslation(TranslationKeys.SUBMIT_REPORT_MESSAGE_HEADER1)} ${getTranslation(TranslationKeys.SUBMIT_REPORT_MESSAGE_HEADER2)}`}
            <br />
            <br />
            {getTranslation(TranslationKeys.TRACKING_NUMBER)}: {trackingNumber}
            <br />
            {getTranslation(TranslationKeys.SECRET_CODE)}: {secretCode}
            {/* <br />
            <br />
            {`${getTranslation(TranslationKeys.SUBMIT_REPORT_MESSAGE_HEADER3)} ${getTranslation(TranslationKeys.SUBMIT_REPORT_MESSAGE_HEADER4)}`} */}
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationModal;
