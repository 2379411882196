import { Attachment, IServerResponseSecretData } from '../interfaces';

interface FormData {
  referenceNumber?: string;
  code?: string;
  reporterFeedback?: string;
  recaptchaToken?: string | null;
}

export interface FormState {
  formData: FormData;
  formInputsValid: Record<string, boolean>;
  shouldValidate: boolean;
  isFormSubmitted: boolean;
  hasFetchError: boolean;
  submitError: string;
  attachments: Attachment[];
  isLoaderVisible: boolean;
  isLoaderSubmitVisible: boolean;
  isUploadingFiles: boolean;
  counterLoaderHelper: number;
  serverResponseSecretData: IServerResponseSecretData;
  isInformationModalActive: boolean;
  isConfirmationModalActive: boolean;
  recaptchaError: string | null;
  recaptchaToken: string | null;
}

type FormAction =
  | { type: 'SET_FORM_DATA'; payload: Partial<FormData> }
  | { type: 'SET_FORM_INPUTS_VALID'; payload: Record<string, boolean> }
  | { type: 'SET_SHOULD_VALIDATE'; payload: boolean }
  | { type: 'SET_IS_FORM_SUBMITTED'; payload: boolean }
  | { type: 'SET_HAS_FETCH_ERROR'; payload: boolean }
  | { type: 'SET_SUBMIT_ERROR'; payload: string }
  | { type: 'SET_ATTACHMENTS'; payload: Attachment[] }
  | { type: 'SET_IS_LOADER_VISIBLE'; payload: boolean }
  | { type: 'SET_IS_LOADER_SUBMIT_VISIBLE'; payload: boolean }
  | { type: 'SET_IS_UPLOADING_FILES'; payload: boolean }
  | { type: 'SET_COUNTER_LOADER_HELPER'; payload: number }
  | { type: 'SET_SERVER_RESPONSE_SECRET_DATA'; payload: IServerResponseSecretData }
  | { type: 'SET_IS_INFORMATION_MODAL_ACTIVE'; payload: boolean }
  | { type: 'SET_IS_CONFIRMATION_MODAL_ACTIVE'; payload: boolean }
  | { type: 'SET_RECAPTCHA_ERROR'; payload: string | null }
  | { type: 'SET_RECAPTCHA_TOKEN'; payload: string | null };

export const whistleBlowerFormReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case 'SET_FORM_INPUTS_VALID':
      return { ...state, formInputsValid: { ...state.formInputsValid, ...action.payload } };
    case 'SET_SHOULD_VALIDATE':
      return { ...state, shouldValidate: action.payload };
    case 'SET_IS_FORM_SUBMITTED':
      return { ...state, isFormSubmitted: action.payload };
    case 'SET_HAS_FETCH_ERROR':
      return { ...state, hasFetchError: action.payload };
    case 'SET_SUBMIT_ERROR':
      return { ...state, submitError: action.payload };
    case 'SET_ATTACHMENTS':
      return { ...state, attachments: action.payload };
    case 'SET_IS_LOADER_VISIBLE':
      return { ...state, isLoaderVisible: action.payload };
    case 'SET_IS_LOADER_SUBMIT_VISIBLE':
      return { ...state, isLoaderSubmitVisible: action.payload };
    case 'SET_IS_UPLOADING_FILES':
      return { ...state, isUploadingFiles: action.payload };
    case 'SET_COUNTER_LOADER_HELPER':
      return { ...state, counterLoaderHelper: action.payload };
    case 'SET_SERVER_RESPONSE_SECRET_DATA':
      return { ...state, serverResponseSecretData: action.payload };
    case 'SET_IS_INFORMATION_MODAL_ACTIVE':
      return { ...state, isInformationModalActive: action.payload };
    case 'SET_IS_CONFIRMATION_MODAL_ACTIVE':
      return { ...state, isConfirmationModalActive: action.payload };
    case 'SET_RECAPTCHA_ERROR':
      return { ...state, recaptchaError: action.payload };
    case 'SET_RECAPTCHA_TOKEN':
      return { ...state, recaptchaToken: action.payload };
    default:
      return state;
  }
};
