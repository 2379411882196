import { useState, useEffect } from 'react';

interface UseHandleScrollOptions {
  initialNavMode?: 'dark' | 'light';
}

interface UseHandleScrollReturn {
  navMode: 'dark' | 'light' | '';
  headerVisible: boolean;
}

export const useHandleScroll = ({ initialNavMode = 'dark' }: UseHandleScrollOptions = {}): UseHandleScrollReturn => {
  const [navMode, setMode] = useState<'dark' | 'light' | ''>(initialNavMode);
  const [headerVisible, setHeaderVisible] = useState<boolean>(true);

  useEffect(() => {
    let lastY = 0;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isAtTop = currentScrollY === 0;
      const isScrollingUp = currentScrollY < lastY;
      const isWithinLimit = currentScrollY <= 30;

      if (isAtTop) {
        setMode(initialNavMode); // Revert to the initial mode whilst at the top
      } else if (isScrollingUp && !isWithinLimit) {
        setMode('');
      }

      setHeaderVisible(isWithinLimit || isScrollingUp); // `header--visible` | `header--hidden`

      lastY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [initialNavMode]);

  return { navMode, headerVisible };
};
