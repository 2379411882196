import React from 'react';
import { FormContainer, IdentityInfo, StepBuilder, initFormState } from '@episerver/forms-sdk';
import { FormProvider } from '../context/FormProvider';
import { FormBody } from './FormBody';

export interface FormContainerProps {
  form: FormContainer;
  identityInfo?: IdentityInfo;
  baseUrl: string;
  history?: any;
  currentPageUrl?: string;
  formEndpoint?: string;
}

export function FormContainerBlock(props: FormContainerProps) {
  const { formEndpoint } = props;
  const stepBuilder = new StepBuilder(props.form);
  const form = stepBuilder.buildForm();
  const state = initFormState(form, props.currentPageUrl);

  {
    /* finally return the form */
  }
  return (
    <FormProvider initialState={state}>
      <FormBody
        identityInfo={props.identityInfo}
        baseUrl={props.baseUrl}
        history={props.history}
        currentPageUrl={props.currentPageUrl}
        formEndpoint={formEndpoint}
      />
    </FormProvider>
  );
}
