import { Textbox } from '@episerver/forms-sdk';
import React, { useEffect, useMemo, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ElementCaption, ValidationMessage } from './shared';
import Icon from '@components/Icon/Icon';
// import "flatpickr/dist/themes/dark.css";
import Flatpickr from 'react-flatpickr';
import { DispatchFunctions } from '@episerver/forms-react/context/dispatchFunctions';
import type { Locale } from 'node_modules/flatpickr/dist/types/locale.d.ts';

export interface TextboxElementBlockProps {
  element: Textbox;
}

export const DatePickerBlock = (props: TextboxElementBlockProps) => {
  const { element } = props;
  const dispatch = new DispatchFunctions();
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, elementRef, validatorClasses, extraAttr } = elementContext;
  const [date, setDate] = useState(new Date());
  const [minDate, setMinDate] = useState<string | Date | undefined>('today');
  useEffect(() => {
    if (element.properties.relation === 'datepicker_end') {
      const startDiv = document.querySelector('div[data-datepicker-type="datepicker_start"]');

      if (!startDiv) return;
      const config = { attributes: true, childList: false, subtree: false };

      const callback = function (mutationsList: any, observer: any) {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'data-datepicker-value') {
            const newStartDate = new Date(startDiv.getAttribute('data-datepicker-value') ?? 'today');
            setMinDate(newStartDate);
            if (date < newStartDate) setDate(newStartDate);
          }
        }
      };

      const observer = new MutationObserver(callback);

      if (startDiv) {
        observer.observe(startDiv, config);
      }

      return () => observer.disconnect();
    }
  }, [element.properties.relation, date]);

  return useMemo(() => {
    const handleOnChange = (date: Date) => {
      if (!date) {
        if (element.properties.validators[0].type === 'RequiredValidator') {
          dispatch.updateValidation(element.key, {
            valid: false,
            message: element.properties.validators[0].model.message,
          });
        }
        return;
      }
      dispatch.updateValidation(element.key, {
        valid: true,
        message: '',
      });
      const formattedDate = date.toDateString();
      setDate(date);
      const event = {
        target: {
          name: element.key,
          value: formattedDate,
          checked: false,
          type: 'text',
        },
      };

      handleChange(event);
    };

    const localization =
      element.locale === 'ar'
        ? {
            months: {
              shorthand: [
                'يناير',
                'فبراير',
                'مارس',
                'أبريل',
                'مايو',
                'يونيو',
                'يوليو',
                'أغسطس',
                'سبتمبر',
                'أكتوبر',
                'نوفمبر',
                'ديسمبر',
              ],
              longhand: [
                'يناير',
                'فبراير',
                'مارس',
                'أبريل',
                'مايو',
                'يونيو',
                'يوليو',
                'أغسطس',
                'سبتمبر',
                'أكتوبر',
                'نوفمبر',
                'ديسمبر',
              ],
            },
          }
        : {
            months: {
              shorthand: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              longhand: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
            },
          };
    return (
      <div
        data-datepicker-type={element.properties.relation}
        data-datepicker-value={date}
      >
        <ElementWrapper
          className={`FormTextbox ${validatorClasses}`}
          validationResults={validationResults}
          isVisible={isVisible}
        >
          <ElementCaption
            element={element}
            className="filled"
          />

          <Flatpickr
            data-disabled-time
            options={{
              disableMobile: true,
              mode: 'single',
              locale: localization as Locale,
              altInput: true,
              altFormat: 'd - F - Y',
              dateFormat: 'd - F - Y',
              defaultDate: 'today',
              minDate: minDate ?? 'today',
              static: true,
              nextArrow:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 11"><path fill-rule="evenodd" d="M11.735 4.813L8.034.78c-.341-.372-.91-.372-1.25 0-.341.371-.341.991 0 1.363l2.184 2.396H.884c-.492 0-.884.427-.884.964 0 .536.392.963.884.963h8.084L6.783 8.848c-.34.371-.34.991 0 1.363.177.192.404.289.632.289.227 0 .455-.096.631-.29l3.689-4.02c.164-.179.265-.427.265-.688 0-.262-.088-.51-.265-.689z"/></svg>',
              prevArrow:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 11"><path fill-rule="evenodd" d="M.265 4.813L3.966.78c.341-.372.91-.372 1.25 0 .341.371.341.991 0 1.363L3.032 4.538h8.084c.492 0 .884.427.884.964 0 .536-.392.963-.884.963H3.032l2.185 2.383c.34.371.34.991 0 1.363-.177.192-.404.289-.632.289-.227 0-.455-.096-.631-.29L.265 6.19C.101 6.011 0 5.763 0 5.502c0-.262.088-.51.265-.689z"/></svg>',
            }}
            value={date}
            onChange={([date]) => {
              handleOnChange(date);
            }}
          />
          <ValidationMessage
            element={element}
            validationResults={validationResults}
          />

          <Icon.ChevronDownIcon className={`iconChevron`} />
        </ElementWrapper>
      </div>
    );
  }, [validatorClasses, validationResults, isVisible, element, value, date, handleChange]);
};
