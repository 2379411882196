import React from 'react';
import { IHeroBanner, IRichtextBlock } from '@interfaces/ICommon';
import Hero from '@components/Hero/Hero';
import FixedButton from '@components/FixedButton/FixedButton';
import { renderContentByType } from '@helpers/renderContentByType';
import useUUID from '@hooks/useUUID';

export interface IContentPageLayout {
  LeasingInquiry: any;
  Date: string;
  Title: string;
  Subtitle: string;
  Section: string;
  ComponentsArea: IComponentsArea[];
  PreviewImage: {
    __typename: string;
    Url: string;
  };
  Content: [
    {
      ContentLink: {
        Expanded: IRichtextBlock;
      };
    },
  ];
  MetaTitle: string;
  MetaKeywords: string[];
  MetaDescription: string;
  HeroBanner: {
    ContentLink: {
      Expanded: IHeroBanner;
    };
  };
  Classname: string;
}

export interface IComponentsArea {
  ContentLink: {
    Expanded: {
      Loadmore?: string;
      Align?: string;
      Title?: string;
      Classname?: string;
      [x: string]: any;
      Video: any;
      Image: any;
      Tabs: ITabs[];
      Config: string;
      __typename: string;
      Editor?: {
        Structure: string;
      };
      Items?: any[];
    };
  };
}

export interface ITabs {
  ContentLink: {
    Expanded: {
      Content: {
        ContentLink: {
          Expanded: { Editor: { Structure: string; __typename: string }; __typename: string };
          __typename: string;
        };
        __typename: string;
      }[];
      Title: string;
      __typename: string;
    };
    __typename: string;
  };
  __typename: string;
}

const ContentPageLayout = ({ data }: { data: IContentPageLayout }) => {
  const heroBannerData: IHeroBanner = data?.HeroBanner?.ContentLink.Expanded;
  const leasingInquiry = data?.LeasingInquiry?.ContentLink.Expanded;
  const content = data?.ComponentsArea;
  const heroKeys = useUUID(content?.length);
  return (
    <>
      <Hero heroBannerData={heroBannerData} />
      <section className={`kafd-main-sec kafd-container ${data?.Classname}`}>
        <div>
          {Array.isArray(content) &&
            content.map((item, index) => (
              <div
                className='kafd-section'
                key={heroKeys[index]}
              >
                {renderContentByType(item)}
              </div>
            ))}
        </div>
        {leasingInquiry && leasingInquiry.Url && (
          <FixedButton url={leasingInquiry?.Url}>{leasingInquiry?.Title}</FixedButton>
        )}
      </section>
    </>
  );
};

export default ContentPageLayout;
