import { useState } from 'react';
import { useData } from '@context/GraphQLDataContext';
import { useRouter } from 'next/router';
import { useHandleMagicLine } from '@hooks/useHandleMagicLine';
import { useHandleScroll } from '@hooks/useHandleScroll';
import * as styles from './Header.module.scss';
import Icon from '@components/Icon/Icon';
import SearchBar from '@components/SearchBar/SearchBar';
import Portal from '@components/Portal/Portal';
import { DetermineLanguage } from '@helpers/determineLanguage';
import useUUID from '@hooks/useUUID';
import DesktopSubMenuList from './DesktopSubMenuList';

interface INavProps {
  headerMode: 'dark' | 'light';
}

const NavDesktop: React.FC<INavProps> = ({ headerMode }) => {
  const { navMode, headerVisible } = useHandleScroll({ initialNavMode: headerMode });
  const { magicLineStyle, ref } = useHandleMagicLine();
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
  const router = useRouter();
  const { translate } = useData();
  const [query, setQuery] = useState('');

  const handleLanguageChange = () => {
    const currentPath = router.asPath;
    const newLanguageCode = currentLanguage === 'ar' ? 'en' : 'ar';
    let newPath;
    if (/^\/(en|ar)(\/|$)/.test(currentPath)) {
      newPath = currentPath.replace(/^\/(en|ar)/, `/${newLanguageCode}`);
    } else {
      newPath = `/${newLanguageCode}${currentPath}`;
    }
    window.location.href = newPath;
  };

  const getHeaderModeClass = (headerMode: 'dark' | 'light' | '') => styles[`nav__${headerMode}`] || '';

  const handleClose = (arg: boolean) => setIsOpenSearch(arg);
  const headerMenuData = useData()?.data?.headerMenu;
  const headerMenuDataKeys = useUUID(headerMenuData?.length);
  const currentPathname = useRouter().asPath;
  const currentLanguage = DetermineLanguage();

  function normalizePath(url: string, trimSlash?: boolean) {
    if (!url) return '';
    try {
      const urlObj = new URL(url);
      let normalizedPath = urlObj.pathname;
      if (trimSlash) {
        normalizedPath =
          normalizedPath.endsWith('/') && normalizedPath.length > 1 ? normalizedPath.slice(0, -1) : normalizedPath;
      }
      return normalizedPath;
    } catch (error) {
      console.error('Error normalizing URL:', error);
      return '';
    }
  }

  return (
    <div
      className={`${styles['d-top-nav']} js_header_stick js-nav-theme
      ${headerVisible ? styles['header--showing'] : styles['header--hidden']} 
      ${getHeaderModeClass(navMode)}`}
      data-comp-name='topNav'
    >
      <div className={styles['nav-inner-wrapper']}>
        {/* Left logo */}
        <div className={`${styles['d-logo']}`}>
          <a
            href={`/${currentLanguage}`}
            className='js-nav-theme'
            target='_self'
          >
            <span className={`${styles['icon']}`}>
              <Icon.KafdLogoIcon className={`${styles['logoicon']}  replaced-svg`} />
            </span>
            <span className='h-full hidden'>Logo</span>
          </a>
        </div>

        {/* Menu items */}
        <div className={styles['links-wrapper']}>
          <ul
            className={styles['nav-links']}
            role='navigation'
            data-hook
          >
            {headerMenuData &&
              Array.isArray(headerMenuData) &&
              headerMenuData.map((menuItem: any, menuIndex: number) => {
                const hasMenuBlock = menuItem.ContentLink.Expanded.__typename === 'MenuBlock';
                const isActiveMenuItem = normalizePath(menuItem.ContentLink.Expanded.Url) === currentPathname;

                return (
                  <li
                    key={headerMenuDataKeys[menuIndex]}
                    className={`nav-links-item uppercase ${isActiveMenuItem ? 'active-page' : ''} ${hasMenuBlock ? styles['has-submenu'] : ''}`}
                  >
                    {/* 1st (top) level menu items */}
                    {!hasMenuBlock ? (
                      <a
                        href={normalizePath(menuItem.ContentLink.Expanded.Url, true)}
                        className={`${styles['submenu-link']} `}
                      >
                        <span className='nav-links-text '>{menuItem.ContentLink.Expanded.Label}</span>
                      </a>
                    ) : (
                      <a className={`${styles['submenu-link']} ${styles['submenu-link--rtl']}`}>
                        <span className='nav-links-text'>{menuItem.children[0].Title}</span>
                        <Icon.ChevronDownIcon className={`${styles['chevron-bottom']} lozad  replaced-svg`} />
                      </a>
                    )}

                    {/* 2nd level menu items (submenus) */}
                    {hasMenuBlock && (
                      <div className={`${styles['submenu']}`}>
                        <ul className={`${styles['secondary-links']} js-submenu-onclick`}>
                          {Array.isArray(menuItem.children[0].Items) && <DesktopSubMenuList menuItem={menuItem} />}
                        </ul>
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>

          {/* Search icon */}
          <a
            className={`${styles['d-search-icon']} js_search_trigger_btn`}
            target='_self'
            onClick={(event) => {
              event.preventDefault();
              setIsOpenSearch(true);
            }}
          >
            <span className={`${styles['icon']}  replaced-svg`}>
              <Icon.IcoSearchIcon className={`${styles['svgicon']}`} />
            </span>
            <span className='js-top-nav js-new-item h-full hidden'>{translate('search')}</span>
          </a>

          {isOpenSearch && (
            <Portal target='search'>
              <SearchBar
                close={handleClose}
                query={query}
                setQuery={setQuery}
              />
            </Portal>
          )}

          {/* Language switcher */}
          <a
            onClick={handleLanguageChange}
            className={`${styles['d-lang']} js-site-lang`}
          >
            <span className={`${styles['lang-text']}`}>{currentLanguage === 'ar' ? 'English' : 'عربى'}</span>
          </a>

          {/* Magic line */}
          <div
            className={`${styles['d-magic-line']}`}
            style={magicLineStyle}
            ref={ref}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default NavDesktop;
