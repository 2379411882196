export const setCookie = (name: string, value: any, mins = 60, hours = 8760) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + hours * mins * 60 * 1000);

  document.cookie = `${name}=${value}; expires=" + ${expirationDate.toUTCString()} + "; path=/`;
};

export const getCookie = (item: string) => {
  if (typeof document !== 'undefined' && document.cookie) {
    const cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i += 1) {
      const name = cookieArray[i].split('=')[0];
      const value = cookieArray[i].split('=')[1];

      if (name.trim() === `${item}`.trim()) {
        return value;
      }
    }
  }

  return undefined;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
