import { useState, useEffect, useRef, useCallback } from 'react';

interface MagicLineStyle {
  left?: number;
  width?: number;
  right?: number;
}

export const useHandleMagicLine = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [magicLineStyle, setMagicLineStyle] = useState<MagicLineStyle>({});
  const [isRTL, setIsRTL] = useState(false);

  const calculateAndSetLinePosition = useCallback(
    (targetElement: HTMLElement | null) => {
      const navLinksContainer = ref.current?.parentElement;
      if (targetElement && navLinksContainer) {
        const containerRect = navLinksContainer.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();
        const navLinksTextElement = targetElement.querySelector('.nav-links-text');
        const newWidth =
          navLinksTextElement instanceof HTMLElement ? navLinksTextElement.offsetWidth : targetElement.offsetWidth;

        if (isRTL) {
          const rightPos = containerRect.right - targetRect.right + 15;
          setMagicLineStyle({
            right: rightPos,
            width: newWidth,
          });
        } else {
          const leftPos = targetRect.left - containerRect.left + 15;
          setMagicLineStyle({
            left: leftPos,
            width: newWidth,
          });
        }
      } else {
        if (isRTL) {
          setMagicLineStyle({ right: 0, width: 0 });
        } else {
          setMagicLineStyle({ left: 0, width: 0 });
        }
      }
    },
    [isRTL]
  );

  useEffect(() => {
    setIsRTL(document.documentElement.getAttribute('dir') === 'rtl');

    // Update the magic line position initially (after .active-page has been added to the DOM)
    const updateMagicLinePositionInitially = () => {
      const activeNavItem = document.querySelector('.nav-links-item.active-page');
      if (activeNavItem) {
        calculateAndSetLinePosition(activeNavItem as HTMLElement);
      }
    };

    const initialTimeoutId = setTimeout(updateMagicLinePositionInitially, 600);

    return () => clearTimeout(initialTimeoutId);
  }, [calculateAndSetLinePosition]);

  useEffect(() => {
    const activeNavItem = document.querySelector('.nav-links-item.active-page');
    calculateAndSetLinePosition(activeNavItem as HTMLElement);

    const handleMouseEnter = (e: Event) => {
      const target = e.target as HTMLElement;
      calculateAndSetLinePosition(target);
    };

    const handleMouseLeave = () => {
      const activeNavItem = document.querySelector('.nav-links-item.active-page');
      if (activeNavItem) {
        calculateAndSetLinePosition(activeNavItem as HTMLElement);
      } else {
        // Optionally, reset the line if no active item is found, based on direction
        if (isRTL) {
          setMagicLineStyle({ right: 0, width: 0 });
        } else {
          setMagicLineStyle({ left: 0, width: 0 });
        }
      }
    };

    setTimeout(() => {
      document.querySelectorAll('.nav-links-item').forEach((item) => {
        item.addEventListener('mouseenter', handleMouseEnter);
        item.addEventListener('mouseleave', handleMouseLeave);
      });
    }, 600);

    return () => {
      document.querySelectorAll('.nav-links-item').forEach((item) => {
        item.removeEventListener('mouseenter', handleMouseEnter);
        item.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [isRTL, calculateAndSetLinePosition]);

  return { magicLineStyle, ref };
};
