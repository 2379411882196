import { SubmitButton, isNullOrEmpty } from '@episerver/forms-sdk';
import React, { useMemo } from 'react';
import Image from '@components/common/htmlTags/Image/Image';
import { useElement } from '../../hooks/useElement';

interface SubmitButtonElementBlockProps {
  element: SubmitButton;
}

export const SubmitButtonElementBlock = (props: SubmitButtonElementBlockProps) => {
  const { element } = props;
  const { elementContext } = useElement(element);
  const { isVisible, extraAttr, validatorClasses, elementRef } = elementContext;

  const buttonDisableState = false;

  return useMemo(
    () =>
      isVisible && (
        <>
          <button
            id={element.key}
            name="submit"
            type="submit"
            value={element.key}
            {...extraAttr}
            disabled={buttonDisableState}
            className={
              isNullOrEmpty(element.properties.image)
                ? `Form__Element FormExcludeDataRebind FormSubmitButton ${validatorClasses}`
                : `Form__Element FormExcludeDataRebind FormSubmitButton FormImageSubmitButton ${validatorClasses}`
            }
            ref={elementRef}
          >
            {isNullOrEmpty(element.properties.image) ? (
              element.properties.label
            ) : (
              <Image
                url={element.properties.image}
                alt={element.properties.label ?? ''}
                width={100}
                height={50}
                layout="fixed"
              />
            )}
          </button>
        </>
      ),
    [
      isVisible,
      element.key,
      element.properties.image,
      element.properties.label,
      extraAttr,
      buttonDisableState,
      validatorClasses,
      elementRef,
    ]
  );
};
