import MobileSubSubMenuList from './MobileSubSubMenuList';
import React from 'react';
import * as styles from './Header.module.scss';
import useUUID from '@hooks/useUUID';
import { trimUrl } from '@helpers/url.helper';
import Icon from '@components/Icon/Icon';

const MobileSubMenuList: React.FC<any> = ({ menuItem, activeSubmenu, toggleSubmenu }) => {
  const subMenuItems = menuItem.children[0].Items;
  const subMenuItemsKeys = useUUID(subMenuItems.length);

  return subMenuItems.map((subMenuItem: any, subMenuIndex: number) => {
    const hasInnerSubmenu = subMenuItem.ContentLink?.Expanded?.__typename === 'MenuBlock';
    const isActiveSubmenu = activeSubmenu === subMenuItem.ContentLink.Expanded.Classname;
    const fancyUnderlineClass = styles[subMenuItem.ContentLink.Expanded.Classname] || '';

    return (
      <li
        key={subMenuItemsKeys[subMenuIndex]}
        className={`${fancyUnderlineClass} 
                      ${styles['link']} 
                      ${isActiveSubmenu ? styles['active-submenu'] : ''}
                      ${fancyUnderlineClass ? 'uppercase' : ''}`}
      >
        {hasInnerSubmenu ? (
          <a
            href={trimUrl(subMenuItem.ContentLink?.Expanded?.Url ?? '#')}
            target='_self'
            className={`${styles['macnhor']}`}
            onClick={(e) => toggleSubmenu(e, subMenuItem.ContentLink.Expanded.Classname)}
          >
            <span className={`${styles['title']} uppercase`}>{subMenuItem.ContentLink.Expanded.Title}</span>
            <Icon.ChevronRightIcon className={`${styles['chevron-right']} lozad js-change-svg replaced-svg`} />
          </a>
        ) : (
          <a
            href={trimUrl(subMenuItem.ContentLink?.Expanded?.Url ?? '#')}
            target='_self'
            className={`${styles['macnhor']}`}
          >
            <span className={`${styles['title']}`}>{subMenuItem.ContentLink.Expanded.Label}</span>
          </a>
        )}

        {/* Generating inner submenus */}
        {hasInnerSubmenu && (
          <div className={`${styles['m-menu-innercontent']}`}>
            <a
              className={`${styles['backbutton']} js-backmenustep`}
              onClick={(e) => {
                e.preventDefault();
                toggleSubmenu(e, subMenuItem.ContentLink.Expanded.Classname);
              }}
              target='_self'
            >
              <div className={`${styles['big-pattern']}`}>
                <Icon.BigPattern />
              </div>
              <span className={`${styles['title']}`}>{subMenuItem.ContentLink.Expanded.Title}</span>
              <span className={`${styles['icon']}`}>
                <Icon.ChevronLeftIcon className={`${styles['svgicon']} js-change-svg replaced-svg`} />
              </span>
            </a>
            <div className={`${styles['m-secondarylinks-wrap']}`}>
              <ul className={`${styles['sub-links']}`}>
                {Array.isArray(subMenuItem.ContentLink.Expanded.Items) && (
                  <MobileSubSubMenuList subMenuItem={subMenuItem} />
                )}
              </ul>
            </div>
          </div>
        )}
      </li>
    );
  });
};
export default React.memo(MobileSubMenuList);
