import React from 'react';
import RichTextComponent from '@components/RichText/RichText';
import { IHeroBanner, IRichtextBlock } from '@interfaces/ICommon';
import Hero from '@components/Hero/Hero';
import { useRouter } from 'next/router';

export interface IArticlePageLayout {
  Date: string;
  Title: string;
  PreviewSubtitle: string;
  Subtitle: string;
  Section: string;
  Classname: string;
  PreviewImage: {
    __typename: string;
    Url: string;
  };
  Content: [
    {
      ContentLink: {
        Expanded: IRichtextBlock;
      };
    },
  ];
  MetaTitle: string;
  MetaKeywords: string[];
  MetaDescription: string;
  HeroBanner: {
    ContentLink: {
      Expanded: IHeroBanner;
    };
  };
}

const ArticlePageLayout = ({ data }: { data: IArticlePageLayout }) => {
  const heroBannerData: IHeroBanner = data?.HeroBanner.ContentLink.Expanded;
  const fullDate = new Date(data?.Date);
  const year = fullDate.toLocaleDateString('en-US', { year: 'numeric' });
  const month = fullDate.toLocaleDateString('en-US', { month: 'numeric' }).padStart(2, '0');
  const day = fullDate.toLocaleDateString('en-US', { day: 'numeric' }).padStart(2, '0');
  const language = useRouter().asPath.split('/')[1] === 'ar' ? 'ar' : 'en';

  return (
    <>
      <section className={`kafd-main-sec kafd-container articlePage ${data?.Classname}`}>
        <Hero heroBannerData={heroBannerData} />

        <div className='kafd-section col-layout'>
          <div
            className='flex flex-wrap lozad animate-section'
            data-toggle-class='animate-section'
            data-loaded='true'
          >
            <div className='offset-1 col-12 offset-md-0 col-md-12 offset-sm-0 col-sm-12'>
              <div className='offset-0 col-12 offset-md-0 col-md-12 offset-sm-0 col-sm-12'>
                <div className='contenteditor '>
                  <small>{`${day}-${month}-${year}`}</small>
                  <h1>{data?.Title}</h1>
                  {language === 'ar' && (
                    <span
                      style={{
                        lineHeight: '107%',
                      }}
                    >
                      {data?.PreviewSubtitle}
                    </span>
                  )}
                </div>
              </div>

              <div className='offset-0 col-12 offset-md-0 col-md-12 offset-sm-0 col-sm-12'>
                <div className='contenteditor'>
                  <RichTextComponent
                    data={data?.Content[0]?.ContentLink.Expanded.Editor.Structure}
                    className={data?.Content[0]?.ContentLink.Expanded?.Classname}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticlePageLayout;
