export function normalizePath(url: string, trimSlash?: boolean) {
  if (!url) return '';
  try {
    const urlObj = new URL(url);
    let normalizedPath = urlObj.pathname;
    if (trimSlash) {
      normalizedPath =
        normalizedPath.endsWith('/') && normalizedPath.length > 1 ? normalizedPath.slice(0, -1) : normalizedPath;
    }
    return normalizedPath;
  } catch (error) {
    console.error('Error normalizing URL:', error);
    return '';
  }
}
