import { useRouter } from 'next/router';

export const DetermineLanguage = () => {
  try {
    const router = useRouter();
    return router.asPath.split('/')[1] ?? 'en';
  } catch (e) {
    return 'en';
  }
};
