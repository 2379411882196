import { Form } from '@episerver/forms-react';
import { DetermineLanguage } from '@helpers/determineLanguage';

interface IFormContainerBlockProps {
  __typename?: string;
  GuidValue: string;
  formEndpoint?: string;
}

const FormContainerBlock: React.FC<IFormContainerBlockProps> = ({ GuidValue, formEndpoint }) => {
  const language = DetermineLanguage();
  return (
    <div>
      <Form
        formKey={GuidValue?.replaceAll('-', '')}
        baseUrl={'/'}
        language={language || 'en'}
        formEndpoint={formEndpoint}
      />
    </div>
  );
};

export default FormContainerBlock;
