import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './Heading6.module.scss';

interface IHeading6 {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Heading6 Component
 *
 *A component representing the <h6>{children}</h6>.
 *
 * @component
 * @param {Object} prop - The props for the Heading6 component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <h6></h6>.
 * @returns {JSX.Element} The rendered JSX element representing the <h6>{children}</h6>.
 *
 * @example
 *  <Heading6 className={['test1', 'test2', 'test3']}>TEST</Heading6>
 */

const Heading6 = (prop: IHeading6): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <h6
      className={clsx(styles.heading6, className)}
      style={style}
    >
      {children}
    </h6>
  );
};

export default Heading6;
