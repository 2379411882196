import { FC, useState, useEffect, MouseEvent } from 'react';
import Portal from '@components/Portal/Portal';
import { getCookie, setCookie } from '@helpers/cookie.helper';
import * as styles from './CookieBar.module.scss';
import { useData } from '@context/GraphQLDataContext';
import clsx from 'clsx';

const CookieBar: FC = () => {
  const [showCookieBar, setShowCookieBar] = useState<boolean>(true);
  const [domReady, setDomReady] = useState<boolean>(false);
  const isConfirmed = getCookie('cookie-policy');
  const { translate } = useData();

  const handleClick = (event: MouseEvent<HTMLButtonElement>, reject = false) => {
    setShowCookieBar(false);

    if (reject) {
      setCookie('cookie-policy', 1, 15, 1);
      return;
    }

    setCookie('cookie-policy', 1);
  };

  useEffect(() => {
    setDomReady(true);
  }, []);

  const isOpened = !isConfirmed && showCookieBar && domReady;

  return (
    <>
      {isOpened && (
        <Portal target='cookie-bar'>
          <div className={styles.cookieWrapper}>
            <div className={styles.cookieWrapperContent}>
              <div dangerouslySetInnerHTML={{ __html: translate('cookie-text') }} />
              <div className={styles.btnWrapper}>
                <button
                  type='button'
                  onClick={handleClick}
                  className={clsx(styles.btn, styles.btnWithBg)}
                >
                  {translate('accept')}
                </button>
                <button
                  type='button'
                  onClick={(event) => handleClick(event, true)}
                  className={clsx(styles.btn, styles.btnWithBorders)}
                >
                  {translate('reject')}
                </button>
              </div>
            </div>
          </div>
        </Portal>
      )}
      <div id='cookie-bar' />
    </>
  );
};

export default CookieBar;
