function prepareUrlHelper(baseUrls: string[]) {
  const bases = baseUrls.map((url) => new URL(url));

  return (urlToCheck: string): string => {
    if (!urlToCheck) return '#';
    if (!urlToCheck.startsWith('http')) {
      return urlToCheck.endsWith('/') ? urlToCheck.slice(0, -1) : urlToCheck;
    }
    try {
      const url = new URL(urlToCheck);
      if (bases.some((base) => base.origin === url.origin)) {
        const path = url.pathname + url.search + url.hash;
        return path.endsWith('/') ? path.slice(0, -1) : path;
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
    }
    return urlToCheck;
  };
}

const baseUrls = [
  'https://kafd-backend.azurewebsites.net',
  'https://kafd-fe.azurewebsites.net',
  'https://kafd03mstr9p35zprod.dxcloud.episerver.net',
  'https://kafd03mstr9p35zinte.dxcloud.episerver.net',
];

const trimUrl = prepareUrlHelper(baseUrls);
const trimImageUrl = prepareUrlHelper(baseUrls);

export { trimUrl, trimImageUrl };
