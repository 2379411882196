export interface ILazyGalleryState {
  isModalOpen: boolean;
  isLoading: boolean;
  modal: { [key: string]: string };
  imagesLoaded: boolean[];
  visibleCount: number;
}

type LazyGalleryAction =
  | { type: 'SET_IS_MODAL_OPEN'; payload: boolean }
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_MODAL'; payload: { [key: string]: string } }
  | { type: 'SET_VISIBLE_COUNT'; payload: number };

export const LazyGalleryReducer = (state: ILazyGalleryState, action: LazyGalleryAction): ILazyGalleryState => {
  switch (action.type) {
    case 'SET_IS_MODAL_OPEN':
      return { ...state, isModalOpen: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_MODAL':
      return { ...state, modal: action.payload };
    case 'SET_VISIBLE_COUNT':
      return { ...state, visibleCount: action.payload };
    default:
      return state;
  }
};
