import { TextboxElementBlock } from './TextboxElementBlock';
import { TextareaElementBlock } from './TextareaElementBlock';
import { NumberElementBlock } from './NumberElementBlock';
import { PredefinedHiddenElementBlock } from './PredefinedHiddenElementBlock';
import { ChoiceElementBlock } from './ChoiceElementBlock';
import { RangeElementBlock } from './RangeElementBlock';
import { FormStepBlock } from './FormStepBlock';
import { SelectionElementBlock } from './SelectionElementBlock';
import { UrlElementBlock } from './UrlElementBlock';
import { FileUploadElementBlock } from './FileUploadElementBlock';
import { ResetButtonElementBlock } from './ResetButtonElementBlock';
import { SubmitButtonElementBlock } from './SubmitButtonElementBlock';
import { ParagraphTextElementBlock } from './ParagraphTextElementBlock';
import { DatePickerBlock } from './DatePickerBlock';
import { PhoneElementBlock } from './PhoneElementBlock';
import { RecaptchaElementBlock } from './RecaptchaElementBlock';
import { NumberLimitElementBlock } from './NumberLimitElementBlock';

export const components: Record<string, any> = {
  TextboxElementBlock,
  TextareaElementBlock,
  PredefinedHiddenElementBlock,
  ChoiceElementBlock,
  NumberElementBlock,
  RangeElementBlock,
  FormStepBlock,
  SelectionElementBlock,
  UrlElementBlock,
  FileUploadElementBlock,
  ResetButtonElementBlock,
  SubmitButtonElementBlock,
  ParagraphTextElementBlock,
  DatePickerBlock,
  PhoneElementBlock,
  RecaptchaElementBlock,
  NumberLimitElementBlock,
};
