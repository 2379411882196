import { Textbox } from '@episerver/forms-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ElementWrapper from './shared/ElementWrapper';
import { useElement } from '../../hooks/useElement';
import { ElementCaption, ValidationMessage, DataList } from './shared';
import Icon from '@components/Icon/Icon';
import codes from './shared/codes.json';
import { useData } from '@context/GraphQLDataContext';
import { useOutsideAlerter } from '@hooks/useOutsideAlerter';
import { DetermineLanguage } from '@helpers/determineLanguage';
import { HTMLInputElementCustom, HTMLTextAreaElementCustom } from '@components/WhistleBlowerForm/utils/interfaces';
import { DispatchFunctions } from '@episerver/forms-react/context/dispatchFunctions';

export interface TextboxElementBlockProps {
  element: Textbox;
}

export const PhoneElementBlock = (props: TextboxElementBlockProps) => {
  const { element } = props;
  const { elementContext, handleChange, handleBlur } = useElement(element);
  const { isVisible, validationResults, value, elementRef, validatorClasses, extraAttr } = elementContext;
  const lang = DetermineLanguage();
  const codesWithLang = codes.map(({ value, label }) => ({ value: value, label: (label as any)[lang] }));
  const [phoneCodes, setPhoneCodes] = useState(() => [...codesWithLang]);
  const [code, setCode] = useState(() => codesWithLang.filter(({ value }) => value === '+966')[0]);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [phone, setPhone] = useState<string | undefined>('');
  const [isOpen, setIsOpen] = useState(false);
  const [finalValue, setFinalValue] = useState(value);

  const dispatch = new DispatchFunctions();
  const { translate } = useData();

  const [isFocused, setIsFocused] = useState(false);

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    setPhone('');
    setFinalValue('');
  };
  let message = element.properties.validators[0]?.model.message;
  const isValueValid = () => {
    if (finalValue === '') {
      message = element.properties.validators[0]?.model.message;
      return false;
    } else if (/^[+0-9]{10,13}$/.test(finalValue)) {
      message = '';
      return true;
    } else {
      message = translate('form-error-phone-number');
      return false;
    }
  };

  const onBlurHandler = (e: any) => {
    if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsFocused(false);
      if (isValueValid()) {
        handleBlur(e);
      }
    }, 100);
  };

  const phoneElementRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter<HTMLDivElement>([phoneElementRef], () => {
    setIsOpen(false);
  });

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (isOpen) (document.querySelector('.selectListSearch') as HTMLElement).focus();
  }, [isOpen]);

  useEffect(() => {
    if (isFocused && element.properties.validators[0]?.type === 'RequiredValidator') {
      if (isValueValid()) {
        dispatch.updateValidation(element.key, {
          valid: true,
          message,
        });
      } else {
        dispatch.updateValidation(element.key, {
          valid: false,
          message,
        });
      }
    }

    handleChange({
      target: {
        name: element.key,
        value: finalValue,
        checked: false,
        type: 'text',
      },
    });
  }, [finalValue]);

  return useMemo(() => {
    const handleOptionClick = (item: { value: string; label: string }) => {
      setCode(item);
      setIsOpen(false);
    };
    const handleKeyDown = (event: any) => {
      if (
        !event.key.match(/[0-9]/) &&
        event.key !== 'Backspace' &&
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight'
      ) {
        event.preventDefault();
      }
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newPhoneValue = event.target.value;
      setPhone(newPhoneValue);
      if (/^[0-9]*$/.test(newPhoneValue)) {
        const currentValue = code.value + newPhoneValue;
        if (newPhoneValue) {
          setFinalValue(currentValue);
        } else {
          setFinalValue('');
        }
      }
    };

    const toggleDropdown = (e: React.SyntheticEvent<EventTarget>): void => {
      if (e.target instanceof Element) {
        if (e.target.className == 'selectListSelectedValue') {
          setIsOpen(!isOpen);
          (document.getElementsByClassName('selectListSearch')[0] as HTMLInputElement).value = '';
          setPhoneCodes(codesWithLang);
        }
      }
    };

    return (
      <div
        className='PhoneElementBlock'
        ref={phoneElementRef}
      >
        <div
          className={`select-display codes ${isOpen ? 'open' : ''}`}
          onClick={toggleDropdown}
        >
          <span className='selectListSelectedValue'>{code.value}</span>
          <div className='selectListWrapper'>
            <input
              className='selectListSearch'
              type='text'
              onChange={(element) => {
                const filteredCodes = codesWithLang.filter(
                  (item) => item.label.toLocaleLowerCase().indexOf(element.target.value.toLocaleLowerCase()) > -1
                );
                setPhoneCodes(filteredCodes);
                if (filteredCodes.length > 0) setCode(filteredCodes[0]);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOptionClick(code);
                }
              }}
            />
          </div>
          <label className={`Form__Element__Caption filled country-code`}>{translate('country-code')}</label>
          <ul className={`selectList ${isOpen ? 'open' : ''}`}>
            {phoneCodes && phoneCodes.length > 0 ? (
              phoneCodes.map((item, index) => (
                <li
                  className={`selectOption ${code.value === item.value ? 'selected' : ''}`}
                  key={index}
                  onClick={() => handleOptionClick(item)}
                >
                  {item.label}
                </li>
              ))
            ) : (
              <li className='noResults'>
                <span>No results found</span>
              </li>
            )}
          </ul>
          <Icon.ChevronDownIcon className={`iconChevron iconChevronActive`} />
        </div>
        <ElementWrapper
          className={`FormTextbox ${validatorClasses}`}
          validationResults={validationResults}
          isVisible={isVisible}
        >
          <ElementCaption
            element={element}
            className={value ? 'filled textInputLabel' : 'textInputLabel'}
          />

          <input
            name={element.key}
            id={element.key}
            type='tel'
            className='FormTextbox__Input'
            aria-describedby={`${element.key}_desc`}
            placeholder={element.properties.placeHolder}
            autoComplete={element.properties.autoComplete}
            value={phone}
            {...extraAttr}
            onChange={handlePhoneChange}
            ref={elementRef}
            onKeyDown={handleKeyDown}
            onBlur={onBlurHandler}
            onFocus={() => setIsFocused(true)}
          />

          <ValidationMessage
            element={element}
            validationResults={validationResults}
          />

          {isFocused ? (
            <Icon.CloseModalIcon
              onClick={handleClearInput}
              onMouseDown={handleClearInput}
              onTouchEnd={handleClearInput}
              className='IconClose IconCloseVisible'
            />
          ) : value && validationResults.result.valid && !isFocused ? (
            <Icon.Tick className='TickIcon' />
          ) : (
            <Icon.CloseModalIcon
              onClick={handleClearInput}
              onMouseDown={handleClearInput}
              onTouchEnd={handleClearInput}
              className='IconClose IconCloseVisible'
            />
          )}
          <DataList element={element} />
        </ElementWrapper>
      </div>
    );
  }, [isVisible, validationResults, finalValue, value, phoneCodes, isOpen]);
};
