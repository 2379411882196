import HeroContent from './HeroContent/HeroContent';
import HeroArticle from './HeroArticle/HeroArticle';
import HeroFormSuccess from './HeroFormSuccess/HeroFormSuccess';
import { IHeroBanner } from '@interfaces/ICommon';

/**
 * `Hero` dynamically displays a hero section tailored to the banner type.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {IHeroBanner} props.heroBannerData - Data for the hero banner, including image or
 * video URLs, title, and subtitle.
 * @returns A `HeroContent`, `HeroArticle` or `HeroFormSuccess` component based on banner type.
 */

interface HeroProps {
  heroBannerData?: IHeroBanner;
}

const Hero: React.FC<HeroProps> = ({ heroBannerData }) => {
  const isDefined = heroBannerData !== undefined && heroBannerData !== null;
  const hasImageUrl = isDefined && heroBannerData.Image?.Url !== undefined;
  const hasVideoUrl = isDefined && heroBannerData.Video?.Url !== undefined;
  if (!isDefined || (!hasImageUrl && !hasVideoUrl)) {
    return null;
  }
  switch (heroBannerData.Type) {
    case 'article_page':
      return <HeroArticle heroBannerData={heroBannerData} />;
    case 'success_page':
      return <HeroFormSuccess heroBannerData={heroBannerData} />;
    default:
      return <HeroContent heroBannerData={heroBannerData} />;
  }
};

export default Hero;
