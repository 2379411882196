import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { IConfigPage } from '@interfaces/IConfigPage';
import { DetermineLanguage } from '@helpers/determineLanguage';

interface ILocalizationContext {
  data: IConfigPage | null;
  translate: (key: string) => string;
  cursor: string;
  setCursor: (cursor: string) => void;
}

const DataContext = createContext<ILocalizationContext>({
  data: null as IConfigPage | null,
  translate: () => '',
  cursor: '',
  setCursor: () => {},
});

export const DataProvider: React.FC<{
  children: ReactNode;
  value: IConfigPage;
}> = ({ children, value }) => {
  const [data, setData] = useState<IConfigPage | null>(null);
  const [cursor, setCursor] = useState('');
  const language = DetermineLanguage();

  useEffect(() => {
    if (!value) return;
    value.language = language;
    setData(value);
  }, [value, language]);

  const translate = (key: string) => {
    return data?.translation?.[key] || key;
  };

  return <DataContext.Provider value={{ data, translate, cursor, setCursor }}>{children}</DataContext.Provider>;
};

export const useData = (): ILocalizationContext => useContext(DataContext);
