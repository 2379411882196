import Heading1 from './Heading1/Heading1';
import Heading2 from './Heading2/Heading2';
import Heading3 from './Heading3/Heading3';
import Heading4 from './Heading4/Heading4';
import Heading5 from './Heading5/Heading5';
import Heading6 from './Heading6/Heading6';
import Paragraph from './Paragraph/Paragraph';
import Preformatted from './Preformatted/Preformatted';
import UnorderedList from './UnorderedList/UnorderedList';
import OrderedList from './OrderedList/OrderedList';
import ListItem from './ListItem/ListItem';
import Superscript from './Superscript/Superscript';
import Subscript from './Subscript/Subscript';
import Span from './Span/Span';
import Div from './Div/Div';
import Section from './Section/Section';

const HtmlTags = {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Paragraph,
  Preformatted,
  UnorderedList,
  OrderedList,
  ListItem,
  Superscript,
  Subscript,
  Span,
  Div,
  Section,
};

export default HtmlTags;
