import React, { useEffect, useCallback, useRef, useReducer } from 'react';
import styles from './WhistleBlowerForm.module.scss';
import Concerns from './Concerns/Concerns';
import ReportTypes from './ReportTypes/ReportTypes';
import Questions from './Questions/Questions';
import WhistleBlowerModal from './WhistleBlowerModal/WhistleBlowerModal';
import { getRecaptchaToken, getTranslation, submitWhistleblowerData, validateAllFormInputs } from './utils/helpers';
import { TranslationKeys } from './translations/translationKeys';
import CheckIdentity from './CheckIdentity/CheckIdentity';
import {
  HTMLInputElementCustom,
  HTMLTextAreaElementCustom,
  IServerResponseSecretData,
  UploadedAttachment,
} from './utils/interfaces';
import InputTextCustom from './InputTextCustom/InputTextCustom';
import Attachments from './Attachments/Attachments';
import loaderGif from '@images/loader-black.gif';
import Image from 'next/image';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormState, whistleBlowerFormReducer } from './utils/reducers/WhistleBlowerForm.reducer';

const ENDPOINT_SUBMIT_FORM_DATA = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/WhistleblowingReports`;
const ENDPOINT_UPLOAD_FILE = `${process.env.NEXT_PUBLIC_WHISTLEBLOWER_API}/Attachments`;

const WhistleBlowerForm: React.FC = () => {
  const initialState: FormState = {
    formData: {},
    submitError: '',
    formInputsValid: {},
    shouldValidate: false,
    isFormSubmitted: false,
    hasFetchError: false,
    attachments: [],
    isLoaderVisible: true,
    isLoaderSubmitVisible: false,
    counterLoaderHelper: 0,
    serverResponseSecretData: {} as IServerResponseSecretData,
    isInformationModalActive: false,
    isConfirmationModalActive: false,
    recaptchaError: null,
    recaptchaToken: null,
    isUploadingFiles: false,
  };
  const [state, dispatch] = useReducer(whistleBlowerFormReducer, initialState);
  const areAllInputsValid = validateAllFormInputs(state.formInputsValid);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleCounterLoaderHelper: React.Dispatch<React.SetStateAction<number>> = useCallback(
    (value: number | ((prevCounter: number) => number)) => {
      dispatch({
        type: 'SET_COUNTER_LOADER_HELPER',
        payload: typeof value === 'function' ? value(state.counterLoaderHelper) : state.counterLoaderHelper + value,
      });
    },
    [state.counterLoaderHelper]
  );

  const handleHasFetchError: React.Dispatch<React.SetStateAction<boolean>> = useCallback(
    (value) => {
      dispatch({
        type: 'SET_HAS_FETCH_ERROR',
        payload: typeof value === 'function' ? value(state.hasFetchError) : value,
      });
    },
    [state.hasFetchError]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    const { name, value, isValid } = e.target;
    dispatch({ type: 'SET_FORM_INPUTS_VALID', payload: { [name]: isValid ?? false } });
    dispatch({ type: 'SET_FORM_DATA', payload: { [name]: value } });
  }, []);

  const handleFilesChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (!e.target) return;
      const { name, value, isValid } = e.target;
      dispatch({ type: 'SET_FORM_INPUTS_VALID', payload: { ...state.formInputsValid, [name]: isValid } });
      dispatch({ type: 'SET_ATTACHMENTS', payload: value });
    },
    [state.formInputsValid]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = await getRecaptchaToken(recaptchaRef, dispatch);

    if (areAllInputsValid) {
      dispatch({ type: 'SET_IS_LOADER_SUBMIT_VISIBLE', payload: true });
    }

    const uploadedFiles: UploadedAttachment[] = [];
    for (const attachment of state.attachments) {
      if (attachment.file) {
        const formData = new FormData();
        formData.append('file', attachment.file);
        const response = await fetch(ENDPOINT_UPLOAD_FILE, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          if (data.Id && data.FileName) {
            uploadedFiles.push({ id: data.Id, fileName: data.FileName });
          } else {
            dispatch({ type: 'SET_SUBMIT_ERROR', payload: getTranslation(TranslationKeys.FILE_UPLOAD_FAILED) });
            return;
          }
        } else {
          dispatch({ type: 'SET_SUBMIT_ERROR', payload: getTranslation(TranslationKeys.FILE_UPLOAD_FAILED) });
          return;
        }
      }
    }

    const fullFormData = {
      recaptchaToken: token || null,
      ...state.formData,
      attachments: uploadedFiles,
    };

    await submitWhistleblowerData(ENDPOINT_SUBMIT_FORM_DATA, fullFormData)
      .then((data) => {
        if (data) {
          dispatch({ type: 'SET_SERVER_RESPONSE_SECRET_DATA', payload: data as unknown as IServerResponseSecretData });
          dispatch({ type: 'SET_IS_INFORMATION_MODAL_ACTIVE', payload: true });
          dispatch({ type: 'SET_SUBMIT_ERROR', payload: '' });
          dispatch({ type: 'SET_IS_FORM_SUBMITTED', payload: true });
        } else {
          dispatch({ type: 'SET_SUBMIT_ERROR', payload: getTranslation(TranslationKeys.SUBMIT_FORM_ERROR_MESSAGE) });
        }
      })

      .finally(() => {
        dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: null });
        dispatch({ type: 'SET_IS_LOADER_SUBMIT_VISIBLE', payload: false });
      });
  };

  useEffect(() => {
    if (state.shouldValidate) {
      dispatch({ type: 'SET_SHOULD_VALIDATE', payload: false });
    }
  }, [state.shouldValidate, state.formInputsValid]);

  useEffect(() => {
    if (state.counterLoaderHelper < 1) dispatch({ type: 'SET_IS_LOADER_VISIBLE', payload: false });
    else dispatch({ type: 'SET_IS_LOADER_VISIBLE', payload: true });
  }, [state.counterLoaderHelper]);

  const handleDelete = useCallback(() => {
    dispatch({ type: 'SET_IS_CONFIRMATION_MODAL_ACTIVE', payload: false });
  }, []);

  const handleModal = useCallback((type: 'information' | 'confirmation', isOpen: boolean) => {
    if (type === 'information') {
      dispatch({ type: 'SET_IS_INFORMATION_MODAL_ACTIVE', payload: isOpen });
    } else if (type === 'confirmation') {
      dispatch({ type: 'SET_IS_CONFIRMATION_MODAL_ACTIVE', payload: isOpen });
    }
  }, []);

  const onRecaptchaSuccess = useCallback(
    (tokenForVerification: string | null) => {
      dispatch({ type: 'SET_RECAPTCHA_ERROR', payload: null });
      dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: tokenForVerification });
    },
    [dispatch]
  );

  const onRecaptchaError = useCallback(() => {
    dispatch({ type: 'SET_RECAPTCHA_ERROR', payload: getTranslation(TranslationKeys.RECAPTCHA_EXECUTION_FAILED) });
    dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: null });
    recaptchaRef.current?.reset();
  }, [dispatch, recaptchaRef]);

  const onRecaptchaExpired = useCallback(() => {
    dispatch({ type: 'SET_RECAPTCHA_ERROR', payload: getTranslation(TranslationKeys.RECAPTCHA_TOKEN_EXPIRED) });
    dispatch({ type: 'SET_RECAPTCHA_TOKEN', payload: null });
    recaptchaRef.current?.reset();
  }, [dispatch, recaptchaRef]);

  return state.hasFetchError ? (
    <div>{getTranslation(TranslationKeys.FETCH_FORM_ERROR_MESSAGE)}</div>
  ) : (
    <>
      {state.isLoaderVisible && 'Loading...'}
      {state.isLoaderVisible && (
        <div style={{ position: 'relative', top: 0, left: 0, width: '100%', height: '200px' }}>
          <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <Image
              width={100}
              height={100}
              src={loaderGif}
              alt='Loader'
            />
          </div>
        </div>
      )}
      <div className={`${state.isLoaderVisible ? 'invisible' : 'visible'}`}>
        <form
          //eslint-disable-next-line
          onSubmit={handleSubmit}
          className='EPiServerForms'
        >
          <InputTextCustom
            name='title'
            onChange={handleChange}
            required={true}
            validationMessage={getTranslation(TranslationKeys.REQUIRED_FIELD)}
            labelText={getTranslation(TranslationKeys.PLACEHOLDER_TITLE)}
          />
          <ReportTypes
            shouldValidate={state.shouldValidate}
            name='reportTypes'
            onChange={handleChange}
            validationMessage={getTranslation(TranslationKeys.CHOOSE_REPORT_TYPE)}
            required={true}
            setHasFetchError={handleHasFetchError}
            counterLoaderHelper={handleCounterLoaderHelper}
          />
          <Concerns
            shouldValidate={state.shouldValidate}
            name='concerns'
            onChange={handleChange}
            validationMessage={getTranslation(TranslationKeys.CHOOSE_CONCERN)}
            required={true}
            setHasFetchError={handleHasFetchError}
            counterLoaderHelper={handleCounterLoaderHelper}
          />
          <Questions
            shouldValidate={state.shouldValidate}
            name='questions'
            onChange={handleChange}
            validationMessage={getTranslation(TranslationKeys.REQUIRED_VALUE)}
            required={true}
            setHasFetchError={handleHasFetchError}
            counterLoaderHelper={handleCounterLoaderHelper}
          />
          <Attachments
            shouldValidate={state.shouldValidate}
            name='attachments'
            onChange={handleFilesChange}
            required={false}
          />
          <CheckIdentity
            shouldValidate={state.shouldValidate}
            name='reveal'
            onChange={handleChange}
            required={true}
            validationMessage={getTranslation(TranslationKeys.REQUIRED_FIELD)}
            getRecaptchaToken={() => getRecaptchaToken(recaptchaRef, dispatch)}
          />
          {state.isLoaderSubmitVisible && (
            <Image
              width={100}
              height={100}
              src={loaderGif}
              alt='submit form gif'
            />
          )}
          <div className={styles.buttonSubmitWrapper}>
            <button
              disabled={!areAllInputsValid || state.isFormSubmitted}
              type='submit'
              className={`Form__Element FormSubmitButton`}
            >
              {getTranslation(TranslationKeys.SUBMIT)}
            </button>
          </div>
        </form>
      </div>

      {state.isConfirmationModalActive && (
        <WhistleBlowerModal
          handleModal={handleModal}
          modalType='confirmation'
          title={getTranslation(TranslationKeys.CONFIRMATION_DELETE_DOCUMENT)}
          onConfirm={handleDelete}
        />
      )}
      {state.isInformationModalActive && (
        <WhistleBlowerModal
          handleModal={handleModal}
          modalType='information'
          title={getTranslation(TranslationKeys.SUCCESS)}
          trackingNumber={state.serverResponseSecretData?.referenceNumber}
          secretCode={state.serverResponseSecretData?.referenceCode}
        />
      )}
      {state.isFormSubmitted && <div>{getTranslation(TranslationKeys.REPORT_SUBMITTED_SUCCESSFULLY)}</div>}

      <div style={{ position: 'fixed', zIndex: '1000' }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
          size='invisible'
          onChange={onRecaptchaSuccess}
          onError={onRecaptchaError}
          onExpired={onRecaptchaExpired}
        />
      </div>
      {state.recaptchaError && <div className='error'>{state.recaptchaError}</div>}
      {state.submitError !== '' && <div className='error'>{state.submitError}</div>}
    </>
  );
};

export default React.memo(WhistleBlowerForm);
