import { queryGraphQl } from '@lib/apolloClient';
import SearchQuery from '@graphql/queries/SearchQuery.graphql';
import { useData } from '@context/GraphQLDataContext';

type SearchQueryParams = {
  query: string;
  limit: number;
  locale: string;
  cursor: string;
};

type SearchQueryResult = {
  Content: {
    cursor: string;
    items: Array<{
      Category: Array<{ Name: string }>;
      Name: string;
      RelativePath: string;
      RouteSegment: string;
      _fulltext: string[];
      _score: number;
    }>;
    total: number;
  };
};

export type SearchResultItem = {
  Name: string;
  RelativePath: string;
  RouteSegment: string;
  fulltext: string;
  _score: number;
  Category?: string[];
  _fulltext?: string[];
};

function extractTextAndHighlightKeyword(originalText: string, keywordString: string, contextWords = 10): string {
  // Remove all HTML tags
  const plainText = originalText.replace(/<\/?[^>]+(>|$)/g, '');

  // Split the keyword string into individual words
  const keywords = keywordString.split(/\s+/).filter(Boolean);

  // RegExp to find the first keyword and a specified number of words around it
  const regexPattern = new RegExp(`(?:\\b|^|\\s)([^\\s]*(?:${keywords.join('|')})[^\\s]*)(?:\\b|\\s|$)`, 'igu');

  const match = regexPattern.exec(plainText);

  if (!match) {
    return ''; // No match found
  }

  const words = plainText.split(/\s+/);
  // Get the index of the matched keyword
  const keywordIndex = words.indexOf(match[1]);

  // Get the start and end indices for the snippet
  const startIndex = Math.max(0, keywordIndex - contextWords);
  const endIndex = Math.min(plainText.length, keywordIndex + match[1].length + contextWords);

  // Extract the snippet
  let snippet = words.slice(startIndex, endIndex).join(' ');

  // Highlight all occurrences of each keyword in the snippet
  keywords.forEach((keyword) => {
    snippet = snippet.replace(new RegExp(keyword, 'ig'), '<span class="highlightedWord">$&</span>');
  });

  return `<p>... ${snippet} ...</p>`;
}

export const useSearch = (): [
  (
    query: string,
    limit: number,
    isInitial?: boolean
  ) => Promise<{ items: SearchResultItem[] | undefined; total?: number }>,
] => {
  const { data, setCursor, cursor } = useData();

  const search = (
    query: string,
    limit: number = 10,
    isInitial: boolean = false
  ): Promise<{ items: SearchResultItem[] | undefined; total?: number }> => {
    let _cursor = '';
    if (isInitial) {
      setCursor('');
      _cursor = '';
    } else {
      _cursor = cursor || '';
    }

    return queryGraphQl<SearchQueryResult>(SearchQuery, {
      query: query,
      limit: limit,
      locale: data?.language,
      cursor: _cursor,
    } as SearchQueryParams).then((queryResult) => {
      setCursor(queryResult?.Content?.cursor ?? '');
      const total = queryResult?.Content?.total;
      const resultItems = queryResult?.Content?.items.map((entry) => {
        let fulltext = '';
        for (const line of entry._fulltext) {
          if (line.startsWith('<')) {
            fulltext = line;
            break;
          }
        }

        return {
          Name: entry.Name,
          RelativePath: entry.RelativePath,
          RouteSegment: entry.RouteSegment,
          fulltext: extractTextAndHighlightKeyword(fulltext, query),
          category: entry.Category.map((category) => category.Name).join(', '),
          _score: entry._score,
        };
      });
      return { items: resultItems, total };
    });
  };

  return [search];
};
