import * as styles from './FileItem.module.scss';
import DownloadIcon from './DownloadIconSvg';
import DownloadIconLinkSvg from './DownloadIconLinkSvg';

interface FileItemExpanded {
  RelativePath: string;
  Title: string;
  Description: string;
}

const FileItem: React.FC<FileItemExpanded> = ({ Title, Description, RelativePath }) => {
  const ASSETS_PATH = process.env.NEXT_PUBLIC_ASSETS_PATH || '';

  const fullPath = `${ASSETS_PATH}${RelativePath}`;

  return (
    <li
      className={`${styles['press-kit_box']} ${styles['kafd-press-kit-section']} lozad animate-section js-top-nav js-new-item`}
      data-toggle-class='animate-section'
      data-loaded='true'
    >
      <div className={`${styles['press-kit_col']} ${styles['_col1']}`}>
        <h3 className={`${styles['press-kit_doc_heading']}`}>{Title}</h3>
        <div className={`${styles['press-kit_doc_desc']}`}>{Description}</div>
      </div>
      <div className={`${styles['press-kit_col']} ${styles['_col2']}`}>
        <a
          href={fullPath ? fullPath : '#'}
          target='_blank'
          rel='noopener noreferrer'
          className={`${styles['btn']} ${styles['btn__download']} ${styles['btn__primary']}`}
        >
          <DownloadIcon className={` ${styles['icon__download']} ${styles['_link']} replaced-svg`} />
          <DownloadIconLinkSvg className={styles['iconLink']} />
        </a>
      </div>
    </li>
  );
};

export default FileItem;
