interface IAnimateClasses {
  animateClass: string | undefined;
}

export const determineClasses = (className?: string): IAnimateClasses => {
  if (className?.includes('animate-block')) {
    //eg. HP - text blocks
    return { animateClass: 'reveal-content' };
  } else if (className?.includes('move-left-to-right')) {
    return { animateClass: 'reveal-content-to-right' };
  }

  return { animateClass: undefined };
};
