import { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import * as styles from './NoResult.module.scss';
import Image from '@components/common/htmlTags/Image/Image';
import ExampleImg from '@images/enquiry-right.jpg';
import Icon from '@components/Icon/Icon';
import Link from 'next/link';
import { useData } from '@context/GraphQLDataContext';

type Props = {
  result: boolean;
};

const NoResult: FC<Props> = ({ result }) => {
  const { translate } = useData();
  const noResultWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    noResultWrapper.current?.classList.add('no-results-animated');

    const timer = setTimeout(() => {
      noResultWrapper.current?.classList.remove('no-results-animated');
    }, 3000);

    return () => clearTimeout(timer);
  }, [result]);

  return (
    <div
      ref={noResultWrapper}
      className={clsx(result ? [styles.noResultsContainer, styles.bgWhite] : styles.bgDark)}
    >
      <div className='kafd-container !pt-0 !mb-0 !min-h-0'>
        {result && (
          <div className='w-full min-[1024px]:w-10/12 mx-auto min-[1280px]:p-[0_1.875rem]'>
            <div className={styles.noResults}>
              <p>{translate('no-results-found')}</p>
              <p>{translate('tip-for-search')}</p>
            </div>
          </div>
        )}
        <div className='kafd-enquiry'>
          <div className={clsx('w-full lg:w-11/12 ml-auto min-[1024px]:min-h-[420px]', styles.boxTop)}>
            <div className='flex flex-wrap relative z-[2]'>
              <div className={`w-full min-[1024px]:w-10/12 min-[1280px]:w-7/12 ${styles.mailSection}`}>
                <div className='relative pl-0 pr-0'>
                  <div
                    className={`${styles.mailBlock} img-holder max-[1024px]:w-[127px] max-[1024px]:h-[127px] w-[215px] h-[215px] relative`}
                  >
                    <Image
                      url={ExampleImg}
                      alt='Image'
                      sizes='100vw'
                      width={0}
                      height={0}
                      className='object-cover h-full opacity-15'
                    />
                    <div className={` ${styles.mailIcon} absolute animated-icon`}>
                      <Icon.MailIcon />
                    </div>
                    <div className={`${styles['patt-1']} animated-2`}>
                      <Icon.Patt4 />
                    </div>
                    <div className={`${styles['patt-2']} animated-3`}>
                      <Icon.Patt5 />
                    </div>
                  </div>
                  <div className={`${styles.mailText} text-block / absolute`}>
                    <div className={styles.boxText}>{translate('to-book-a-visit')}</div>
                    <Link
                      href='mailto:helpdesk@kafd.sa'
                      className={styles.link}
                    >
                      {translate('mail')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx('w-full lg:w-11/12 mr-auto relative min-[1024px]:-mt-[330px]', styles.boxBottom)}>
            <div className='flex flex-wrap relative z-[1]'>
              <div className={`w-full min-[1024px]:w-8/12 min-[1280px]:w-7/12 ml-auto ${styles.phoneSection}`}>
                <div className='relative pl-0 pr-0'>
                  <div
                    className={`img-holder ${styles.phoneBlock} max-[1024px]:w-[147px] max-[1024px]:h-[179px] w-[245px] h-[299px] relative`}
                  >
                    <Image
                      url={ExampleImg}
                      alt='Image'
                      sizes='100vw'
                      width={0}
                      height={0}
                      className='object-cover h-full opacity-15'
                    />
                    <div
                      className={`${styles.phoneIcon} absolute top-[calc(50%_-_62px/2)] left-[calc(50%_-_62px/2)] animated-icon`}
                    >
                      <Icon.PhoneIcon />
                    </div>
                    <div className={`${styles['patt-1']} animated-1`}>
                      <Icon.Patt1 />
                    </div>
                    <div className={`${styles['patt-2']}  animated-4`}>
                      <Icon.Patt2 />
                    </div>
                  </div>
                  <div
                    className={`${styles.phoneText} text-block / absolute min-[1024px]:w-[calc(100%_-_275px)] max-[1024px]:bottom-[30px] min-[1024px]:top-[50%] text-right`}
                  >
                    <div className={styles.boxText}>{translate('to-book-a-visit-short')}</div>
                    <Link
                      prefetch={false}
                      href='tel:966920000247'
                      className={styles.link}
                    >
                      {translate('phone')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResult;
