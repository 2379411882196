import { FC, useEffect, useReducer } from 'react';
import * as styles from './SearchBar.module.scss';
import Icon from '@components/Icon/Icon';
import clsx from 'clsx';
import NoResult from '@components/SearchBar/NoResult/NoResult';
import FoundResult from '@components/SearchBar/FoundResult/FoundResult';
import Link from 'next/link';
import { useData } from '@context/GraphQLDataContext';
import { useSearch } from '@hooks/useSearch';
import { highlightMatchString } from '@helpers/highlightMatchString';
import { ISearchBarState, SearchBarReducer } from './SearchBar.reducer';
import useUUID from '@hooks/useUUID';

type Props = {
  close: (arg: boolean) => void;
  query: string;
  setQuery: (query: string) => void;
};

const SearchBar: FC<Props> = ({ close, query, setQuery }) => {
  const initialState: ISearchBarState = {
    result: false,
    showInputText: false,
    showAutoComplete: false,
    isSubmitted: false,
    foundData: [],
    total: 0,
  };

  const [state, dispatch] = useReducer(SearchBarReducer, initialState);

  const isValidQuery = query.length > 3;
  const { translate, data } = useData();
  const [search] = useSearch();
  const foundDataKeys = useUUID(state.foundData?.length);
  useEffect(() => {
    const bodyStyle = document.body.style;
    // Save the current body overflow style
    const originalOverflow = bodyStyle.overflow;
    // Disable scrolling
    bodyStyle.overflow = 'hidden';

    // Cleanup function to re-enable scrolling
    return () => {
      bodyStyle.overflow = originalOverflow;
    };
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch({ type: 'SET_IS_SUBMITTED', payload: true });
    search(query.toLowerCase(), 7, true)
      .then(({ items, total }) => {
        dispatch({ type: 'SET_FOUND_DATA', payload: items });
        dispatch({ type: 'SET_TOTAL', payload: total });
        dispatch({ type: 'SET_RESULT', payload: true });
        dispatch({ type: 'SET_SHOW_AUTOCOMPLETE', payload: false });
      })
      .catch(() => {
        dispatch({ type: 'RESET_STATE' });
      });
  };

  const handleClear = () => {
    setQuery('');
    dispatch({ type: 'RESET_STATE' });
  };

  useEffect(() => {
    if (!isValidQuery) {
      dispatch({ type: 'RESET_STATE' });
      return;
    }

    const delayFn = setTimeout(() => {
      void (async () => {
        dispatch({ type: 'SET_RESULT', payload: false });
        const { items, total } = await search(query.toLowerCase(), 7, true);
        dispatch({ type: 'SET_TOTAL', payload: total });
        dispatch({ type: 'SET_FOUND_DATA', payload: items });
        if (!state.isSubmitted) {
          dispatch({ type: 'SET_SHOW_AUTOCOMPLETE', payload: true });
        }
      })();
    }, 500);

    return () => clearTimeout(delayFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidQuery, query]);

  return (
    <div className={`${styles.searchWrapper} searchWrapper`}>
      <div className={styles.headerWrapper}>
        <a href={data?.language ? `/${data?.language}` : '/en'}>
          <Icon.KafdLogoIcon className={styles.logoBig} />
          <Icon.MKafdIcon className={styles.logoSmall} />
        </a>
        <button
          type='button'
          onClick={() => close(false)}
          className={styles.closeButton}
        >
          {translate('close')}
          <Icon.CloseModalIcon />
        </button>
      </div>
      <div className={styles.innerWrapper}>
        <div className={styles.bgDark}>
          <div className={clsx('kafd-container !min-h-[initial]', styles.bgDark, styles.container)}>
            <div className='w-full min-[1280px]:w-10/12 mx-auto min-[1280px]:p-[0_1.875rem]'>
              <div className={styles.headerTitleWrapper}>
                <h1 className={styles.headerTitle}>{translate('how-can-we-help')}</h1>
                <div className={styles.headerText}>{translate('search-description')}</div>
              </div>
              <form
                className={styles.form}
                onSubmit={handleSubmit}
              >
                <input
                  id='search'
                  type='text'
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    dispatch({ type: 'SET_SHOW_AUTOCOMPLETE', payload: true });
                  }}
                  placeholder={translate('type-in-to-search')}
                  className={styles.inputField}
                  onSubmit={() => {
                    dispatch({ type: 'SET_SHOW_AUTOCOMPLETE', payload: false });
                  }}
                  onFocus={() => {
                    dispatch({ type: 'SET_SHOW_INPUT_TEXT', payload: true });
                    dispatch({ type: 'SET_SHOW_AUTOCOMPLETE', payload: true });
                    dispatch({ type: 'SET_IS_SUBMITTED', payload: false });
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      dispatch({ type: 'SET_SHOW_AUTOCOMPLETE', payload: false });
                      if (query !== '') return;
                      dispatch({ type: 'SET_SHOW_INPUT_TEXT', payload: false });
                    }, 200);
                  }}
                />
                {state.showInputText && (
                  <label
                    htmlFor='search'
                    className={styles.label}
                  >
                    {translate('search')}
                  </label>
                )}
                {!state.result ? (
                  <button
                    type='submit'
                    className={styles.button}
                    aria-label='Search'
                    disabled={!isValidQuery}
                  >
                    <Icon.IcoSearchIcon />
                  </button>
                ) : (
                  <button
                    type='button'
                    className={styles.button}
                    aria-label='Clear'
                    onClick={handleClear}
                  >
                    <Icon.CloseModalIcon />
                  </button>
                )}
                {state.showAutoComplete && state.foundData && (
                  <div className='absolute w-full top-[93px] left-0 z-[100]'>
                    <ul>
                      {state.foundData.map((data: any, index: number) => (
                        <li
                          key={foundDataKeys[index]}
                          className={styles.autocompleteItem}
                        >
                          <Link
                            target='_blank'
                            href={data.RelativePath}
                          >
                            <div className={styles.autocompleteCategory}>{translate(data.category)}</div>
                            <div className={styles.autocompleteTitle}>
                              {highlightMatchString(data.Name, query, styles.highlightTitleMatch)}
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    {state.foundData && state.foundData.length < (state.total || 0) && (
                      <div className={styles.bottomBox}>
                        <Link
                          prefetch={false}
                          href='#'
                          onClick={handleSubmit}
                          className='button-style-2'
                        >
                          <span>{translate('see-all-results')}</span>
                          <svg className='icon cta-icon'>
                            <use xlinkHref='/img/sprite/sprite.svg#anchor-arrow'></use>
                          </svg>
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className={styles.bgWhite}>
          {state.result && state.foundData && state.foundData.length > 0 ? (
            <FoundResult
              items={state.foundData}
              query={query}
              initialTotal={state.total}
            />
          ) : (
            <NoResult result={state.result} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
