import { FormStorage } from '../form-storage';
import { FormValidator } from '../form-validator';
import { equals, isNull } from '../helpers';
import {
  FormConstants,
  FormContainer,
  FormState,
  FormSubmission,
  FormValidationResult,
  ProblemDetail,
} from '../models';
import { ApiConstant } from '../form-loader/apiConstant';
import ReCAPTCHA from 'react-google-recaptcha';
import { ApiClient } from '../form-loader/apiClient';
import { ApiClientConfig, defaultConfig } from "../form-loader/apiClientConfig";

export interface FormSubmitModel {
  /**
   * The key of the form's submitted data.
   */
  formKey: string;
  /**
   * The locale of the submitted form.
   */
  locale: string;
  /**
   * Key value collection of submitted data
   */
  submissionData: FormSubmission[];
  /**
   * Indicate whether the form submission is finalize
   */
  isFinalized: boolean;
  /**
   * The key reference to newly saved form submission.
   */
  partialSubmissionKey: string;
  /**
   * The url of the page hosted the form step
   */
  hostedPageUrl: string;
  /**
   * The access token to identify login user
   */
  accessToken?: string;
  /**
   * The current index of submitted step
   */
  currentStepIndex: number;
  /**
   * The token generated by Google reCAPTCHA v2 invisible.
   */
  reCaptchaRef?: any;
  reCaptchaKey?: string;
  /**
   * The endpoint of the form submission coming from the CMS.
   */
  formEndpoint?: string;
}

export interface FormSubmitResult {
  /**
   * Indication if the submission succeeded.
   */
  success: boolean;
  /**
   * The key reference to newly saved form submission.
   */
  submissionKey: string;
  /**
   * Indication if the form validation succeeded.
   */
  validationFail: boolean;
  /**
   * List of messages from the form submission.
   */
  messages: FormSubmitMessage[];
  /**
   * returned status after submit to CRM through reverse proxy
   */
  httpStatus?: string;
}

export interface FormSubmitMessage {
  /**
   * The section where the message originated from.
   */
  section: string;
  /**
   *  The message describing an outcome.
   */
  message: string;
  /**
   * The identifier of the resource that was the reason for this message to be created.
   */
  identifier: string;
}

/**
 * Class to submit form submission to Headless Form API
 */

const baseURL = `${process.env.NEXT_PUBLIC_CMS_FORM_PATH}`;
export class FormSubmitter{
  readonly _form: FormContainer;
  readonly _baseUrl: string;
  readonly client: ApiClient<FormContainer>;

  constructor(form: FormContainer, baseUrl: string) {
    this._form = form;
    this._baseUrl = `${baseURL}/`;
      this.client = new ApiClient<FormContainer>({ ...defaultConfig});
  }

  async triggerRecaptchaVerificationManually(reCaptchaRef: any) {
    if (reCaptchaRef.current) {
      const captchaInstance = reCaptchaRef.current as ReCAPTCHA;
      if (captchaInstance) {
       const token =  await captchaInstance.execute();
       return token;
      //  .then((token: string | null) => {
      //     return token;
      //   }).catch((error) => {
      //     console.log('Error when trigger reCAPTCHA verification manually', error);
      //     return null;
      //   }).finally(() => {
      //     console.log('ReCAPTCHA verification is triggered.');
      //   });
      }
    } else {
      console.log('ReCAPTCHA ref is not set.');
      return null;
    }
  }

  /**
   * Combine 2 data from storage and form submission
   * @param dataFromStorage the array of form data from session storage
   * @param submissionData the array of form submission
   */
  combineData(dataFromStorage: FormSubmission[], submissionData: FormSubmission[]): FormSubmission[] {
    const mapFromArray = new Map<string, FormSubmission>();
    submissionData.forEach((element) => {
      mapFromArray.set(element.elementKey, element);
    });

    const combinedData = [
      ...submissionData,
      ...dataFromStorage.filter((element) => !mapFromArray.has(element.elementKey)),
    ];

    return combinedData;
  }

  formDataToJSON(formData: FormData) {
    const jsonObject: any = {};
    formData.forEach((value, key) => {
      if (jsonObject[key]) {
        if (!Array.isArray(jsonObject[key])) {
          jsonObject[key] = [jsonObject[key]];
        }
        jsonObject[key].push(value);
      } else {
        jsonObject[key] = value;
      }
    });
    return jsonObject;
  }

  /**
   * Post an array of form submission to the Headless Form API
   * @param formSubmission the array of form submission to post
   */
  doSubmit(model: FormSubmitModel, formContext: FormState | null): Promise<FormSubmitResult> {
    return new Promise<FormSubmitResult>(async (resolve, reject) => {
     
      // Post data to API
      let formData = {} as any;
      const reCaptchaRef = model.reCaptchaRef;
      const reCaptchaKey = model.reCaptchaKey;
      let reCaptchaToken: void | null = null;
      if (reCaptchaRef && reCaptchaKey) {
        await this.triggerRecaptchaVerificationManually(reCaptchaRef).then((token) => {
          reCaptchaToken = token;
          if(!reCaptchaToken){
            reject({message: 'reCAPTCHA verification failed'});
          }
        }).catch((error) => {
          console.log('Error when trigger reCAPTCHA verification manually', error);
        })
       
      }
      formData = {formKey: model.formKey,
        locale: model.locale,
        isFinalized: model.isFinalized,
        SubmissionKey: model.partialSubmissionKey,
        HostedPageUrl: model.hostedPageUrl,
        CurrentStep: model.currentStepIndex,
      };

      const inputs = formContext?.formContainer.formElements;
      const token = await this.client.getToken(`${process.env.NEXT_PUBLIC_CMS_FORM_PATH}/api/episerver/connect/token`);
      const dataObject = {} as any;
      //append form submission to FormData object
      model.submissionData.forEach((data) => {
        let ovalue = data.value;
        let key = '';
        if (model.formEndpoint && model.formEndpoint !== '') {
          inputs?.find((element) => {
            if (element.key === data.elementKey) {
              key = element.displayName;
            }
          });
        } else {
          key = `${FormConstants.FormFieldPrefix}${data.elementKey}`;
        }

        if (isNull(ovalue)) {
          return;
        }

        // checking file upload elements, item must be File if any,
        // for using Object.getPrototypeOf(variable) variable must be object type
        if (Array.isArray(ovalue) && ovalue.length > 0 && ovalue[0] !== null && typeof ovalue[0] === 'object') {
          let files = ovalue,
            fileNames = '';
          // append each upload file with a unique key (bases on element's key) so that the server side can see it through the Request.Files,
          // concat all the files' name and assign with the element's Id
          for (var idx = 0; idx < files.length; idx++) {
            let ofile = files[idx].file;
            if (ofile && Object.getPrototypeOf(ofile) === File.prototype) {
              dataObject[key + '_file_' + idx] = ofile;
            }

            // always send file name to server if existed to handle case upload file then click back
            // charactor | cannot be used in filename and then is safe for splitting later
            if (idx > 0) {
              fileNames += ' | ';
            }

            fileNames += files[idx].name;
          }
          dataObject[key] = fileNames;
          data.prevValue = fileNames;
        } else {
          if (reCaptchaKey && (key === reCaptchaKey || key === 'reCaptcha')) {
            dataObject[key] = reCaptchaToken;
            dataObject['reCaptchaSiteKey'] = data.value;
          } else {
            dataObject[key] = data.value;
           
          }
        }
      });
      formData['fields'] = dataObject;
      // Save data to session storage
      let formStorage = new FormStorage(this._form);
      let currentData = formStorage.loadFormDataFromStorage();
      let dataCombined = this.combineData(currentData, model.submissionData);
      formStorage.saveFormDataToStorage(dataCombined);
      //init a request and call ajax

      const isCRM = model.formEndpoint && model.formEndpoint !== '' && model.formEndpoint.indexOf('api/crm/') > -1;
      
      let requestInit: RequestInit = {
        method: isCRM ? 'POST' : 'PUT',
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
        body : JSON.stringify(formData)
      };
  
      try {
        const submitUrl = isCRM
          ? `${this._baseUrl}${model.formEndpoint}`
          : `${this._baseUrl}${ApiConstant.apiEndpoint}`;
        const response: Response = await fetch(submitUrl, requestInit);
        let responseJson: ProblemDetail | FormSubmitResult = await response.json();
        if (response.ok) {
          formStorage.removeFormDataInStorage();
          resolve(responseJson as FormSubmitResult);
        } else {
          console.log('The answer from the server', responseJson);
          reject(responseJson as ProblemDetail);
        }
      } catch (error) {
        console.log('ERROR', error);
        reject(error);
      }
    });
  }

  /**
   * Function to validate data before submit
   * @param formSubmission the array of form submission to post
   * @returns An array of validation result
   */
  doValidate(formSubmissions: FormSubmission[]): FormValidationResult[] {
    return this._form.formElements
      .filter((e) => formSubmissions.some((fs) => equals(fs.elementKey, e.key)))
      .map((e) => {
        let formValidator = new FormValidator(e);
        let value = formSubmissions.filter((fs) => equals(fs.elementKey, e.key))[0]?.value;
        return {
          elementKey: e.key,
          result: formValidator.validate(value),
        } as FormValidationResult;
      });
  }
}
