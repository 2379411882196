import { useState, useEffect, useCallback } from 'react';

const useHandleResize = (mobileWidthThreshold = 1024) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < mobileWidthThreshold);
  }, [mobileWidthThreshold]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return isMobile;
};

export default useHandleResize;
