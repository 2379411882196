import ArrowDown from '@images/svg/arrow-down.svg';
import ArrowRight from '@images/svg/arrow-right-thin.svg';
import ArrowAnchor from '@images/svg/arrow-anchor.svg';
import PlayVideo from '@images/svg/play.svg';
import CloseModal from '@images/svg/ico-close.svg';
import ChevronLeft from '@images/svg/chevron-left.svg';
import ChevronRight from '@images/svg/chevron-right.svg';
import ChevronDown from '@images/svg/chevron-down.svg';
import ChevronDownFooter from '@images/svg/chevron-down-footer.svg';
import Download from '@images/svg/download.svg';
import KafdLogo from '@images/svg/kafd.svg';
import IcoSearch from '@images/svg/ico-search.svg';
import PifLogo from '@images/svg/pif-logo.svg';
import MKafd from '@images/svg/m-kafd.svg';
import BigPattern from '@images/svg/big-pattern.svg';
import IcoTelV2 from '@images/svg/ico-tel-v2.svg';
import IcoMailEnv from '@images/svg/ico-mail-env.svg';
import IcoTw from '@images/svg/ico-tw.svg';
import IcoYt from '@images/svg/ico-yt.svg';
import IcoFb from '@images/svg/ico-fb.svg';
import IcoIn from '@images/svg/ico-in.svg';
import IcoLi from '@images/svg/ico-li.svg';
import Mail from '@images/svg/ico-mail.svg';
import Phone from '@images/svg/ico-phone.svg';
import Patt1 from '@images/patterns/patt-1.svg';
import Patt2 from '@images/patterns/patt-2.svg';
import Patt3 from '@images/patterns/patt-3.svg';
import Patt4 from '@images/patterns/patt-4.svg';
import Patt5 from '@images/patterns/patt-5.svg';
import Patt6 from '@images/patterns/patt-6.svg';
import Tick from '@images/svg/tick.svg';
import MotifPiano from '@images/motifs/motif-piano.svg';
import MotifCircle from '@images/motifs/motif-circle.svg';
import MotifRaindrops from '@images/motifs/motif-raindrops.svg';
import UploadIcon from '@images/svg/upload-icon.svg';
import DeleteBin from '@images/svg/delete-bin.svg';

const Icon = {
  ArrowDownIcon: ArrowDown,
  ArrowRightIcon: ArrowRight,
  ArrowAnchorIcon: ArrowAnchor,
  PlayVideoIcon: PlayVideo,
  CloseModalIcon: CloseModal,
  ChevronLeftIcon: ChevronLeft,
  ChevronRightIcon: ChevronRight,
  ChevronDownIcon: ChevronDown,
  ChevronDownFooterIcon: ChevronDownFooter,
  DownloadIcon: Download,
  KafdLogoIcon: KafdLogo,
  IcoSearchIcon: IcoSearch,
  PifLogoIcon: PifLogo,
  MKafdIcon: MKafd,
  BigPattern: BigPattern,
  IcoTelV2Icon: IcoTelV2,
  IcoMailEnvIcon: IcoMailEnv,
  IcoTwIcon: IcoTw,
  IcoYtIcon: IcoYt,
  IcoFbIcon: IcoFb,
  IcoInIcon: IcoIn,
  IcoLiIcon: IcoLi,
  MailIcon: Mail,
  PhoneIcon: Phone,
  Patt1: Patt1,
  Patt2: Patt2,
  Patt3: Patt3,
  Patt4: Patt4,
  Patt5: Patt5,
  Patt6: Patt6,
  Tick: Tick,
  MotifPiano: MotifPiano,
  MotifCircle: MotifCircle,
  MotifRaindrops: MotifRaindrops,
  UploadIcon: UploadIcon,
  DeleteBin: DeleteBin,
};

export default Icon;
