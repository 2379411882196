import { Attachment } from '../interfaces';

interface FormData {
  referenceNumber?: string;
  code?: string;
  reporterFeedback?: string;
  recaptchaToken?: string | null;
}

export interface FormState {
  formData: FormData;
  formInputsValid: Record<string, boolean>;
  shouldValidate: boolean;
  isFormSubmitted: boolean;
  isLoaderSubmitVisible: boolean;
  responseWhistleblowerTitle: string;
  responseWhistleblowerStatus: string;
  recaptchaError: string | null;
  recaptchaToken: string | null;
  hasFetchError: boolean;
  submitError: string;
  viewOnly: boolean;
  reporterFeedback: string;
  attachments: Attachment[];
  reportId: string;
  isUploadingFiles: boolean;
  feedbackSubmitted: boolean;
  feedbackError: string | null;
  showFeedbackSuccessState: boolean;
}

type FormAction =
  | { type: 'SET_FORM_DATA'; payload: Partial<FormData> }
  | { type: 'SET_FORM_INPUTS_VALID'; payload: Record<string, boolean> }
  | { type: 'SET_SHOULD_VALIDATE'; payload: boolean }
  | { type: 'SET_IS_FORM_SUBMITTED'; payload: boolean }
  | { type: 'SET_IS_LOADER_SUBMIT_VISIBLE'; payload: boolean }
  | { type: 'SET_IS_UPLOADING_FILES'; payload: boolean }
  | { type: 'RESET_STATE'; payload: FormState }
  | { type: 'SET_RESPONSE_TITLE'; payload: string }
  | { type: 'SET_RECAPTCHA_TOKEN'; payload: string | null }
  | { type: 'SET_RECAPTCHA_ERROR'; payload: string | null }
  | { type: 'SET_HAS_FETCH_ERROR'; payload: boolean }
  | { type: 'SET_SUBMIT_ERROR'; payload: string }
  | { type: 'SET_RESPONSE_STATUS'; payload: string }
  | { type: 'SET_ATTACHMENTS'; payload: Attachment[] }
  | { type: 'SET_VIEW_ONLY'; payload: boolean }
  | { type: 'SET_REPORTER_FEEDBACK'; payload: string }
  | { type: 'SET_REPORT_ID'; payload: string }
  | { type: 'SET_FEEDBACK_SUBMITTED'; payload: boolean }
  | { type: 'SET_FEEDBACK_ERROR'; payload: string | null }
  | { type: 'SET_SHOW_FEEDBACK_SUCCESS_STATE'; payload: boolean };

export const whistleBlowerStatusFormReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case 'SET_FORM_INPUTS_VALID':
      return { ...state, formInputsValid: { ...state.formInputsValid, ...action.payload } };
    case 'SET_SHOULD_VALIDATE':
      return { ...state, shouldValidate: action.payload };
    case 'SET_IS_FORM_SUBMITTED':
      return { ...state, isFormSubmitted: action.payload };
    case 'SET_IS_LOADER_SUBMIT_VISIBLE':
      return { ...state, isLoaderSubmitVisible: action.payload };
    case 'RESET_STATE':
      return { ...action.payload };
    case 'SET_RESPONSE_TITLE':
      return { ...state, responseWhistleblowerTitle: action.payload != null ? action.payload : ' ' };
    case 'SET_RESPONSE_STATUS':
      return { ...state, responseWhistleblowerStatus: action.payload != null ? action.payload : ' ' };
    case 'SET_RECAPTCHA_ERROR':
      return { ...state, recaptchaError: action.payload };
    case 'SET_HAS_FETCH_ERROR':
      return { ...state, hasFetchError: action.payload };
    case 'SET_RECAPTCHA_TOKEN':
      return { ...state, recaptchaToken: action.payload };
    case 'SET_SUBMIT_ERROR':
      return { ...state, submitError: action.payload };
    case 'SET_VIEW_ONLY':
      return { ...state, viewOnly: action.payload };
    case 'SET_ATTACHMENTS':
      return { ...state, attachments: action.payload };
    case 'SET_REPORTER_FEEDBACK':
      return { ...state, reporterFeedback: action.payload };
    case 'SET_REPORT_ID':
      return { ...state, reportId: action.payload };
    case 'SET_IS_UPLOADING_FILES':
      return { ...state, isUploadingFiles: action.payload };
    case 'SET_FEEDBACK_SUBMITTED':
      return { ...state, feedbackSubmitted: action.payload };
    case 'SET_FEEDBACK_ERROR':
      return { ...state, feedbackError: action.payload };
    case 'SET_SHOW_FEEDBACK_SUCCESS_STATE':
      return { ...state, showFeedbackSuccessState: action.payload };
    default:
      return state;
  }
};
