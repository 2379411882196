import { useEffect, useState } from 'react';
import Image from 'next/image';
import Icon from '@components/Icon/Icon';
import * as styles from './Footer.module.scss';
import FooterSocials from './FooterSocials/FooterSocials';
import FooterMenuBottom from './FooterMenuBottom/FooterMenuBottom';
import { useData } from '@context/GraphQLDataContext';
import { IMenuEntryBlock, IMenuBlock, IConfigPage } from '@interfaces/IConfigPage';
import { trimUrl } from '@helpers/url.helper';
import { useExtractLanguageFromUrl } from '@hooks/useExtractLanguageFromUrl';

const Footer = () => {
  const language = useExtractLanguageFromUrl();
  const [activeCarousel, setActiveCarousel] = useState<number | null>(null);
  const data = useData();
  const { translate } = data;

  useEffect(() => {
    const footerEl = document.querySelector('.footer') as HTMLElement;
    if (!footerEl) {
      return;
    }

    const handleScroll = () => {
      const rect = footerEl.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        footerEl.dataset.anim = 'on';
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);

    handleScroll(); // start checking on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!data) {
    return null;
  }
  const { footerMainMenu, socialMediaLinks, bottomMenu } = data?.data ? data.data : ({} as IConfigPage);

  const handleToggleCarousel = (index: number) => {
    setActiveCarousel(activeCarousel === index ? null : index);
  };

  return (
    <footer
      className={`footer bg-desaturatedblue ${styles['wrap-foot']} lozad animate-section`}
      data-toggle-class='animate-section'
      data-comp-name='footer'
      data-loaded='true'
    >
      <div className={`bg-bluewood ${styles['upper-foot']} relative`}>
        <div className={`relative ${styles['kafd-main-sec']}`}>
          <div className={`${styles['kafd-container']}`}>
            <div className={`flex ${styles['list-content']}`}>
              <div className={`${styles.footerTopMenus}`}>
                <div className={`${styles['footer-top-menu']} ${styles['list-content-inner']}`}>
                  {Array.isArray(footerMainMenu) &&
                    footerMainMenu.map((menuItem, index) => {
                      if (menuItem.ContentLink.Expanded.__typename === 'MenuBlock') {
                        // type assertion of `IMenuBlock` after check
                        const menuBlock = menuItem.ContentLink.Expanded as IMenuBlock;
                        const fancyUnderlineClass = styles[menuBlock.Classname] || '';
                        const isActive = activeCarousel === index;

                        return (
                          <div
                            key={index}
                            className={`${styles['flist']} xl:w-3/12 ${styles['text-reveal-animation']} ${isActive ? styles['active'] : ''}`}
                          >
                            <div className={`${styles['f-heading']} ${fancyUnderlineClass}`}>
                              <h6 className={`font-kafd`}>
                                <a
                                  href={trimUrl(menuBlock.Url || '#')}
                                  target='_self'
                                >
                                  {menuBlock.Title.toUpperCase()}
                                </a>
                              </h6>
                              <button
                                className={`${styles['chevron']} button-reset-styles`}
                                type='button'
                                onClick={() => handleToggleCarousel(index)}
                              >
                                <Icon.ChevronDownFooterIcon />
                              </button>
                            </div>

                            <ul className='lvl-1'>
                              {Array.isArray(menuItem.children) &&
                                menuItem.children[0].Items?.map((item, index) => {
                                  if (item.ContentLink.Expanded.__typename === 'MenuEntryBlock') {
                                    // type assertion of `IMenuEntryBlock` after check
                                    const menuEntryBlock = item.ContentLink.Expanded as IMenuEntryBlock;
                                    return (
                                      <li key={index}>
                                        <a
                                          href={trimUrl(menuEntryBlock.Url || '#')}
                                          target='_self'
                                        >
                                          {menuEntryBlock.Label}
                                        </a>
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                            </ul>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>

              <div className={`${styles.footerTopContactUs} ${styles['text-reveal-animation']}`}>
                <div className={`${styles['btn__white_container']}`}>
                  <a
                    href={`/${language}/contact-us`}
                    target='_self'
                    className={`button-style-2 dark-theme`}
                  >
                    <span className={`cta-text`}>{translate('contact-us')}</span>
                    <svg className='icon cta-icon'>
                      <use xlinkHref='/img/sprite/sprite.svg#anchor-arrow'></use>
                    </svg>
                  </a>
                </div>

                <div className={styles.footerStoreBtns}>
                  <a
                    href='https://apps.apple.com/sa/app/kafd/id1673103698'
                    target='_blank'
                  >
                    <Image
                      src='/img/svg/app-store.svg'
                      alt='App Store'
                      width={96}
                      height={30}
                    />
                  </a>

                  <a
                    href='https://play.google.com/store/apps/details?id=sa.kafd.app&amp;pcampaignid=web_share'
                    target='_blank'
                  >
                    <Image
                      src='/img/svg/play-store.svg'
                      alt='Play Store'
                      width={96}
                      height={30}
                    />
                  </a>
                </div>

                {/* Note: phone & email showing only on mobile */}
                <div className={`${styles['footer__contacts']}`}>
                  <div className={`w-full`}>
                    <div className={`${styles['f-contact']}`}>
                      <ul className={`lvl-1`}>
                        <li>
                          <a
                            className={`${styles['footer-tel']}`}
                            href={`tel:${translate('phone')}`}
                            target='_blank'
                          >
                            <span className={`${styles['title']}`}>{translate('phone')}</span>
                            <span className={`${styles['icon']}`}>
                              <Icon.IcoTelV2Icon className={`${styles['icon']} replaced-svg`} />
                            </span>
                            <span className={`txt__right_nav ${styles['label']}`}>{translate('phone')}</span>
                          </a>
                        </li>

                        <li>
                          <a
                            className={`${styles['footer-tel']}`}
                            href={`mailto:${translate('mail')}`}
                            target='_self'
                          >
                            <span className={`${styles['title']}`}>{translate('mail')}</span>
                            <span className={`${styles['icon']}`}>
                              <Icon.IcoMailEnvIcon className={`${styles['icon']} replaced-svg`} />
                            </span>
                            <span className={`txt__right_nav ${styles['label']}`}>{translate('mail')}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`relative ${styles['kafd-main-sec']} sr-focus`}
        id='footer-info'
        tabIndex={-1}
      >
        <div className={`${styles['kafd-container']} ${styles['level-2']}`}>
          <FooterSocials socialMediaLinks={socialMediaLinks} />

          {/* Separator */}
          <div className={`${styles['footer-separator']} ${styles['text-reveal-animation']}`}></div>

          {bottomMenu && <FooterMenuBottom bottomMenu={bottomMenu} />}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
