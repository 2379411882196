import { ReactNode } from 'react';
import * as styles from './FixedButton.module.scss';

import { trimUrl } from '@helpers/url.helper';

interface IFixedButton {
  children?: ReactNode;
  url?: string;
}

/**
 * FixedButton Component
 *
 *A component based on a classic link or button with a span inside.
 *
 * @component
 * @param {Object} prop - The props for the FixedButton component.
 * @param {string} prop.children - The text inside a span which is inside the link. Default is children = 'Leasing Inquiries'.
 * @param {string} prop.url - A href of the link. Default is url = '#'.
 * @returns {JSX.Element} The rendered JSX element representing the button.
 */

const FixedButton = ({ children = 'Leasing Inquiries', url = '#' }: IFixedButton): JSX.Element => {
  return (
    <a
      className={styles.FixedButton}
      href={trimUrl(url)}
    >
      <span className='relative'>{children}</span>
    </a>
  );
};

export default FixedButton;
