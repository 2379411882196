import React, { useState } from 'react';
import { Attachment } from '../utils/interfaces';
import styles from './Attachments.module.scss';
import Icon from '@components/Icon/Icon';
import ConfirmationModal from '../WhistleBlowerModal/ConfirmationModal/ConfirmationModal';
import { TranslationKeys } from '../translations/translationKeys';
import { getTranslation } from '../utils/helpers';

const Attachments: React.FC<any> = ({ name, onChange }) => {
  const [attachments, setAttachments] = useState<Attachment[]>([{ file: null }]);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [idForDelete, setIdForDelete] = useState<string | undefined>('');
  const [modalToggler, setModalToggler] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      const newFileErrors = [...fileErrors];

      if (!allowedTypes.includes(file.type) || file.size > 5242880) {
        newFileErrors.push(file.name);
        setFileErrors(newFileErrors);
      }

      const newAttachments = [...attachments, { file }].filter((item) => item.file !== null);
      setAttachments(newAttachments);
      onChange({
        target: { name, value: newAttachments.filter((a) => a.file), isValid: newFileErrors.length === 0 },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleAddAttachment = () => {
    if (attachments.length < 5) {
      setAttachments([...attachments, { file: null }]);
    }
  };

  const handleModalRemoveAttachment = (id: string | undefined) => {
    setIdForDelete(id);
    setModalToggler((prevModalToggler) => !prevModalToggler);
  };

  const handleRemoveAttachment = (id: string | undefined) => {
    const newAttachments = attachments.filter(
      (item, index) => `${item.file?.name}-${item.file?.lastModified}-${index}` !== id
    );
    setAttachments(newAttachments);
    const newfileErrors = fileErrors.filter((item) => item != name);
    setFileErrors(newfileErrors);
    onChange({
      target: { name, value: newAttachments.filter((a) => a.file), isValid: newfileErrors.length === 0 },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
    setModalToggler((prevModalToggler) => !prevModalToggler);
  };

  const formatSize = (size: number) => {
    return size < 1024
      ? `${size}B`
      : size < 1048576
        ? `${(size / 1024).toFixed(1)}KB`
        : `${(size / 1048576).toFixed(1)}MB`;
  };

  return (
    <div className={styles.attachBlock}>
      <h4 className={styles.attachBlock__title}>{getTranslation(TranslationKeys.ATTACHMENTS)}</h4>
      {attachments.map((attachment, index) => (
        <div
          key={`${attachment.file?.name}-${attachment.file?.lastModified}-${index}`}
          className={`${styles.attachItem} ${fileErrors.includes(attachment.file?.name ?? '') && styles.validationItemError}`}
        >
          {/* Empty attach item */}
          {!attachment.file && (
            <label className={styles.attachItemWrapper}>
              <div className={styles.attachItemPlaceholder}>
                <div>(JPG, PNG, DOCX, PDF)</div>
                <div className={styles.attachItemPlaceholder__max}>5 MB MAX</div>
              </div>
              <Icon.UploadIcon className={styles.uploadIcon} />
              <input
                type='file'
                onChange={(event) => handleFileChange(event)}
                style={{ display: 'none' }}
              />
            </label>
          )}

          {/* Loaded attach item */}
          {attachment.file && (
            <div className={styles.attachItemWrapper}>
              <div className={styles.attachItemPlaceholder}>
                <div className={styles.attachItemPlaceholder__filename}>{attachment.file.name}</div>
                <div className={styles.attachItemPlaceholder__size}>{formatSize(attachment.file.size)}</div>
              </div>

              <button
                type='button'
                onClick={() =>
                  handleModalRemoveAttachment(`${attachment.file?.name}-${attachment.file?.lastModified}-${index}`)
                }
                className={styles.deleteButton}
              >
                <Icon.DeleteBin className={styles.deleteIcon} />
              </button>
              {fileErrors.includes(attachment.file.name) && (
                <span className={styles.fileErrorMessage}>{getTranslation(TranslationKeys.REQUIRED_FILE)}</span>
              )}
            </div>
          )}
        </div>
      ))}
      {attachments.length < 5 && (
        <div className={styles.attachButtonWrapper}>
          <div className='btn-reveal-effect form-secondary-cta document-btn'>
            <button
              data-hover={getTranslation(TranslationKeys.ADD_ANOTHER_ATTACHMENT)}
              type='button'
              onClick={handleAddAttachment}
              className={`button-style-reveal`}
            >
              <span>{getTranslation(TranslationKeys.ADD_ANOTHER_ATTACHMENT)}</span>
            </button>
          </div>
        </div>
      )}
      {modalToggler && (
        <ConfirmationModal
          handleModal={() => handleModalRemoveAttachment(idForDelete)}
          title={getTranslation(TranslationKeys.CONFIRMATION_DELETE_DOCUMENT)}
          onConfirm={() => handleRemoveAttachment(idForDelete)}
        />
      )}
    </div>
  );
};

export default Attachments;
