import { queryGraphQl } from '@lib/apolloClient';
import SearchQueryInCategory from '@graphql/queries/SearchQueryInCategory.graphql';
import { useData } from '@context/GraphQLDataContext';
import { SearchResultItem } from './useSearch';

type SearchQueryParams = {
  query: string;
  category: string;
};

function extractTextAndHighlightKeyword(originalText: string, keywordString: string, contextWords = 10): string {
  const sentences = originalText.split(/(?<=[.!?])/);
  const keywords = keywordString.toLowerCase().split(/\s+/).filter(Boolean);

  let snippet = '';
  sentences.some((sentence) => {
    const words = sentence.split(/\s+/);
    let startIndex = -1;
    let endIndex = -1;
    words.forEach((word, index) => {
      if (keywords.includes(word.toLowerCase())) {
        startIndex = Math.max(0, index - contextWords);
        endIndex = Math.min(words.length - 1, index + contextWords);
        return true;
      }
    });
    if (startIndex !== -1 && endIndex !== -1) {
      snippet = words.slice(startIndex, endIndex + 1).join(' ');
      keywords.forEach((keyword) => {
        snippet = snippet.replace(new RegExp(keyword, 'ig'), `<span class="highlightedWord">${keyword}</span>`);
      });
      return true;
    }
    return false;
  });

  return `<p>... ${snippet} ...</p>`;
}

export const useSearchInCategory = (): [
  (query: string, category: string) => Promise<{ items: SearchResultItem[] | undefined; total?: number }>,
] => {
  const { data } = useData();
  const search = (
    query: string,
    category: string
  ): Promise<{ items: SearchResultItem[] | undefined; total?: number }> => {
    return queryGraphQl<any>(SearchQueryInCategory, {
      query: query,
      category: category,
      locale: data?.language,
    } as SearchQueryParams).then((queryResult) => {
      const resultItems: SearchResultItem[] = [];
      Object.keys(queryResult).forEach((key) => {
        const items = queryResult[key].items;
        items.map((entry: SearchResultItem) => {
          let fulltext = '';
          if (!entry._fulltext || entry._fulltext.length === 0) return;
          for (const line of entry._fulltext) {
            if (line.startsWith('<')) {
              fulltext = line;
              break;
            }
          }
          resultItems.push({
            Name: entry.Name,
            RelativePath: entry.RelativePath,
            RouteSegment: entry.RouteSegment,
            fulltext: extractTextAndHighlightKeyword(fulltext, query),
            _score: entry._score,
            Category: [],
            _fulltext: [],
          });
          return {
            Name: entry.Name,
            RelativePath: entry.RelativePath,
            RouteSegment: entry.RouteSegment,
            fulltext: extractTextAndHighlightKeyword(fulltext, query),
            _score: entry._score,
          };
        });
      });
      return { items: resultItems, total: resultItems.length };
    });
  };

  return [search];
};
export type { SearchResultItem };
