/**
 * Checks if a given URL is absolute.
 * @param {string} url - The URL to check.
 * @return {boolean} - True if the URL is absolute, false otherwise.
 */

const baseUrl: string = process.env.NEXT_PUBLIC_LINK_ADDRESS || '';

export const isAbsoluteUrl = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url, baseUrl);
    // Consider absolute if url starts with parsedUrl's protocol
    return url.startsWith(parsedUrl.protocol);
  } catch (error) {
    // Error in parsing? --> URL was not absolute
    return false;
  }
};
