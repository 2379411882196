import { useCallback, useEffect, useReducer } from 'react';
import { IEvent, IEventsIDs } from '@interfaces/IEventsPage';
import { queryGraphQl } from '@lib/apolloClient';
import EventBlocksQuery from '@graphql/queries/EventBlocksQuery.graphql';
import TabsBlock from '@components/TabsBlock/TabsBlock';
import EventsList from '@components/EventsList/EventsList';
import { useData } from '@context/GraphQLDataContext';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { DetermineLanguage } from '@helpers/determineLanguage';
import { eventsReducer, FormState } from './Events.reducer';

type ActionTypes = 'SET_PAST_EVENTS' | 'SET_CURRENT_EVENTS' | 'SET_FUTURE_EVENTS';
type CountActionTypes = 'SET_PAST_LOADED_COUNT' | 'SET_CURRENT_LOADED_COUNT' | 'SET_FUTURE_LOADED_COUNT';
type EventKeys = 'pastEvents' | 'currentEvents' | 'futureEvents';

const LoadEvents = async (ids: string[], locale: string): Promise<IEvent[] | null> => {
  try {
    const data = await queryGraphQl<any>(EventBlocksQuery, { ids, locale }, false);
    return data?.EventBlock.items ?? [];
  } catch (error) {
    console.log('Cannot load data', error);
    return null;
  }
};

const getEventKey = (setEvents: ActionTypes): EventKeys => {
  switch (setEvents) {
    case 'SET_PAST_EVENTS':
      return 'pastEvents';
    case 'SET_CURRENT_EVENTS':
      return 'currentEvents';
    case 'SET_FUTURE_EVENTS':
      return 'futureEvents';
    default:
      throw new Error('Invalid event type');
  }
};

const EventsComponent = ({ pastEventsIds, currentEventsIds, futureEventsIds }: IEventsIDs) => {
  const initialState: FormState = {
    pastEvents: [],
    currentEvents: [],
    futureEvents: [],
    isLoading: false,
    isError: { pastEvents: false, currentEvents: false, futureEvents: false },
    pastLoadedCount: 0,
    currentLoadedCount: 0,
    futureLoadedCount: 0,
    allPastLoaded: false,
    allCurrentLoaded: false,
    allFutureLoaded: false,
  };
  const [state, dispatch] = useReducer(eventsReducer, initialState);
  const {
    pastEvents,
    currentEvents,
    futureEvents,
    isLoading,
    isError,
    pastLoadedCount,
    currentLoadedCount,
    futureLoadedCount,
    allPastLoaded,
    allCurrentLoaded,
    allFutureLoaded,
  } = state;

  const { translate } = useData();
  const language = DetermineLanguage();

  const loadMoreEvents = useCallback(
    async (eventIds: string[], setEvents: ActionTypes, setLoadedCount: CountActionTypes, loadedCount: number) => {
      dispatch({ type: 'SET_IS_LOADING', payload: true });

      const nextLoadCount = 10;
      const sliceEnd = Math.min(loadedCount + nextLoadCount, eventIds.length);
      const idsToLoad = eventIds.slice(loadedCount, sliceEnd);

      if (idsToLoad.length === 0) {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
      }

      const newEvents = await LoadEvents(idsToLoad, language ?? 'en');
      if (newEvents === null) {
        dispatch({ type: 'SET_IS_ERROR', payload: { ...state.isError, [getEventKey(setEvents)]: true } });
      } else {
        const uniqueEvents = newEvents.filter(
          (event, index, self) => index === self.findIndex((e) => e._id === event._id)
        );
        dispatch({ type: 'SET_IS_ERROR', payload: { ...state.isError, [getEventKey(setEvents)]: false } });
        dispatch({ type: setEvents, payload: uniqueEvents });
        dispatch({ type: setLoadedCount, payload: loadedCount + nextLoadCount });
      }

      dispatch({ type: 'SET_IS_LOADING', payload: false });
    },
    [language, state.isError]
  );

  useEffect(() => {
    dispatch({ type: 'SET_ALL_PAST_LOADED', payload: pastEvents.length >= pastEventsIds.length });
  }, [pastEvents, pastEventsIds]);

  useEffect(() => {
    if (currentEvents.length >= currentEventsIds.length) {
      dispatch({ type: 'SET_ALL_CURRENT_LOADED', payload: true });
    }
  }, [currentEvents, currentEventsIds]);

  useEffect(() => {
    if (futureEvents.length >= futureEventsIds.length) {
      dispatch({ type: 'SET_ALL_FUTURE_LOADED', payload: true });
    }
  }, [futureEvents, futureEventsIds]);

  useEffect(() => {
    if (pastEvents.length === 0) {
      void loadMoreEvents(pastEventsIds, 'SET_PAST_EVENTS', 'SET_PAST_LOADED_COUNT', pastLoadedCount);
    }
    if (currentEvents.length === 0) {
      void loadMoreEvents(currentEventsIds, 'SET_CURRENT_EVENTS', 'SET_CURRENT_LOADED_COUNT', currentLoadedCount);
    }
    if (futureEvents.length === 0) {
      void loadMoreEvents(futureEventsIds, 'SET_FUTURE_EVENTS', 'SET_FUTURE_LOADED_COUNT', futureLoadedCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TabsBlock
        activeTab={1}
        data={[
          {
            title: translate('previous-events'),
            content: !isError.pastEvents ? (
              <EventsList
                eventCards={pastEvents}
                isLoading={isLoading}
              />
            ) : (
              <ErrorMessage className='text-center' />
            ),
            onLoadMore: () =>
              loadMoreEvents(pastEventsIds, 'SET_PAST_EVENTS', 'SET_PAST_LOADED_COUNT', pastLoadedCount),
            disabled: allPastLoaded,
          },
          {
            title: translate('happening-now'),
            content: !isError.currentEvents ? (
              <EventsList
                eventCards={currentEvents}
                isLoading={isLoading}
              />
            ) : (
              <ErrorMessage className='text-center' />
            ),
            onLoadMore: () =>
              loadMoreEvents(currentEventsIds, 'SET_CURRENT_EVENTS', 'SET_CURRENT_LOADED_COUNT', currentLoadedCount),
            disabled: allCurrentLoaded,
          },
          {
            title: translate('upcoming-events'),
            content: !isError.futureEvents ? (
              <EventsList
                eventCards={futureEvents}
                isLoading={isLoading}
              />
            ) : (
              <ErrorMessage className='text-center' />
            ),
            onLoadMore: () =>
              loadMoreEvents(futureEventsIds, 'SET_FUTURE_EVENTS', 'SET_FUTURE_LOADED_COUNT', futureLoadedCount),
            disabled: allFutureLoaded,
          },
        ]}
      />
    </div>
  );
};

export default EventsComponent;
