import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

interface IPreformatted {
  className?: string | string[];
  style?: CSSProperties;
  children: ReactNode;
}

/**
 * Preformatted Component
 *
 *A component representing the <pre>{children}</pre>.
 *
 * @component
 * @param {Object} prop - The props for the Preformatted component.
 * @param {string} prop.className - The class name/names.
 * @param {CSSProperties} prop.style - The style will be injected.
 * @param {string} prop.children - The text inside a <pre></pre>.
 * @returns {JSX.Element} The rendered JSX element representing the <pre>{children}</pre>.
 *
 * @example
 *  <Preformatted className={['test1', 'test2', 'test3']}>TEST</Preformatted>
 */

const Preformatted = (prop: IPreformatted): JSX.Element => {
  const { className, style, children } = prop;

  return (
    <pre
      className={clsx(className)}
      style={style}
    >
      {children}
    </pre>
  );
};

export default Preformatted;
