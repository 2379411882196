import React, { useCallback, useEffect, useState } from 'react';
import { IInputProps, HTMLInputElementCustom, HTMLTextAreaElementCustom } from '../utils/interfaces';
import Icon from '@components/Icon/Icon';
import { getTranslation } from '../utils/helpers';
import { TranslationKeys } from '../translations/translationKeys';

const InputTextCustom: React.FC<IInputProps> = ({
  name,
  type = 'text',
  labelText,
  onChange,
  required = false,
  validationMessage,
  additionalValidationRule,
  additionalValidation,
  additionalValidationMessage,
}) => {
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(!required);
  const [validMessage, setValidMessage] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const target = { name, value, isValid: !required } as HTMLInputElementCustom;
    onChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doAdditionalValidation = useCallback(
    (value: string) => {
      if (!additionalValidation) {
        return true;
      }
      const regex = additionalValidationRule;
      return value && regex?.test(String(value).toLowerCase());
    },
    [additionalValidation, additionalValidationRule]
  );

  const validateInput = useCallback(
    (value: string) => {
      if (required && value.trim() === '') {
        setIsValid(false);
        setValidMessage(validationMessage || getTranslation(TranslationKeys.REQUIRED_FIELD));
      } else if (additionalValidation && !doAdditionalValidation(value)) {
        setIsValid(false);
        setValidMessage(additionalValidationMessage || getTranslation(TranslationKeys.INVALID_FIELD));
      } else {
        setIsValid(true);
        setValidMessage('');
      }
    },
    [required, validationMessage, additionalValidation, doAdditionalValidation, additionalValidationMessage]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
      const target = e.target as HTMLInputElementCustom;
      setValue(target.value);
      validateInput(target.value);
      const isValid = required
        ? target.value.trim() !== ''
          ? additionalValidation
            ? doAdditionalValidation(target.value)
            : true
          : false
        : true;

      setIsValid(!!isValid);

      target.isValid = !!isValid;
      onChange({ target } as React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>);
    },

    [validateInput, required, additionalValidation, doAdditionalValidation, onChange]
  );

  const handleClearInput = (e: React.ChangeEvent<HTMLInputElementCustom | HTMLTextAreaElementCustom>) => {
    e.preventDefault();
    const target = e.target.ownerDocument.activeElement as HTMLInputElementCustom;
    target.value = '';
    handleChange({ target } as React.ChangeEvent<HTMLInputElementCustom>);
  };

  const validationCssClass = !isValid && required && validMessage != '' ? 'ValidationFail' : 'ValidationSuccess';

  const Icons = isFocused ? (
    <Icon.CloseModalIcon
      onMouseDown={handleClearInput}
      className='IconClose IconCloseVisible'
    />
  ) : value && isValid && !isFocused ? (
    <Icon.Tick className='TickIcon' />
  ) : (
    <></>
  );
  return (
    <div>
      {type === 'textarea' ? (
        <div
          className={`Form__Element FormTextbox ${required ? 'ValidationRequired' : ''}  ${validationCssClass} FormTextbox--Textarea`}
        >
          <label
            htmlFor={name}
            className={`Form__Element__Caption textInputLabel ${value ? 'filled' : ''}`}
          >
            {labelText}
          </label>
          <textarea
            className='FormTextbox__Input Whistleblower'
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
          />
          {Icons}
          {!isValid && required && validMessage != '' && (
            <span className='Form__Element__ValidationError'>{validMessage}</span>
          )}
        </div>
      ) : (
        <div className={`Form__Element FormTextbox ${required ? 'ValidationRequired' : ''} ${validationCssClass}`}>
          <label
            htmlFor={name}
            className={`Form__Element__Caption textInputLabel ${value ? 'filled' : ''}`}
          >
            {labelText}
          </label>
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            aria-invalid={!isValid}
            className='FormTextbox__Input textInput'
          />
          {Icons}
          {!isValid && required && validMessage != '' && (
            <span className='Form__Element__ValidationError'>{validMessage}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(InputTextCustom);
